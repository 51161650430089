<template>
  <div
    :id="id"
    class="icon svg-icon-component"
    :class="className"
    @click="buttonClickEvent"
    v-html="content"
  />
</template>

<script>
export default {
  props: {
    // Endereço da imagem
    src: {
      type: String,
      required: true
    },
    // Id do elemento
    id: {
      type: String,
      required: false,
      default: ''
    },
    className: {
      type: String,
      required: false,
      default: ''
    }
  },
  emits: ['customClickEvent'],
  data() {
    return {
      content: ""
    };
  },
  watch: {
    "src": {
      handler: function(){
        this.getIcon(this.src);
      }
    }
  },
  created(){
    this.getIcon(this.src);
  },
  methods: {
    getIcon(src){
      this.$axios({
        method: "GET",
        baseURL: "/",
        url: src
      })
        .then(res => this.content = res.data);
    },
    buttonClickEvent(){
      this.$emit("customClickEvent");
    }
  }
};
</script>
