<template>
  <lila-default-template>
    <template #mainContent>
      <form
        class="container basic-info-form"
        :class="loadingContent ? 'loading large' : ''"
        @submit.prevent="submitForm()"
      >
        <h1 class="title">
          Novo Paciente
        </h1>

        <lila-message :alert-data="alert" />

        <div v-show="!loadingContent">
          <div class="columns">
            <div class="column is-5">
              <p>Você pode adicionar mais de um Registro, clicando no botão Adicionar</p>

              <div class="field-group">
                <div class="field-group-header align-title">
                  <p class="field-group-label-type">
                    Adicionar Registro
                  </p>
                </div>

                <div class="field-group-body">
                  <lila-select
                    v-model="v.newHistoryRecord.historyRecordTypeId.$model"
                    placeholder="Escolha o tipo do Registro"
                    :options="historyRecordTypeList"
                    :validation="v.newHistoryRecord.historyRecordTypeId"
                  />


                  <div class="columns">
                    <div class="column is-6">
                      <lila-datepicker
                        v-show="newHistoryRecord.historyRecordTypeId != 4"
                        v-model="v.newHistoryRecord.initialDate.$model"
                        :placeholder="newHistoryRecord.historyRecordTypeId == 1 ? 'Data' : 'Data inicio'"
                        :validation="v.newHistoryRecord.initialDate"
                      />
                    </div>

                    <div class="column is-6">
                      <lila-datepicker
                        v-show="newHistoryRecord.historyRecordTypeId != 1 && newHistoryRecord.historyRecordTypeId != 4"
                        v-model="v.newHistoryRecord.endDate.$model"
                        placeholder="Data fim"
                        :validation="v.newHistoryRecord.endDate"
                      />
                    </div>
                  </div>

                  <lila-textarea
                    v-show="newHistoryRecord.historyRecordTypeId != 4"
                    v-model="v.newHistoryRecord.historyRecordNotes.$model"
                    placeholder="Anotações do Registro"
                    :validation="v.newHistoryRecord.historyRecordNotes"
                  />

                  <lila-button
                    type="button"
                    title="Adicionar"
                    @custom-click-event="manageHistoryRecord()"
                  />
                </div>
              </div>
            </div>

            <div class="column is-2 middle-column">
              <div class="line" />
            </div>

            <div class="column is-5 middle-column">
              <div class="field-group">
                <div class="field-group-header align-title">
                  <p class="field-group-title field-group-label-type">
                    Registros adicionados
                  </p>

                  <p
                    v-show="data.historyRecordList.length == 0"
                    class="semi-bold-text"
                  >
                    Você ainda não incluiu nenhum Registro.
                  </p>
                </div>

                <div class="field-group-body">
                  <lila-history-record-card
                    :data-list="data.historyRecordList"
                    card-size="is-12-mobile is-12-tablet is-12-desktop is-6-widescreen is-6-fullhd"
                    @custom-click-edit-event="mountEditHistoryRecord"
                    @custom-click-delete-event="deleteHistoryRecordHistory"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="registration-steps has-text-centered">
            <ul class="steps">
              <li />
              <li />
              <li class="active" />
              <li />
            </ul>
          </div>

          <lila-button
            type="submit"
            title="Salvar"
            class="submit-buttons"
            :second-button="{type: 'routeText', route: 'myPatients', title: 'Continuar mais tarde', class: 'dark-lilas'}"
            :loading="loading"
          />
        </div>
      </form>
    </template>
  </lila-default-template>
</template>

<script>
import DefaultTemplate from "@components/template/DefaultTemplate.vue";

import Select from "@components/shared/fields/Select.vue";
import Textarea from "@components/shared/fields/Textarea.vue";
import Button from "@components/shared/button/Button.vue";
import Message from "@components/shared/message/Message.vue";
import HistoryRecordCard from "@components/shared/lila/HistoryRecordCard.vue";
import LilaDatepicker from "@components/shared/fields/LilaDatepicker.vue";

import { requiredIf } from "@vuelidate/validators";

export default {
  components: {
    LilaDatepicker,
    "lila-select": Select,
    "lila-textarea": Textarea,
    "lila-button": Button,
    "lila-message": Message,
    "lila-history-record-card": HistoryRecordCard,
    "lila-default-template": DefaultTemplate
  },
  data() {
    return {
      data: {
        id: this.$route.params.id,
        historyRecordList: []
      },
      newHistoryRecord: {
        addNew: false,
        _id: "",
        index: "",
        historyRecordTypeId: "",
        initialDate: "",
        endDate: "",
        historyRecordNotes: ""
      },
      historyRecordTypeList: [],
      loadingContent: false,
      loading: false,
      alert: {
        message: "",
        type: "",
        show: false
      }
    };
  },
  validations() {
    if(this.newHistoryRecord.addNew){
       return {
        newHistoryRecord: {
          historyRecordTypeId: {
              required: requiredIf(function(){
                  return this.newHistoryRecord.addNew;
              })
          }, 
          initialDate: {},
          endDate: {},
          historyRecordNotes: {}
        }
      };
    } else {
       return {
        newHistoryRecord: {
          historyRecordTypeId: {
              required: requiredIf(function(){
                  return this.newHistoryRecord.addNew;
              })
          }, 
          initialDate: {},
          endDate: {},
          historyRecordNotes: {}
        }
      };
    }
  },
  created(){
    if(this.$Utilities.hasProperty(this.$route.query, "message")){
      this.$Utilities.controlAlert(null, this.alert, this.$route.query.message);
    }

    this.verifyPatientStatus();
  },
  methods: {
    submitForm(){
      this.alert.show = false;
      this.newHistoryRecord.addNew = false;

      this.v.$touch();

      if(this.v.$invalid) return;

      this.loading = true;

      let requestList = [];

      this.data.historyRecordList.forEach(item => requestList.push(this.saveHistoryRecord(item)));

      if(requestList.length == 0){
        this.alert.message = "É necessario adicionar pelo menos um Registro.";
        this.alert.type = "warning";
        this.alert.show = true;

        this.loading = false;

        return;
      }

      Promise
        .all(requestList)
        .then(() => this.$router.push({
          name: "step4PatientInformation",
          params: {
            id: this.data.id
          },
          query: {
            message: "complementaryInformation"
          }
        }))
        .catch(error => {
          this.$Utilities.verifyToken(error.response.status);

          this.$router.push({name: "myPatients", query: {
            customMessageType: "danger",
            customMessage: "Não foi possivel salvar as informações complementares do paciente."
          }});
        })
        .finally(() => this.loading = false);
    },
    saveHistoryRecord(historyRecord){
      return this.$axios({
        method: "POST",
        url: `api/historyRecord/patient/${this.data.id}`,
        data: this.formatDataToSend(historyRecord),
        headers: {"Authorization": `bearer ${this.$Session.get(this.$userSessionName).token.Value}`}
      });
    },
    formatDataToSend(historyRecord){
      let newHistoryRecord = {
        historyRecordTypeId: historyRecord.historyRecordTypeId,
        historyRecordNotes: historyRecord.historyRecordNotes
      };

      if(historyRecord.initialDate) newHistoryRecord.initialDate = this.$Utilities.formatDate(historyRecord.initialDate, "DD/MM/YYYY", "MM/DD/YYYY");

      if(historyRecord.endDate) newHistoryRecord.endDate = this.$Utilities.formatDate(historyRecord.endDate, "DD/MM/YYYY", "MM/DD/YYYY");

      return newHistoryRecord;
    },
    verifyPatientStatus(){
      this.loadingContent = true;

      this.$axios
        .get(
          `api/patient/record/${this.data.id}`,
          {headers: {"Authorization": `bearer ${this.$Session.get(this.$userSessionName).token.Value}`}}
        )
        .then((res) => {
          if(res.data.Status != "Pending"){
            this.$router.push({name: "myPatients"});
          }else{
            this.getPatient();
          }

        });
    },
    getPatient(){
      this.loadingContent = true;

      this.$axios
        .get(
          `api/patient/${this.data.id}`,
          {headers: {"Authorization": `bearer ${this.$Session.get(this.$userSessionName).token.Value}`}}
        )
        .then(() => this.getPatientHistoryRecordList())
        .catch(error => {
          this.$Utilities.verifyToken(error.response.status);
          this.$router.push({name: "myPatients"});
        });
    },
    getPatientHistoryRecordList(){
      this.$axios
        .get(
          `api/historyRecord/patient/${this.data.id}`,
          {headers: {"Authorization": `bearer ${this.$Session.get(this.$userSessionName).token.Value}`}}
        )
        .then((res) => {
          if(res.data.Data.length > 0) this.$router.push({name: "myPatients"});

          this.getHistoryRecordType();
        })
        .catch(error => {
          this.$Utilities.verifyToken(error.response.status);
          this.$router.push({name: "myPatients"});
        });
    },
    getHistoryRecordType(){
      this.loadingContent = true;

      this.$axios
        .get(
          "api/historyRecordtype",
          {headers: {"Authorization": `bearer ${this.$Session.get(this.$userSessionName).token.Value}`}}
        )
        .then((res) => {
          this.historyRecordTypeList = res.data.map(item => {
            return {
              _id: item.Id,
              name: item.Name
            };
          });
        })
        .catch(error => {
          this.$Utilities.verifyToken(error.response.status);
          this.$Utilities.controlAlert(error.response.data, this.alert, "danger");
        })
        .finally(() => this.loadingContent = false);
    },
    manageHistoryRecord(){
      this.newHistoryRecord.addNew = true;

      this.v.$touch();

      if(this.v.$invalid) return;

      if(this.newHistoryRecord.index === ""){
        this.addHistoryRecord();
      }else{
        this.editHistoryRecord();
      }

      this.clearHistoryRecord();
    },
    addHistoryRecord(){
      this.data.historyRecordList.push({
        _id: "",
        historyRecordTypeId: this.newHistoryRecord.historyRecordTypeId,
        historyRecordTypeName: this.getHistoryRecordByName(this.newHistoryRecord.historyRecordTypeId),
        date: `${this.newHistoryRecord.initialDate} ${this.newHistoryRecord.endDate ? `- ${this.newHistoryRecord.endDate}` : ""}`,
        initialDate: this.newHistoryRecord.initialDate,
        endDate: this.newHistoryRecord.endDate,
        historyRecordNotes: this.newHistoryRecord.historyRecordNotes
      });
    },
    editHistoryRecord(){
      this.data.historyRecordList[this.newHistoryRecord.index].historyRecordTypeId = this.newHistoryRecord.historyRecordTypeId;
      this.data.historyRecordList[this.newHistoryRecord.index].historyRecordTypeName = this.getHistoryRecordByName(this.newHistoryRecord.historyRecordTypeId);
      this.data.historyRecordList[this.newHistoryRecord.index].date = `${this.newHistoryRecord.initialDate} ${this.newHistoryRecord.endDate ? `- ${this.newHistoryRecord.endDate}` : ""}`;
      this.data.historyRecordList[this.newHistoryRecord.index].initialDate = this.newHistoryRecord.initialDate;
      this.data.historyRecordList[this.newHistoryRecord.index].endDate = this.newHistoryRecord.endDate;
      this.data.historyRecordList[this.newHistoryRecord.index].historyRecordNotes = this.newHistoryRecord.historyRecordNotes;
    },
    deleteHistoryRecordHistory(deletedHistoryRecord, index){
      if(deletedHistoryRecord._id == ""){
        this.data.historyRecordList.splice(index, 1);

        if(this.newHistoryRecord.index == index) this.clearHistoryRecord();
      }
    },
    mountEditHistoryRecord(historyRecord, index){
      this.newHistoryRecord._id = this.data.historyRecordList[index]._id;
      this.newHistoryRecord.index = index;
      this.newHistoryRecord.historyRecordTypeId = this.data.historyRecordList[index].historyRecordTypeId;
      this.newHistoryRecord.initialDate = this.data.historyRecordList[index].initialDate;
      this.newHistoryRecord.endDate = this.data.historyRecordList[index].endDate;
      this.newHistoryRecord.historyRecordNotes = this.data.historyRecordList[index].historyRecordNotes;
    },
    clearHistoryRecord(){
      this.newHistoryRecord.addNew = false;
      this.newHistoryRecord._id = "";
      this.newHistoryRecord.index = "";
      this.newHistoryRecord.historyRecordTypeId = "";
      this.newHistoryRecord.initialDate = "";
      this.newHistoryRecord.endDate = "";
      this.newHistoryRecord.historyRecordNotes = "";

      this.v.$reset();
    },
    getHistoryRecordByName(historyRecordId){
      let historyRecord = this.historyRecordTypeList.filter(item => item._id == historyRecordId);

      return historyRecord.length > 0 ? historyRecord[0].name : "- - -";
    }
  }
};
</script>

<style>
    @import '~@assets/css/views/complementaryRegistration.css';
</style>
