import { userSessionName } from "../js/axios";
import { Session } from "../plugins/Session";
import moment from "moment";
import axios from "axios";
import router from "../router";
import decodeToken from "../js/decodeToken.js";



export const Utilities = {
  formatDate: (date, inputFormat = "DD/MM/YYYY", outputFormat = "YYYY-MM-DD") => {
    if (date)
      return moment(date, inputFormat).format(outputFormat);
  },
  formatDateWithTime: (date, inputFormat = "YYYY-MM-DDTHH:mm:ss", outputFormat = "DD/MM/YYYY") => {
    
    if (date){
      date = date.replace(/Z/, "");
      return moment(date, inputFormat).format(outputFormat);
    }
  },
  formatDateTimeAndMinutes(date) {
    const d = new Date(date);
    return d.toLocaleDateString("pt-BR", { day: "2-digit", month: "2-digit", year: "numeric" }) +
      ", " + 
      d.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  },

  getErrorMsg(field) {
    const keys = Object.keys(field);
    if(typeof field.required !== "undefined" && keys.includes("required")) return "Campo obrigatório.";
    if(typeof field.sameAsPassword !== "undefined" && keys.includes("sameAsPassword")) return "As senhas não são iguais.";
    if(typeof field.email !== "undefined" && keys.includes("email")) return "Informe um email válido.";
    if(typeof field.url !== "undefined" && keys.includes("url")) return "Informe uma URL válida.";
    if(typeof field.maxLength !== "undefined" && keys.includes("maxLength")) return `O campo pode conter no máximo ${field.$params.maxLength.max} caracteres.`;

    if(typeof field.validObservationsLength !== "undefined" && keys.includes("validObservationsLength")) return `O campo pode conter no máximo ${field.validObservationsLength.$params.maxLength} caracteres.`;

    if(typeof field.minLength !== "undefined" && keys.includes("minLength")) return `O campo deve conter no mínimo ${field.$params.minLength.min} caracteres.`;
    if(typeof field.validCpf !== "undefined" && keys.includes("validCpf")) return "Informe um CPF válido.";
    if(typeof field.validCns !== "undefined" && keys.includes("validCns")) return "O CNS deve conter exatamente 15 dígitos numéricos.";
    if(typeof field.validPhone !== "undefined" && keys.includes("validPhone")) return "Informe um telefone válido.";
    if(typeof field.validFormatImage !== "undefined" && keys.includes("validFormatImage")) return "Utilize apenas imagens nos formatos JPEG ou PNG.";
    if(typeof field.checkSpaces !== "undefined" && keys.includes("checkSpaces")) return "A senha não pode conter espaços.";
    if(typeof field.numeric !== "undefined" && keys.includes("numeric")) return "Apenas numeros são permitidos.";
    if(typeof field.advisorRequired !== "undefined" && keys.includes("advisorRequired")) return "É necessario informar um assessor.";
    if(typeof field.validateDate !== "undefined" && keys.includes("validateDate")) return "Informe um horário valido.";
    if(typeof field.verifyLastName !== "undefined" && keys.includes("verifyLastName")) return "Deve ser inserido pelo menos um sobrenome.";
    if(typeof field.checkNumber !== "undefined" && keys.includes("checkNumber")) return "A senha deve conter pelo menos um número.";
    if(typeof field.checkUpperCase !== "undefined" && keys.includes("checkUpperCase")) return "A senha deve conter pelo menos uma letra maiúscula.";
    if(typeof field.terms !== "undefined" && keys.includes("terms")) return "É necessario aceitar os Termos de Uso.";
    if(typeof field.verifyMedicalCareLocation !== "undefined" && keys.includes("verifyMedicalCareLocation")) return "Pelo menos um local de atendimento deve ser informado.";
    if(typeof field.dateLessOrEqualToCurrent !== "undefined" && keys.includes("dateLessOrEqualToCurrent")) return "A data não pode ser superior a data atual.";
    if(typeof field.endDateMustBeGreater !== "undefined" && keys.includes("endDateMustBeGreater")) return "A data fim deve ser maior que a data inicio.";
  },
  validateCPF(strCpf){
    let Soma = 0;
    let Resto;
    let i = 1;

    strCpf = strCpf.replace(/\D/g,"");

    if(strCpf == "00000000000") return false;

    for(i=1; i<=9; i++) Soma = Soma + parseInt(strCpf.substring(i-1, i)) * (11 - i);

    Resto = (Soma * 10) % 11;

    if((Resto == 10) || (Resto == 11))  Resto = 0;
    if(Resto != parseInt(strCpf.substring(9, 10))) return false;

    Soma = 0;
    i = 1;

    for(i = 1; i <= 10; i++) Soma = Soma + parseInt(strCpf.substring(i-1, i)) * (12 - i);

    Resto = (Soma * 10) % 11;

    if((Resto == 10) || (Resto == 11))  Resto = 0;
    if(Resto != parseInt(strCpf.substring(10, 11) )) return false;

    return true;
  },
  buscarEndereco(endereco, loading){
    if (!endereco?.cep) return false;
    let raw_cep = endereco.cep.replace(/\D/g,"");

    if(raw_cep.length < 8) return false;

    loading.loadingCep = true;

    axios
      .get(`https://viacep.com.br/ws/${raw_cep}/json/`)
      .then(response => {
        if(typeof response.data.erro != "undefined") return false;

        if(typeof endereco.country != "undefined") endereco.country = "Brasil";
        if(typeof endereco.street != "undefined") endereco.street = response.data.logradouro;
        if(typeof endereco.complement != "undefined") endereco.complement = response.data.complemento;
        if(typeof endereco.number != "undefined") endereco.number = "";
        if(typeof endereco.neighborhood != "undefined") endereco.neighborhood = response.data.bairro;
        if(typeof endereco.city != "undefined") endereco.city = response.data.localidade;
        if(typeof endereco.uf != "undefined") endereco.uf = response.data.uf;
      })
      .catch(() => {})
      .finally(() => {
        loading.loadingCep = false;
      });
  },
  typePhone(phone){
    if(phone.length == 10 || phone.length == 11){
      let expFixo = new RegExp(/^([1-9][1-9])?[\s-]?[2-7]\d{3}-?\d{4}$/);
      let expMovel = new RegExp(/^([1-9][1-9])?[\s-]?9[2-9]\d{3}-?\d{4}$/);

      if(expFixo.test(phone)){
        return "Fixo";
      }else if(expMovel.test(phone)){
        return "Celular";
      }
    }

    return false;
  },
  removeAccent(string){
    return string
      .toLowerCase()
      .trim()
      .replace(new RegExp("[àáâãäå]", "g"),"a")
      .replace(new RegExp("æ", "g"),"ae")
      .replace(new RegExp("ç", "g"),"c")
      .replace(new RegExp("[èéêë]", "g"),"e")
      .replace(new RegExp("[ìíîï]", "g"),"i")
      .replace(new RegExp("ñ", "g"),"n")
      .replace(new RegExp("[òóôõö]", "g"),"o")
      .replace(new RegExp("œ", "g"),"oe")
      .replace(new RegExp("[ùúûü]", "g"),"u")
      .replace(new RegExp("[ýÿ]", "g"),"y");
  },
  controlAlert(res, alert, type, defaultMessage = false){
    let status = this.hasProperty(res, "response.status") ? res.response.status : 0;
    let messages = {
      "msgErro": "Ocorreu um erro. Tente novamente.",
      "msgSuccess": "Sua ação foi concluida com sucesso.",
      "msgEmptyList": "Nenhum resultado foi encontrado.",
      "msgDelete": "O registro foi deletado com sucesso.",
      "msgSave": "O registro foi inserido com sucesso.",
      "msgEdit": "O registro foi editado com sucesso.",
      "msgFirstPasswordCreated": "Senha cadastrada com sucesso.",
      "msgPasswordChanged": "Sua senha foi alterada com sucesso.",
      "msgExpiredToken": "Token incorreto ou expirado.",
      "msgBasicInformation": "Dados básicos salvos com sucesso.",
      "msgComplementaryInformation": "Dados complementares salvos com sucesso.",
      "msgSessionExpired": "Sessão expirada. Realize o login novamente.",
      "msgForgotPassword": "Confira seu e-mail para encontrar instruções para recuperar sua senha."
    };

    switch (status) {
    case 404:
      alert.message = messages.msgErro;
      break;
    default:
      if(res !== null && (this.hasProperty(res, "Title") || this.hasProperty(res, "Message")) && this.hasProperty(res, "Errors")){
        if(this.hasProperty(res, "Title")) alert.message = res.Title;
        if(this.hasProperty(res, "Message")) alert.message = res.Message;

        if(res.Errors != null && typeof res.Errors == "object"){
          alert.message += "<br><br> <ul>";

          res.Errors.forEach((erro) => alert.message += `<li>${erro}</li>`);

          alert.message += "</ul>";
        }else if(res.Errors != null){
          alert.message += ` ${res.Errors}`;
        }
      } else if(this.hasProperty(res, "Message")) {
        alert.message = res.Message;
      } else {
        if(defaultMessage){
          alert.message = defaultMessage;
        }else{
          switch (type) {
          case "danger":
            alert.message = messages.msgErro;
            break;
          case "success":
            alert.message = messages.msgSuccess;
            break;
          case "emptyList":
            alert.message = messages.msgEmptyList;
            type = "warning";
            break;
          case "delete":
            alert.message = messages.msgDelete;
            type = "success";
            break;
          case "save":
            alert.message = messages.msgSave;
            type = "success";
            break;
          case "edit":
            alert.message = messages.msgEdit;
            type = "success";
            break;
          case "firstPasswordCreated":
            alert.message = messages.msgFirstPasswordCreated;
            type = "success";
            break;
          case "passwordChanged":
            alert.message = messages.msgPasswordChanged;
            type = "success";
            break;
          case "expiredToken":
            alert.message = messages.msgExpiredToken;
            type = "warning";
            break;
          case "basicInformation":
            alert.message = messages.msgBasicInformation;
            type = "success";
            break;
          case "complementaryInformation":
            alert.message = messages.msgComplementaryInformation;
            type = "success";
            break;
          case "sessionExpired":
            alert.message = messages.msgSessionExpired;
            type = "warning";
            break;
          case "forgotPassword":
            alert.message = messages.msgForgotPassword;
            type = "success";
            break;
          }
        }
      }

      break;
    }

    alert.type = type;
    alert.show = true;

    document.body.scrollTop = 0; // Safari
    document.documentElement.scrollTop = 0; // Chrome, Firefox, IE e Opera
  },
  showResultMessage(params, alert){
    if(this.hasProperty(params, "message")){
      this.controlAlert(null, alert, params.message);
    }else if(this.hasProperty(params, "customMessage")){
      this.controlAlert(null, alert, params.customMessage.type, params.customMessage.message);
    }
  },
  hasProperty(obj, propertyPath){
    if(!propertyPath) return false;

    let properties = propertyPath.split(".");

    for (let i = 0; i < properties.length; i++) {
      let prop = properties[i];

      if(!obj || !obj.hasOwnProperty(prop)){
        return false;
      } else {
        obj = obj[prop];
      }
    }

    return true;
  },
  formatMoney(money){
    return new Intl.NumberFormat([], { style: "currency", currency: "BRL" }).format(money);
  },
  checkFullRegistration(){
    if(Session.get(userSessionName).doctor.CPF &&
                Session.get(userSessionName).doctor.BirthDate &&
                Session.get(userSessionName).doctor.Gender &&
                Session.get(userSessionName).doctor.Addresses[0]){
      router.push({name: "patientTickets"});
    }
  },
  getFileExtension(fileName){
    return fileName.split(".").pop();
  },
  // getFileName(fullPath){
  //   // eslint-disable-next-line no-useless-escape
  //   return fullPath.replace(/^.*[\\\/]/, "");
  // }, This is a duplicate keeping here to consult if needed
  getFileName(filePath){
    return filePath.split("/").pop();
  },
  verifyToken(status){
    if(status == 401){
      Session.remove(userSessionName);
      router.push({name: "login", params: {message: "sessionExpired"}});
    }
  },
  leadingZero(number){
    return number < 10 ? `0${number}` : number;
  },
  addHiperlinkinText(text){
    let url_list = text.match(/\bhttps?:\/\/\S+/gi);

    if(url_list != null) url_list.forEach(url => text = text.replace(url, `<a class="hiperlink" href="${url}" target="_blank">${url}</a>`));

    return text;
  },
  /**
         * Retorna o valor correpondente à chave/propriedade ignorando a case
         * @param {objeto a comparar as chaves} object
         * @param {chave ou propriedade de um objeto} key
         */
  getValueIgnoreCase(object, key) {
    // Verifica se object é null ou undefined
    if (object == null) {
      return undefined;
    }
    
    let keysLowerCase = Object.keys(object).map(function(keyName) {
      return keyName.toLowerCase();
    });
  
    const index = keysLowerCase.indexOf(key.toLowerCase());
    return index !== -1 ? Object.values(object)[index] : undefined;
  },

  getPatientCid(patient) {
    const getValueIgnoreCase = (obj, key) => {
      if (!obj || typeof obj !== 'object') return undefined;
      const foundKey = Object.keys(obj).find(k => k.toLowerCase() === key.toLowerCase());
      return foundKey ? obj[foundKey] : undefined;
    };
  
    const CIDs = getValueIgnoreCase(patient, "CIDs");
  
    const activeCidList = CIDs ? CIDs.filter(item => getValueIgnoreCase(item, "isEnable")) : [];
  
    const firstActiveCid = activeCidList[0];
  
    // Handle case where no active CIDs are present and CurrentPS is used
    if (activeCidList.length === 0 && getValueIgnoreCase(patient, "CurrentPS")) {
      return this.getPatientPs(getValueIgnoreCase(patient, "CurrentPS"));
    }
  
    // Handle single active CID
    if (activeCidList.length === 1 && firstActiveCid) {
      const CIDCode = getValueIgnoreCase(firstActiveCid.CID, "CIDCode");
      const Estadio = getValueIgnoreCase(firstActiveCid, "Estadio");
      return `${CIDCode} . ${this.getPatientEstadio(Estadio)}${getValueIgnoreCase(patient, "CurrentPS") ? `. ${this.getPatientPs(getValueIgnoreCase(patient, "CurrentPS"))}` : ""}`;
    }
  
    // Handle multiple active CIDs
    if (activeCidList.length > 1) {
      return `C97${getValueIgnoreCase(patient, "CurrentPS") ? `. ${this.getPatientPs(getValueIgnoreCase(patient, "CurrentPS"))}` : ""}`;
    }
  
    return undefined;
  }
  ,
  getSelectedPatientCid(patient){
    let activeCidList = (patient.CIDs[0] != null) ? patient.CIDs.filter(item => item.IsEnable) : [];

    if(activeCidList.length == 0)  return this.getPatientPs(patient.PS);
    if(activeCidList.length == 1) return `${activeCidList[0].CIDCode} . ${this.getPatientEstadio(activeCidList[0].Estadio)} . ${this.getPatientPs(patient.PS)}`;
    if(activeCidList.length > 1) return `C97 . ${this.getPatientPs(patient.PS)}`;
  },
  getPatientPs(ps){
    let psList = [
      {id: "0", name: "PS 0"},
      {id: "1", name: "PS I"},
      {id: "2", name: "PS II"},
      {id: "3", name: "PS III"},
      {id: "4", name: "PS IV"}
    ];
    let selectedPs = psList.filter(item => item.id == ps);

    return selectedPs.length > 0 ? selectedPs[0].name : "";
  },
  getPatientEstadio(estadio){
    switch (estadio) {
    case "Localizado":
      return "LOC";

    case "Extenso":
      return "EXT";

    case "Não se aplica":
      return "NA";

    default:
      return estadio;
    }
  },
  intensityPainName(intensity){
    let intensityList = ["", "Leve", "Moderado", "Grave", "Muito Grave"];

    return intensityList[intensity] != undefined ? intensityList[intensity] : "";
  },
  getDefaultProfilePicture(genre){
    switch (genre) {
    case "Feminino":
      return require(`@components/shared/lila/profilePicturePlaceholder/${(Math.floor(Math.random() * 4) + 1)}.png`);

    case "Masculino":
      return require("@components/shared/lila/profilePicturePlaceholder/man_1.png");

    default:
      return null;
    }
  },
  inRange(minValue, maxValue, value){
    if(minValue == null) return value <= maxValue;
    if(maxValue == null) return value >= minValue;

    return value >= minValue && value <= maxValue;
  },

  getAge(date) {

    const birthDate = moment(date, "MM/DD/YYYY");
    const today = moment();
    
    let age = today.year() - birthDate.year();
    
    if (today.month() < birthDate.month() || 
        (today.month() === birthDate.month() && today.date() < birthDate.date())) {
      age--;
    }
    
    return age + 'a';
  },

  getAbbrevGender(gender){
    switch (gender) {
    case "Masculino":
      return "Masc";

    case "Feminino":
      return "Fem";

    default:
      return gender;
    }
  },

    scrollToInvalidField(){
      document.body.scrollTop = document.querySelector(".error").offsetTop; // Safari
      document.documentElement.scrollTop = document.querySelector(".error").offsetTop; // Chrome, Firefox, IE e Opera
    },

    hasAccessToFeature(app, featureId) {
      const decodedToken = decodeToken(app.$Session.get(app.$userSessionName).token.Value);
      return decodedToken && decodedToken.FeaturesId.includes(featureId);
    }
  };

  
export default function (app) {
  return app.config.globalProperties.$Utilities = Utilities;
}
