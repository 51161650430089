import { userSessionName } from "../js/axios";

export const Session = {
  set: (sessionName, value) => {
    localStorage.setItem(sessionName, JSON.stringify(value));
  },
  get: (sessionName) => {
    let storage = localStorage.getItem(sessionName);

    return storage ? JSON.parse(localStorage.getItem(sessionName)) : false;
  },
  remove: (sessionName) => {
    localStorage.removeItem(sessionName);
  },
  setUser: (value) => {
    let loggedUser = JSON.parse(localStorage.getItem(userSessionName));

    loggedUser.doctor = value;

    localStorage.setItem(userSessionName, JSON.stringify(loggedUser));
  },
  setUserAttribute: (attribute, value) => {
    let loggedUser = JSON.parse(localStorage.getItem(userSessionName));

    loggedUser.doctor[attribute] = value;

    localStorage.setItem(userSessionName, JSON.stringify(loggedUser));
  }
};

export default function (app) {
  return app.config.globalProperties.$Session = Session;
}
