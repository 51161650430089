import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode, resolveComponent as _resolveComponent, vShow as _vShow, withCtx as _withCtx, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 2,
  class: "patients-with-actions"
}
const _hoisted_4 = { class: "patient-card-column" }
const _hoisted_5 = { class: "actions-list-column" }
const _hoisted_6 = { class: "action-card" }
const _hoisted_7 = { class: "card-content" }
const _hoisted_8 = { class: "card-description" }

import { ref, onMounted, computed, getCurrentInstance } from 'vue';
import LilaDefaultTemplate from '@components/template/DefaultTemplate.vue';
import { FrontendAction, Action, Patient, FrontendPatient } from '@/models/index';
import PatientCardNOTLIST from '@components/shared/lila/PatientCardNOTLIST.vue';
import { utilities } from '@/plugins/UtilitiesV2';


export default /*@__PURE__*/_defineComponent({
  __name: 'AllActions',
  setup(__props) {

const actionsList = ref<FrontendAction[]>([]);

const isLoading = ref(false);
const alert = ref({
  type: null as string | null,
  message: '',
});

const instance = getCurrentInstance();


if (!instance) {
  throw new Error("getCurrentInstance() returned null. Ensure this is called within setup().");
}

const axios = instance.appContext.config.globalProperties.$axios;
const $Session = instance.appContext.config.globalProperties.$Session;
const $userSessionName = instance.appContext.config.globalProperties.$userSessionName;

const AlertType = {
  DANGER: 'danger',
  // Adicione outros tipos se necessário
};

const AlertMessage = {
  ERROR: 'Ocorreu um erro ao carregar as ações.',
  // Adicione outras mensagens se necessário
};

// Função para parsear a data no fuso horário local
const parseDateLocal = (dateString: string): Date => {
  const [year, month, day] = dateString.split('-').map(Number);
  return new Date(year, month - 1, day); // Meses em JavaScript são 0-based
};

// Método para formatar a data para exibição
const formatDate = (date: string | null): string => {
  if (!date) return 'Sem prazo';
  const parsedDate = parseDateLocal(date);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };
  return parsedDate.toLocaleDateString(undefined, options);
};

// Método para determinar a classe com base na data de prazo
const getDueDateClass = (dueDate: string | null): string => {
  if (!dueDate) return '';
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Definir 'today' para o início do dia

  const due = parseDateLocal(dueDate);

  if (due < today) {
    return 'overdue';
  }

  const oneWeekFromNow = new Date();
  oneWeekFromNow.setDate(today.getDate() + 7);
  oneWeekFromNow.setHours(0, 0, 0, 0);

  if (due <= oneWeekFromNow) {
    return 'due-soon';
  }

  return '';
};
// Função para criar um FrontendPatient a partir de um Patient
function createFrontendPatient(patient: Patient): FrontendPatient {
  const frontendPatient: FrontendPatient = {
    ...patient,
    painLevel: '', // Atribua valores padrão ou calcule conforme necessário
    displayCid: utilities.getSelectedPatientCid(patient),
    profilePicture: patient.Photo || null,
    imgLoaded: false,
    serviceLocation: '', // Atribua conforme necessário
    doctor: '', // Atribua conforme necessário
    ticketId: null,
    doctorPriority: null,
    selectedRace: null,
    selectedMaritalStatus: null,
    selectedEmployment: null,
    selectedEducation: null,
    selectedJourneyStage: null,
    selectedSymptomStartPeriod: null,
    frontendActions: [],
  };
  return frontendPatient;
}

// Modificar o método loadActionsFromAPI
async function loadActionsFromAPI(): Promise<void> {
  try {
    isLoading.value = true;

    // Obter o token de autenticação
    const token = $Session.get($userSessionName).token.Value;

    const response = await axios.get('/api/action/pending/professional', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const actionsFromAPI = response.data;

    actionsList.value = actionsFromAPI.map((action: Action) => {
      const patient = action.Patient;


      // Criar um FrontendPatient
      const frontendPatient = createFrontendPatient(patient);

      return {
        ...action,
        name: action.ActionType?.Name ?? 'Ação sem nome',
        title: action.ActionType?.Name ?? 'Sem título',
        description: action.ActionOutcomeObservations ?? 'Sem descrição',
        hidden: false,
        categoryClass: 'default-category',
        frontendPatient: frontendPatient,
      } as FrontendAction;
    });
  } catch (error) {
    console.error('Erro ao carregar ações da API:', error);
    alert.value.type = AlertType.DANGER;
    alert.value.message = AlertMessage.ERROR;
  } finally {
    isLoading.value = false;
  }
}

const patientsWithActions = computed((): Array<{ patient: FrontendPatient; actions: FrontendAction[] }> => {
  const groups: Record<number, FrontendAction[]> = {};

  actionsList.value.forEach((action) => {
    const patientId = action.frontendPatient.Id;
    if (!groups[patientId]) {
      groups[patientId] = [];
    }
    groups[patientId].push(action);
  });

  return Object.keys(groups)
    .map((patientId) => {
      const actions = groups[Number(patientId)];
      const patient = actions[0].frontendPatient; // Todas as ações para este paciente têm o mesmo frontendPatient

      // Ordenar as ações:
      // 1. Ações com DueDate (ordenadas por DueDate ascendente)
      // 2. Ações sem DueDate
      const sortedActions = actions.sort((a, b) => {
        const aDue = a.DueDate ? parseDateLocal(a.DueDate).getTime() : Infinity;
        const bDue = b.DueDate ? parseDateLocal(b.DueDate).getTime() : Infinity;
        return aDue - bDue;
      });

      return {
        patient: patient,
        actions: sortedActions,
      };
    });
});


onMounted(() => {
  loadActionsFromAPI(); // Carrega as ações da API
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(LilaDefaultTemplate, null, {
    mainContent: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[1] || (_cache[1] = _createElementVNode("h1", { class: "title" }, " Suas ações pendentes ", -1)),
        _cache[2] || (_cache[2] = _createElementVNode("p", null, " Veja aqui suas ações pendentes para serem feitas nos próximos 90 dias. ", -1)),
        (alert.value.message)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(`alert ${alert.value.type}`)
            }, _toDisplayString(alert.value.message), 3))
          : _createCommentVNode("", true),
        (isLoading.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, " Carregando... "))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(patientsWithActions.value, (patientWithActions) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: patientWithActions.patient.Id,
                  class: "patient-action-row"
                }, [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(PatientCardNOTLIST, {
                      patient: patientWithActions.patient,
                      "router-path": 'myPatientMedicalRecord',
                      class: "action-patient-card"
                    }, null, 8, ["patient"])
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(patientWithActions.actions, (action) => {
                      return _withDirectives((_openBlock(), _createBlock(_component_router_link, {
                        key: action.Id,
                        to: { name: 'myPatientActions', params: { id: patientWithActions.patient.Id } },
                        class: "action-link"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_6, [
                            _createElementVNode("div", _hoisted_7, [
                              _createElementVNode("h3", null, _toDisplayString(action.title), 1),
                              _createElementVNode("div", _hoisted_8, [
                                _createElementVNode("div", null, _toDisplayString(action.description), 1),
                                _cache[0] || (_cache[0] = _createElementVNode("span", { class: "action-arrow" }, "›", -1))
                              ]),
                              _createElementVNode("div", {
                                class: _normalizeClass(["due-date", getDueDateClass(action.DueDate)])
                              }, " Prazo: " + _toDisplayString(formatDate(action.DueDate)), 3)
                            ])
                          ])
                        ]),
                        _: 2
                      }, 1032, ["to"])), [
                        [_vShow, !action.hidden]
                      ])
                    }), 128))
                  ])
                ]))
              }), 128))
            ]))
      ])
    ]),
    _: 1
  }))
}
}

})