<template>
  <div class="calendar-create-event-dialog">
    <div
      class="calendar-create-event-content"
      @click.stop="closePatientSearchList()"
    >
      <div class="calendar-create-event-content-header">
        <span>Novo evento</span>
        <input
          v-model="localNewEvent.title"
          class="calendar-create-event-field-title"
          placeholder="Título"
          :class="{ 'error-border': !localNewEvent.title && showError }"
        >
        <p
          v-if="!localNewEvent.title && showError"
          class="error-message"
        >
          O campo Título é obrigatório.
        </p>
      </div>

      <div class="calendar-create-event-content-body">
        <textarea
          v-model="localNewEvent.contentDescription"
          placeholder="Descrição"
          class="calendar-create-event-field-description"
        />

        <div>
          <input
            v-model="searchQuery"
            type="text"
            list="patient-list"
            placeholder="Paciente"
            class="calendar-create-event-field-patient"
            :class="{ 'error-border': !localNewEvent.patient && showError }"
            @input="filterPatients"
          >
          <ul
            v-if="filteredPatients.length"
            class="calendar-create-event-field-patient-suggestions"
            @click.stop
          >
            <li
              v-for="patient in filteredPatients"
              :key="patient.Id"
              @click="selectPatient(patient)"
            >
              <img
                :src="patient.Photo || require('@/assets/images/default_avatar_neutral.png')"
                :alt="patient.Name"
                class="circular-mask small-image-25"
              >
              <span>{{ patient.Name }}</span>
            </li>
          </ul>
          <p
            v-if="!localNewEvent.patient && showError"
            class="error-message"
          >
            O campo Paciente é obrigatório.
          </p>
        </div>

        <div class="calendar-create-event-dialog-actions">
          <button @click="cancelEventCreation">
            Cancelar
          </button>
          <button @click="saveNewEvent">
            Salvar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CalendarEventTypes } from "@/enums/CalendarEventTypes.js";
import { calendarApi } from '@/js/axios.js';

export default {
  components: {
    CalendarEventTypes,
    calendarApi
  },
  props: {
    eventDate: {
      type: Object,
      default: null
    },
    alert: {
      type: Object,
      default: null
    },
    patients: {
      type: Array,
      default: null  
    }
  },
  data() {
    return {
      localNewEvent: { start: this.eventDate, end: this.eventDate, eventTypeId: CalendarEventTypes.CustomEvent, title: '', patient: null },
      searchQuery: '',
      filteredPatients: [],
      showError: false

    };
  },

  methods: {

    filterPatients() {
      this.localNewEvent.patient = null;
      this.filteredPatients = this.patients.filter(patient =>
        patient.Name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
    selectPatient(patient) {
      this.searchQuery = patient.Name;
      this.localNewEvent.patient = patient;
      this.filteredPatients = [];
    },
    closePatientSearchList() {
      this.filteredPatients = [];
    },
    cancelEventCreation() {
      this.$emit("close");
      this.localNewEvent = {};
    },
    async saveNewEvent() {

      if (!this.localNewEvent.title || !this.localNewEvent.patient) {
        this.showError = true; 
        return;
      }

      await this.$HTTPUtilities.post(
        this,
        `/api/calendar/patient/${this.localNewEvent.patient.Id}`,
        this.mountNewCalendarEventJSON(),
        (res) => {
          this.localNewEvent.id = res.data.id;
        },
        this.alert,
        calendarApi
      );

      this.$emit('save-new-event', this.localNewEvent);
      this.localNewEvent = {};
      this.showError = false;
    },
    mountNewCalendarEventJSON() {
      return {
        date: this.localNewEvent.start.format(),
        title: this.localNewEvent.title,
        description: this.localNewEvent.contentDescription,
        eventType: CalendarEventTypes.CustomEvent
      };
    }

  }
};
</script>

<style>
.calendar-create-event-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Fundo semi-transparente */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Certifique-se de que o modal esteja acima de outros elementos */
}

.calendar-create-event-content {
  padding: 0;
  background-color: white;
  max-width: 420px;
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
}

.calendar-create-event-content-header {
  padding: 20px;
}

.calendar-create-event-content-header span {
  color: #4f3f82;
  font-weight: bold;
  position: relative;
  font-size: 16px;
}

.calendar-create-event-field-title {
  width: 100%;
  margin-top: 20px;
  padding: 12px;
  border: 1px solid #767676;
  border-radius: 5px;
}

.calendar-create-event-content-body {
  padding: 0 20px 20px 20px;
}

.calendar-create-event-card {
  padding: 0;
}

.create-event-card-header {
  color: #4f3f82;
  font-weight: bold;
  font-size: 24px;
}

.calendar-create-event-field-description {
  margin-bottom: 20px;
  width: 100%;
  padding: 12px;
  border: 1px solid #767676;
  border-radius: 5px;
}

.calendar-create-event-field-patient {
  max-width: 400px;
  margin-bottom: 10px;
  width: 100%;
  padding: 12px;
  border: 1px solid #767676;
  border-radius: 5px;
}

.calendar-create-event-field-patient-suggestions {
  margin-top: -10px;
  margin-bottom: 50px;
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
  list-style-type: none;
  margin-left: 2px !important;
  margin-right: 20px;
}

.calendar-create-event-field-patient-suggestions li {
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  gap: 10px;
}

.calendar-create-event-field-patient-suggestions li:hover {
  background-color: #f0f0f0;
}

.calendar-create-event-dialog-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.calendar-create-event-dialog-actions button {
  padding: 10px 20px;
  border: 1px solid;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.calendar-create-event-dialog-actions button:first-of-type {
  background-color: white;
  color: black;
  border: 1px solid #ccc;
}

.calendar-create-event-dialog-actions button:last-of-type {
  background-color: #4f3f82;
  color: white;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.error-border {
  border-color: red;
}
</style>

