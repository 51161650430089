<template>
  <lila-default-template :loading-signal-r="loadingSignalR">
    <template #mainContent>
      <div
        class="container patient-ticket-list"
        :class="loadingContent ? 'loading large' : ''"
      >
        <div class="patient-ticket-header">
          <h1 class="title">
            Chamados
          </h1>
        </div>

        <lila-message :alert-data="alert" />

        <div
          v-show="!loadingContent"
          class="columns"
        >
          <div class="column is-narrow patient-sidebar">
            <div class="conversation-list-summary">
              <ul>
                <li class="urgent">
                  ({{ $Utilities.leadingZero(conversationList.urgent.length) }}) Urgentes
                </li>
                <li class="moderate">
                  ({{ $Utilities.leadingZero(conversationList.moderate.length) }}) Moderada
                </li>
                <li class="low">
                  ({{ $Utilities.leadingZero(conversationList.low.length) }}) Baixa
                </li>
              </ul>
            </div>

            <div
              ref="conversationListView"
              class="conversation-list custom-scroll"
            >
              <div
                v-show="data.symptomsAlertList.length > 0"
                class="conversation-content"
              >
                <div class="conversation-list-header">
                  <p class="conversation-list-title">
                    Alerta de Sintomas
                  </p>

                  <div
                    v-show="deletingAllSymptomsAlerts"
                    class="loading very-small"
                  />

                  <button
                    v-show="!deletingAllSymptomsAlerts"
                    class="clear-filter"
                    @click="deleteAllAlert"
                  >
                    Limpar tudo
                  </button>
                </div>

                <lila-diagnostic-card
                  :data-list="data.symptomsAlertList"
                  router-path="patientClinicalEvolution"
                  @custom-click-event="selectPatient"
                  @custom-delete-alert-card="deleteAlert"
                />
              </div>

              <div
                v-show="((conversationList.urgent.length > 0)
                  || (conversationList.moderate.length > 0)
                  || (conversationList.low.length > 0))"
                class="conversation-content"
              >
                <div class="conversation-list-header">
                  <p class="conversation-list-title">
                    Conversas
                  </p>

                  <div
                    v-show="deletingAllTickets"
                    class="loading very-small"
                  />

                  <button
                    v-show="!deletingAllTickets
                      && conversationList 
                      && (conversationList.urgent.length > 0
                        || conversationList.moderate.length > 0
                        || conversationList.low.length > 0)"
                    class="clear-filter"
                    @click="deleteAllTickets"
                  >
                    Limpar tudo
                  </button>
                </div>

                <lila-ticket-card
                  :data-list="conversationList.urgent"
                  :selected-patient="data.selectedPatient"
                  router-path="patientChat"
                  @custom-click-event="selectPatient"
                  @custom-delete-ticket-card="deleteTicket"
                />

                <lila-ticket-card
                  :data-list="conversationList.moderate"
                  :selected-patient="data.selectedPatient"
                  router-path="patientChat"
                  @custom-click-event="selectPatient"
                  @custom-delete-ticket-card="deleteTicket"
                />

                <lila-ticket-card
                  :data-list="conversationList.low"
                  :selected-patient="data.selectedPatient"
                  router-path="patientChat"
                  @custom-click-event="selectPatient"
                  @custom-delete-ticket-card="deleteTicket"
                />
              </div>
            </div>
          </div>

          <div class="column patient-column">
            <div
              v-if="pageName == 'patientTickets'"
              class="wait-patient has-text-centered"
            >
              <figure class="image">
                <img :src="require(`@assets/images/doctor.png`)">
              </figure>

              <p>Clique nos chamados ao lado para começar a interagir</p>
            </div>

            <div
              v-else
              ref="patientBox"
              class="patient-box"
            >
              <lila-patient-card-summary
                v-if="data.selectedPatient"
                :patient="data.selectedPatient"
              />

              <div class="menu-patient-card">
                <ul>
                  <li :class="$route.name == 'patientMedicalRecord' ? 'active' : ''">
                    <router-link :to="{name: 'patientMedicalRecord'}">
                      Ficha
                    </router-link>
                  </li>
                  <li :class="$route.name == 'patientHistoryRecord' ? 'active' : ''">
                    <router-link :to="{name: 'patientHistoryRecord'}">
                      Registros
                    </router-link>
                  </li>
                  <li
                    v-show="userHasPermission(2)"
                    :class="$route.name == 'patientPrescriptions' ? 'active' : ''"
                  >
                    <router-link :to="{name: 'patientPrescriptions'}">
                      Receita Médica
                    </router-link>
                  </li>
                  <li :class="$route.name == 'patientClinicalEvolution' ? 'active' : ''">
                    <router-link :to="{name: 'patientClinicalEvolution'}">
                      Evolução Clínica
                    </router-link>
                  </li>
                  <li :class="$route.name == 'patientChat' ? 'active' : ''">
                    <router-link :to="{name: 'patientChat'}">
                      Conversa
                    </router-link>
                  </li>
                  <li
                    v-show="userHasPermission(8)" 
                    :class="$route.name == 'patientBarriers' ? 'active' : ''"
                  >
                    <router-link :to="{name: 'patientBarriers'}">
                      Barreiras
                    </router-link>
                  </li>
                  <li
                    v-if="hasAccessToActionFeature()"
                    :class="$route.name == 'patientActions' ? 'active' : ''"
                  >
                    <router-link :to="{name: 'patientActions', id: data.selectedPatient ? data.selectedPatient.id : ''}">
                      Ações
                    </router-link>
                  </li>
                  <li :class="$route.name == 'patientClosedMessages' ? 'active' : ''">
                    <router-link :to="{name: 'patientClosedMessages'}">
                      Conversas Encerradas
                    </router-link>
                  </li>
                  <li :class="$route.name == 'patientAttachments' ? 'active' : ''">
                    <router-link :to="{name: 'patientAttachments'}">
                      Anexos
                    </router-link>
                  </li>
                </ul>
              </div>

              <router-view
                :closed-ticket-id="closedTicketId"
                :new-message-for-patient="newMessageForPatient"
                @update-ticket-priority="updateTicketPriority"
                @remove-ticket="removeTicket"
                @update-ticket-message="updateTicketMessage"
                @update-patient-card-summary="updatePatientCardSummary"
                @update-patient-ticket-card="updatePatientTicketCard"
                @update-patient-symptom-alert-card="updatePatientSymptomAlertCard"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </lila-default-template>
</template>

<script>
import DefaultTemplate from "@components/template/DefaultTemplate.vue";
import { provide, ref } from "vue";
import TicketCard from "@components/shared/lila/TicketCard.vue";
import DiagnosticCard from "@components/shared/lila/DiagnosticCard.vue";
import PatientCardSummary from "@components/shared/lila/PatientCardSummary.vue";
import Message from "@components/shared/message/Message.vue";
import { Utilities } from "../../plugins/Utilities.js";
import decodeToken from "../../js/decodeToken.js";
import { eventBus } from "../../eventBus";
import { onMounted, onBeforeUnmount } from "vue";




require("@microsoft/signalr");

export default {
  components: {
    "lila-ticket-card": TicketCard,
    "lila-diagnostic-card": DiagnosticCard,
    "lila-patient-card-summary": PatientCardSummary,
    "lila-message": Message,
    "lila-default-template": DefaultTemplate
     
  },
  setup () {
    const alert = ref({
        message: "",
        type: "",
        show: false
    });

    const conversationList = ref ({
        urgent: [],
        moderate: [],
        low: []
      }
    );

    provide("alert-message", alert);

    const getTicket = (patientId) => {

      let index = null;
      let ticket = null;


      ["urgent", "moderate", "low"].forEach(priority => {
        index = conversationList.value[priority].map(item => item.id).indexOf(parseInt(patientId));

        if (index != -1) {
          ticket = conversationList.value[priority].splice(index, 1);
        }
      });

      return ticket;
    };

    
    function getPatientUrgency(urgency){
      switch (urgency) {
      case "High":
        return "urgent";

      case "Normal":
        return "moderate";

      case "Low":
        return "low";
      }
    }

    function updateTicketPriority(payload){
      let ticket = null;

      payload.priority = getPatientUrgency(payload.priority);

      ticket = getTicket(payload.patientId);

      if(ticket != null){
        ticket[0].urgency = payload.priority;
        conversationList.value[payload.priority].push(ticket[0]);
      }
    }

    const removeTicketByPatientId = (patientId) => {
      getTicket(patientId);
    };

    let updateTicketPriorityHandler = null;

    onMounted(() => {
      eventBus.on("removeTicket", removeTicketByPatientId);
      updateTicketPriorityHandler = (payload) => updateTicketPriority(payload);
      eventBus.on("updateTicketPriority", updateTicketPriorityHandler);
    });

    onBeforeUnmount(() => {
      eventBus.off("removeTicket", removeTicketByPatientId);
      
      if (updateTicketPriorityHandler) {
        eventBus.off("updateTicketPriority", updateTicketPriorityHandler);
      }
      
    });

    return { alert,
          conversationList,
          getTicket,
          getPatientUrgency };
  },
  data() {
    return {
      userId: null,
      data: {
        symptomsAlertList: [],
        selectedPatient: null
      },
      pageName: this.$route.name,
      ticketMessageSignalR: {
        active: false,
        connection: null,
        timeout: false,
        retryCount: 0
      },
      symptomSignalR: {
        active: false,
        connection: null,
        timeout: false,
        retryCount: 0
      },
      ticketTransferSignalR: {
        active: false,
        connection: null,
        timeout: false,
        retryCount: 0
      },
      signalRTimeout: 5000,
      signalRMaxRetry: 3,
      loading: false,
      loadingContent: false,
      loadingSignalR: false,
      deletingAllTickets: false,
      deletingAllSymptomsAlerts: false,
      patientColumnHeight: 0,
      patientColumnHeightInterval: null,
      closedTicketId: null,
      newMessageForPatient: {}
    };
  },
  watch:{
    $route (to){
      this.pageName = to.name;
      if(this.pageName == "patientTickets") this.data.selectedPatient = null;
    }
  },
  created(){

    switch (this.$Session.get(this.$userSessionName).profile) {
    case "doctor":
      this.userId = this.$Session.get(this.$userSessionName).doctor.Id;
      break;
    default:
      this.userId = this.$Session.get(this.$userSessionName).professional.Id;
      break;
    }

    this.getContent();

    this.startTicketMessageSignalR();
    this.startSymptomSignalR();
    this.startTicketTransferSignalR();

},
  mounted(){
    this.controlSidebarHeight();
  },
  beforeUnmount(){
    clearInterval(this.patientColumnHeightInterval);

    this.closeSignalRConnections();
  },



  methods: {
    getContent(){
      this.loadingContent = true;

      Promise
        .all([
          this.getPatientTicketList(),
          this.getPatientSymptomsAlertList()
        ])
        .finally(() => this.loadingContent = false);
    },
    getPatientTicketList(){
      let ticketUrl = "";

      switch (this.$Session.get(this.$userSessionName).profile) {
      case "professional_leader":
        ticketUrl = "api/ticket/professional";
        break;
      case "professional_default":
        ticketUrl = "api/ticket/professional";
        break;
      default:
        ticketUrl = "api/ticket";
        break;
      }


      return this.$axios
        .get(
          ticketUrl,
          {headers: {"Authorization": `bearer ${this.$Session.get(this.$userSessionName).token.Value}`}}
        )
        .then((res) => {

          this.conversationList.urgent = this.mountCardList(res.data.High);
          this.conversationList.moderate = this.mountCardList(res.data.Normal);
          this.conversationList.low = this.mountCardList(res.data.Low);

          if(this.$route.params.id){
            this.getSelectedPatient(this.$route.params.id);

            if(this.data.selectedPatient == null) this.$router.push({name: "patientTickets"});
          }

        })
        .catch(error => {
          console.error(error);
          this.$Utilities.verifyToken(error.response.status);
          this.$Utilities.controlAlert(error.response.data, this.alert, "danger");
        });


    },

    mountCard(ticket){


      let patient = this.$Utilities.getValueIgnoreCase(ticket, "patient");
      let attendancePlace = this.$Utilities.getValueIgnoreCase(patient, "attendancePlace");
      let messages = this.$Utilities.getValueIgnoreCase(ticket, "messages");

      return {
        id: this.$Utilities.getValueIgnoreCase(patient, "id"),
        ticketId: this.$Utilities.getValueIgnoreCase(ticket, "id"),
        messagesIdList: this.$Utilities.getValueIgnoreCase(ticket, "messages") != null ? this.$Utilities.getValueIgnoreCase(ticket, "messages")
          .map(item => this.$Utilities.getValueIgnoreCase(item, "id")) : [],
        name: this.$Utilities.getValueIgnoreCase(patient, "name"),
        profilePicture: this.$Utilities.getValueIgnoreCase(patient, "photo") ? this.$Utilities.getValueIgnoreCase(patient, "photo") : this.$Utilities.getDefaultProfilePicture(this.$Utilities.getValueIgnoreCase(patient, "gender")),
        cid: this.$Utilities.getPatientCid(patient) == null ? "" : this.$Utilities.getPatientCid(patient),
        gender: this.$Utilities.getValueIgnoreCase(patient, "gender"),
        birthDate: this.$Utilities.getValueIgnoreCase(patient, "birthDate"),
        painLevel: "",
        serviceLocation: attendancePlace ? this.$Utilities.getValueIgnoreCase(attendancePlace, "street") : "",
        message: this.$Utilities.getValueIgnoreCase(messages[messages.length - 1], "AttachedDocument") ? "[um arquivo foi enviado]" : this.$Utilities.getValueIgnoreCase(messages[messages.length - 1], "message"),
        amountMessage: messages.filter(i => this.$Utilities.getValueIgnoreCase(i, "origin") == "Patient").length > 0 ? messages.filter(i => this.$Utilities.getValueIgnoreCase(i, "origin") == "Patient").length : "",
        doctorPriority: this.getPatientUrgency(this.$Utilities.getValueIgnoreCase(ticket, "doctorPriority")),
        date: messages[messages.length - 1] ? this.$Utilities.formatDateWithTime(this.$Utilities.getValueIgnoreCase(messages[messages.length - 1], "DateAndTime"), "YYYY-MM-DDTHH:mm:ss", "DD/MM/YYYY - HH:mm:ss") : "",
        imgLoaded: false,
        deletingTicket: false
      };

    },

    mountCardList(ticketList){
      return ticketList.map(item => {
        return this.mountCard(item);
      });
    },

    
    createTicketMessage(ticket){

      let priority = this.getPatientUrgency(this.$Utilities.getValueIgnoreCase(ticket, "doctorPriority"));
      let card = this.mountCard(ticket);
      this.conversationList[priority].push(card);
    },

    getPatientSymptomsAlertList(){
      return this.$axios
        .get(
          "/api/ticket/symptoms",
          {headers: {"Authorization": `bearer ${this.$Session.get(this.$userSessionName).token.Value}`}}
        )
        .then((res) => {

            this.data.symptomsAlertList = [];
            this.createSymptomAlerts(res.data);
        })
        .catch(error => {
          this.$Utilities.verifyToken(error.response.status);
          this.$Utilities.controlAlert(error.response.data, this.alert, "danger");
        });
    },

    getPatientPain(patientId){
      this.$axios
        .get(
          `/api/clinicalevolution/pain/patient/${patientId}`,
          {headers: {"Authorization": `bearer ${this.$Session.get(this.$userSessionName).token.Value}`}}
        )
        .then((res) => {
          if(res.data) this.data.selectedPatient.painLevel = `EVA ${res.data.Evolutions[0].Intensity}`;
        })
        .catch(error => {
          this.$Utilities.verifyToken(error.response.status);
        })
        .finally(() => this.loadingContent = false);
    },
    deleteAlert(alert){
      let alertList = [];

      alertList.push({
        id: alert.alertId
      });

      this.deleteSymptomAlert(alertList);
    },
    deleteAllAlert(){
      let alertList = [];

      this.data.symptomsAlertList.forEach(item => {
        alertList.push({
          id: item.alertId
        });
      });

      this.deleteSymptomAlert(alertList);
    },
    removeAlert(alertId){
      let index = this.data.symptomsAlertList.map(item => item.alertId).indexOf(parseInt(alertId));

      if(index != -1) this.data.symptomsAlertList.splice(index, 1);
    },
    deleteSymptomAlert(alertList){
      let alertListArray = alertList.map(item => item.id);

      this.deletingAllSymptomsAlerts = true;

      for(let index in this.data.symptomsAlertList){
        if(alertListArray.indexOf(this.data.symptomsAlertList[index].alertId) != -1) this.data.symptomsAlertList[index].deletingAlert = true;
      }

      this.$axios({
        method: "PUT",
        url: "/api/ticket/symptoms",
        data: alertList,
        headers: {"Authorization": `bearer ${this.$Session.get(this.$userSessionName).token.Value}`}
      })
        .catch(error => {
          this.$Utilities.verifyToken(error.response.status);
          this.$Utilities.controlAlert(error.response.data, this.alert, "danger");
        })
        .finally(() => {
          alertList.forEach(item => this.removeAlert(item.id));

          this.deletingAllSymptomsAlerts = false;
        });
    },
    
    deleteMessageTicket(ticketList){
      ticketList.forEach(item => {
        ["urgent", "moderate", "low"].forEach(priority => {
          for(let index in this.conversationList[priority]){
            if(this.conversationList[priority][index].id == item.patientId) this.conversationList[priority][index].deletingTicket = true;
          }
        });
      });

      this.$axios({
        method: "PUT",
        url: "/api/ticket",
        data: ticketList,
        headers: {"Authorization": `bearer ${this.$Session.get(this.$userSessionName).token.Value}`}
      })
        .catch(error => {
          this.$Utilities.verifyToken(error.response.status);
          this.$Utilities.controlAlert(error.response.data, this.alert, "danger");
        })
        .finally(() => {
          ticketList.forEach(item => this.removeTicket(item.patientId));

          this.deletingAllTickets = false;
        });
    },
    deleteTicket(patient){
      let ticketList = [];

      this.getTicket(patient.id)[0].messagesIdList.forEach(messageId => {
        ticketList.push({
          id: messageId,
          patientId: patient.id
        });
      });

      this.deleteMessageTicket(ticketList);
    },

    deleteAllTickets(){
      let ticketList = [];

      this.deletingAllTickets = true;

      ["urgent", "moderate", "low"].forEach(priority => {
        this.conversationList[priority].forEach(ticket => {
          ticket.messagesIdList.forEach(messageId => {
            ticketList.push({
              id: messageId,
              patientId: ticket.id
            });
          });
        });
      });

      this.deleteMessageTicket(ticketList);
    },
    selectPatient(patient){
      let selectedPatient = Object.assign({}, patient);

      if(this.data.selectedPatient && this.data.selectedPatient.profilePicture == selectedPatient.profilePicture){
        selectedPatient.imgLoaded = true;
      }

      this.data.selectedPatient = selectedPatient;

      this.getPatientPain(this.data.selectedPatient.id);
    },
    getSelectedPatient(patientId){
      let patient = null;

      ["urgent", "moderate", "low"].forEach(index => {
        patient = this.conversationList[index].filter(item => item.id == patientId);

        if(patient.length > 0) this.selectPatient(patient[0]);

      });

    },

    updateTicketMessage(priority, patientId, message, date, ticket){

      priority = this.getPatientUrgency(priority);

      let card = this.criarOuRecuperarCard(patientId, ticket, message, priority);

      if (message.origin == 2 || message.origin.toUpperCase() == "PROFESSIONAL") 
          card[0].amountMessage = "";
      
      card[0].message = message.message;
      card[0].date = this.$Utilities.formatDateWithTime(date, "YYYY-MM-DDTHH:mm:ss", "DD/MM/YYYY - HH:mm:ss");
      card[0].messagesIdList.push(this.$Utilities.getValueIgnoreCase(message, "id"));

      this.conversationList[priority].push(card[0]);
    },


    updatePatientCardSummary(patient) {
      this.data.selectedPatient.name = patient.name || patient.Name;
      this.data.selectedPatient.cid = Utilities.getSelectedPatientCid(patient);
      this.data.selectedPatient.gender = patient.gender || patient.Gender,
      this.data.selectedPatient.birthDate = (patient.birthdate || patient.BirthDate) 
  ? this.$Utilities.formatDate(patient.birthdate || patient.BirthDate, "DD/MM/YYYY", "MM/DD/YYYY") 
  : "";

      this.data.selectedPatient.diagnostic = patient.diagnostic | patient.Diagnostic;
    },
    updatePatientTicketCard(patient) {
      let priorities = ["urgent", "moderate", "low"];
      
      for(let priority of priorities) {
        for(let ticket of this.conversationList[priority]) {
          if(ticket.id == this.data.selectedPatient.id) {
            ticket.name = patient.Name;
            ticket.cid = Utilities.getSelectedPatientCid(patient);
            ticket.gender = patient.Gender;
            ticket.birthDate = patient.BirthDate ? this.$Utilities.formatDate(patient.BirthDate, "DD/MM/YYYY", "MM/DD/YYYY") : "";
            break; 
          }
        }
      }
    },
    updatePatientSymptomAlertCard(patient) {
      for(let alert of this.data.symptomsAlertList) {
        if(alert.id == this.data.selectedPatient.id) {
          alert.name = patient.Name;
          alert.cid = Utilities.getSelectedPatientCid(patient);
          alert.gender = patient.Gender;
          alert.birthDate = patient.BirthDate ? this.$Utilities.formatDate(patient.BirthDate, "DD/MM/YYYY", "MM/DD/YYYY") : "";
        }
      }
    },
    criarOuRecuperarCard(patientId, chat, message, priority){
      
      if(this.conversationList[priority].map(item => item.id).indexOf(parseInt(patientId)) == -1) {
          return [this.mountCard(chat, message)];
      }
      else {
        return this.getTicket(patientId);
      }
    },

    removeTicket(patientId){
      this.getTicket(patientId);
    },

    getPatientUrgencyByNumber(number){
      
      switch (number) {
      case 0:
        return "urgent";

      case 1:
        return "moderate";

      case 2:
        return "low";
      }
    },
    userHasPermission(feature) {
      const decodedToken = decodeToken(this.$Session.get(this.$userSessionName).token.Value);
      return decodedToken && decodedToken.FeaturesId.includes(feature);
    },

    startTicketMessageSignalR(){

      try{
        if(this.ticketMessageSignalR.connection != null) return true;

        this.ticketMessageSignalR.connection = this.$SignalRConnector.startSignalR("messageprofessionalhub", (connection) => {
          this.startTicketMessageSignalRListeners(connection);
        }, this.signalRTimeout, String(this.userId));

      }

      catch(erro) {

          console.log("Erro na comunicação com o SignalR: " + erro);

          if(this.ticketMessageSignalR.retryCount < this.signalRMaxRetry){
            ++this.ticketMessageSignalR.retryCount;

            this.startTicketMessageSignalR();
          }
        }
    },



    async startTicketMessageSignalRListeners(connection){

          connection.on("PatientMessage", (res) => {
            this.loadingSignalR = true;
            this.newMessageForPatient = {
              patientId: res.fromId,
              ticketId: res.ticketId
            };

            this.$axios
              .all([this.getPatientTicketList()])
              .finally(() => this.loadingSignalR = false);
          });

          
          connection.on("ChatClosed", (res) => {
            this.loadingSignalR = true;
            this.closedTicketId = res.id;

            this.getTicket(res.patient.id);

            this.loadingSignalR = false;
          });

          connection.on("ProfessionalMessage", (chat, message) => {
            this.loadingSignalR = true;
            this.updateTicketMessage(chat.doctorPriority, chat.patient.id, message, message.dateAndTime, chat);
            this.loadingSignalR = false;
          });
    },

    startSymptomSignalR(){

      try{
        if(this.symptomSignalR.connection != null) return true;

        this.symptomSignalR.connection = this.$SignalRConnector.startSignalR("symptomhub", (connection) => {
          this.startSymptomSignalRListeners(connection);
        }, this.signalRTimeout, String(this.userId));
      }
      catch(erro) {

        console.log("Erro na comunicação com o SignalR: " + erro);

        if(this.symptomSignalR.retryCount < this.signalRMaxRetry){
          ++this.symptomSignalR.retryCount;

          this.startSymptomSignalR();
        }
      }

    },

    async startSymptomSignalRListeners(connection){

      connection.on("SymptomAlert", () => {
        this.loadingSignalR = true;

         this.$axios
            .all([this.getPatientSymptomsAlertList()])
            .finally(() => this.loadingSignalR = false);
      });
      
    },

    startTicketTransferSignalR(){

      try{
        if(this.ticketTransferSignalR.connection != null) return true;

        this.ticketTransferSignalR.connection = this.$SignalRConnector.startSignalR("tickettransferhub", (connection) => {
          this.startTransferSignalRListeners(connection);
        }, this.signalRTimeout, String(this.userId));

      }

      catch(erro) {

          console.log("Erro na comunicação com o SignalR: " + erro);
          if(this.ticketTransferSignalR.retryCount < this.signalRMaxRetry){
            ++this.ticketTransferSignalR.retryCount;

            this.startTicketTransferSignalR();
          }
        }

    },


    async startTransferSignalRListeners(connection){


      connection.on("TicketHandedOff", (transferMessage) => {
            
        this.loadingSignalR = true;

        let ticket = this.$Utilities.getValueIgnoreCase(transferMessage, "ticket");      
        let patient = this.$Utilities.getValueIgnoreCase(ticket, "patient");

        this.removeTicket(this.$Utilities.getValueIgnoreCase(patient, "id"));
            
        this.loadingSignalR = false;
      });

      connection.on("TicketReceived", (transferMessage) => {
                
        this.loadingSignalR = true;
                      
        let ticket = transferMessage.ticket;
        this.createTicketMessage(ticket);

        let clinicalEvolutions = transferMessage.symptoms;
        clinicalEvolutions.forEach(clinicalEvolution => {
          this.createSymptomAlertsForPatient(clinicalEvolution, ticket.patient);
        });

        this.loadingSignalR = false;
      });

    },

    closeSignalRConnections(){
      if(this.ticketMessageSignalR.timeout != null) clearTimeout(this.ticketMessageSignalR.timeout);
      if(this.symptomSignalR.timeout != null) clearTimeout(this.symptomSignalR.timeout);
      if(this.ticketTransferSignalR.timeout != null) clearTimeout(this.ticketTransferSignalR.timeout);

      this.$SignalRConnector.stopSignalR(this.ticketMessageSignalR.connection);
      this.$SignalRConnector.stopSignalR(this.symptomSignalR.connection);
      this.$SignalRConnector.stopSignalR(this.ticketTransferSignalR.connection);

      this.ticketMessageSignalR.active = false;
      this.symptomSignalR.active = false;
      this.ticketTransferSignalR.active = false;

    },

    createSymptomAlerts(clinicalEvolutions){

      clinicalEvolutions.forEach(item => {

          this.createSymptomAlertsForPatient(item, this.$Utilities.getValueIgnoreCase(item, "patient"));
      });
    },
    
    createSymptomAlertsForPatient(clinicalEvolution, patient){


         let attendancePlace = this.$Utilities.getValueIgnoreCase(patient, "attendancePlace");
         let evolutions = this.$Utilities.getValueIgnoreCase(clinicalEvolution, "evolutions"); 
         let patientPhoto = (this.$Utilities.getValueIgnoreCase(patient, "photo") != null && this.$Utilities.getValueIgnoreCase(patient, "photo") != undefined) ?
              this.$Utilities.getValueIgnoreCase(patient, "photo")
              : this.$Utilities.getDefaultProfilePicture(this.$Utilities.getValueIgnoreCase(patient, "gender"));
       
         evolutions.forEach(evolution => {

           let symptom = this.$Utilities.getValueIgnoreCase(evolution, "symptom");
           this.data.symptomsAlertList.push({
             id: this.$Utilities.getValueIgnoreCase(patient, "id"),
             alertId: this.$Utilities.getValueIgnoreCase(evolution, "id"),
             name: this.$Utilities.getValueIgnoreCase(patient, "name"),
             profilePicture: patientPhoto,
             cid: this.$Utilities.getPatientCid(patient),
             gender: this.$Utilities.getValueIgnoreCase(patient, "gender"),
             birthDate: this.$Utilities.getValueIgnoreCase(patient, "birthDate"),
             painLevel: "",
             serviceLocation: attendancePlace ? this.$Utilities.getValueIgnoreCase(attendancePlace, "street") : "",
             diagnostic: {
               
               isEva: this.$Utilities.getValueIgnoreCase(symptom, "isEva"),
               name: this.$Utilities.getValueIgnoreCase(symptom, "name"),
               intensity: this.$Utilities.getValueIgnoreCase(evolution, "intensity")
             },
             date: this.$Utilities.formatDateWithTime(this.$Utilities.getValueIgnoreCase(clinicalEvolution, "dateAndTime"), "YYYY-MM-DDTHH:mm:ss", "DD/MM/YYYY HH:mm"),
             imgLoaded: false,
             deletingAlert: false,
             doctor: ""
           });
        });
    },

    controlSidebarHeight(){
      this.patientColumnHeightInterval = setInterval(($this) => {
        if (!$this.$refs.patientBox) return null;
        if(typeof $this.$refs.patientBox !== "undefined" && $this.patientColumnHeight != $this.$refs.patientBox.offsetHeight){
          $this.patientColumnHeight = $this.$refs.patientBox.offsetHeight;
          $this.$refs.conversationListView.style.height = `${$this.patientColumnHeight - 50}px`;
        }
      }, 500, this);
    },
    reloadTicketList(){
      this.getContent();
    },

    hasAccessToActionFeature(){
      return this.$Utilities.hasAccessToFeature(this, 7);
    }
  }


};
</script>

<style>
    @import '~@assets/css/components/patientTickets.css';
    @import '~@assets/css/views/patientTickets.css';

.patient-ticket-list .columns {
  display: flex;
}

.patient-ticket-list .patient-sidebar {
  flex: 1;
  max-width: 400px;
}

.patient-ticket-list .patient-column {
  flex: 2;
  min-width: 300px;
}
</style>
