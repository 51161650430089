<template>
  <div
    id="app"
    class="app"
  >
    <main
      v-if="isInternalLink"
      class="is-gapless internal-link"
    >
      <router-view />
    </main>

    <main v-else>
      <router-view />
    </main>

    <lila-dialog />
  </div>
</template>

<script>
import LilaDialog from "@components/shared/LilaDialog.vue";
export default {
  name: "App",
  components: {
    LilaDialog
  },
  computed: {
    isInternalLink(){
      return !this.$notRequiredAuth.includes(this.$route.name);
    }
  }
};
</script>

<style>
	@import './../node_modules/bulma/css/bulma.css';
</style>
