<template>
  <lila-login-template>
    <template #mainContent>
      <div :class="loadingContent ? 'loading large' : ''">
        <lila-message :alert-data="alertTop" />

        <form
          v-show="showForm"
          class="login-form full-width"
          @submit.prevent="submitForm()"
        >
          <h1 class="title">
            Criar Senha
          </h1>

          <lila-message :alert-data="alert" />

          <lila-input
            v-model="v.data.password.$model"
            placeholder="Digite a senha"
            type="password"
            :validation="v.data.password"
          />

          <lila-input
            v-model="v.data.confirmPassword.$model"
            placeholder="Confirme a senha"
            type="password"
            :validation="v.data.confirmPassword"
          />

          <lila-button
            type="submit"
            title="Confirmar"
            class="submit-buttons"
            :loading="loading"
          />
        </form>
      </div>
    </template>
  </lila-login-template>
</template>

<script>
import LoginTemplate from "@components/template/LoginTemplate.vue";

import Input from "@components/shared/fields/Input.vue";
import Button from "@components/shared/button/Button.vue";
import Message from "@components/shared/message/Message.vue";

import { required, minLength, maxLength, sameAs } from "@vuelidate/validators";

const checkSpaces = function(value){
  return !/\s/.test(value);
};

export default {
  components: {
    "lila-input": Input,
    "lila-button": Button,
    "lila-message": Message,
    "lila-login-template": LoginTemplate
  },
  data() {
    return {
      data: {
        password: "",
        confirmPassword: "",
        token: ""
      },
      alert: {
        message: "",
        type: "",
        show: false
      },
      alertTop: {
        message: "",
        type: "",
        show: false
      },
      loading: false,
      loadingContent: false,
      showForm: false
    };
  },
  validations() {
    return {
      data: {
        password: {
          required,
          checkSpaces,
          minLength: minLength(6),
          maxLength: maxLength(15)
        },
        confirmPassword: {
          required,
          minLength: minLength(6),
          maxLength: maxLength(15),
          sameAsPassword: sameAs(this.data.password)
        }
      }
    };
  },
  created(){
    this.verifyToken();
  },
  methods: {
    submitForm(){
      this.alert.show = false;
      this.alertTop.show = false;

      this.v.$touch();

      if(this.v.$invalid) return;

      this.loading = true;

      this.$axios
        .put("api/account/first-password", this.data)
        .then(() => this.$router.push({name: "login", params: {message: "firstPasswordCreated"}}))
        .catch(error => this.$Utilities.controlAlert(error.response.data, this.alert, "danger"))
        .finally(() => this.loading = false);
    },
    verifyToken(){
      this.loadingContent = true;

      if(typeof this.$route.params.token == "undefined") this.$router.push({name: "login"});

      this.$axios
        .get(`api/account/first-access?token=${this.$route.params.token}`)
        .then(() => {
          this.showForm = true;
          this.data.token = this.$route.params.token;
        })
        .catch(error => {
          if(error.response.status == 404){
            this.$router.push({name: "login", params: {message: "expiredToken"}});
          }else{
            this.$router.push({name: "login", params: {message: "danger"}});
          }
        })
        .finally(() => this.loadingContent = false);
    }
  }
};
</script>

<style></style>
