<template>
  <div
    :class="[ loading ? 'loading large' : '', 'popupActions']"
    @click="untoggleProfessionalsDropdownIfVisible(); closePopup()"
  >
    <div
      class="popupActions-content"
      @click.stop="untoggleProfessionalsDropdownIfVisible"
    >
      <div v-if="showCategoriesPopup">
        <h3>Selecione uma categoria</h3>
        <ul>
          <li
            v-for="category in categories"
            :key="category"
            @click="selectCategory(category)"
          >
            {{ category.Name }}
          </li>
        </ul>
      </div>

      <div v-if="showActionTypesPopup">
        <h3>Selecione uma ação</h3>
        <ul>
          <li
            v-for="actionType in filterActionTypesByCategories()"
            :key="actionType"
            :class="{
              'action-type-selected': actionType === selectedActionType
            }"
            @click="selectAction(actionType)"
          >
            {{ actionType.Name }}
          </li>
        </ul>
      </div>

      <div v-if="showActionTypesPopup">
        <p class="popupActions-subtitle">
          Prazo
        </p>
        <vue-cal
          class="vuecal--date-picker small-calendar vuecal--purple-theme"
          xsmall
          hide-view-selector
          :time="false"
          :transitions="false"
          active-view="month"
          locale="pt-br"
          :disable-views="['week']"
          @cell-click="selectDate"
        />

        <!-- Caixa de seleção de profissional -->
        <div
          class="professional-assigned-box"
          @click.stop="toggleProfessionalsDropdown"
        >
          <img
            :src="
              selectedProfessional?.Photo ||
                require('@/assets/images/default_avatar_neutral.png')
            "
            class="default-avatar circular-mask small-image-35"
          >
          <p v-if="selectedProfessional">
            {{ selectedProfessional.Name }}
          </p>
        </div>

        <!-- Lista de profissionais (dropdown) -->
        <ul
          v-if="professionalsDropdownVisible"
          class="professionals-assigned-dropdown-list"
        >
          <li
            v-for="professional in professionals"
            :key="professional.Id"
            class="dropdown-item"
            @click="selectProfessional(professional)"
          >
            {{ professional.Name }}
          </li>
        </ul>
      </div>

      <div id="add-action-buttons">
        <button
          class="popupActions-container-button"
          @click="closePopup()"
        >
          Fechar
        </button>

        <button
          v-if="showActionTypesPopup"
          id="saveAction"
          :disabled="!selectedActionType"
          class="popupActions-container-button"
          @click="addAction(selectedActionType)"
        >
          Salvar
        </button>
      </div>
    </div>
  </div>
</template>
  
  <script>
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";

export default {
  components: {
    "vue-cal": VueCal
  },
  props: {
    pendingActions: Array,
    alert: Object,
    professionals: []
  },
  emits: ['close'],
  data() {
    return {
      categories: [],
      selectedCategory: null,
      actionTypes: [],
      selectedActionType: null,
      selectedDueDate: null,
      selectedProfessional: null,
      professionalsDropdownVisible: false,
      showActionTypesPopup: false,
      showCategoriesPopup: false,
      loading: false
    };
  },
  created() {
    this.selectedProfessional = this.currentProfessional();
    this.loadActionTypes();
  },
  methods: {

    currentProfessional(){
      return this.professionals.filter(professional => professional.Id == this.$Session.get(this.$userSessionName).professional.Id)[0];
    },
    
    loadActionTypes() {
      this.loading = true;
      this.$axios
        .get("api/action/types", {
          headers: {
            Authorization: `bearer ${this.$Session.get(this.$userSessionName).token.Value
              }`
          }
        })
        .then((res) => {
          res.data.actionTypes.forEach((actionType) =>
            this.actionTypes.push(actionType)
          );
          this.extractCategoriesFromTypes();
          this.showActionCreationOptions();
          this.loading = false;

        });
    },

    showActionCreationOptions() {
      if (this.categories?.length > 0) this.showCategoriesPopup = true;
      else this.showActionTypesPopup = true;
    },
    selectCategory(category) {
      this.selectedCategory = category;
      this.showActionTypesPopup = true;
    },
    selectAction(actionType) {
      this.selectedActionType = actionType;
    },
    selectDate(date) {
      this.selectedDueDate = date.format("YYYY-MM-DD");
    },
    toggleProfessionalsDropdown() {
      this.professionalsDropdownVisible = !this.professionalsDropdownVisible;
    },
    untoggleProfessionalsDropdownIfVisible() {
      if (this.professionalsDropdownVisible)
        this.professionalsDropdownVisible = false;
    },
    selectProfessional(professional) {
      this.selectedProfessional = professional;
      this.untoggleProfessionalsDropdownIfVisible();
    },

    mountNewActionJSON() {
      return {
        ActionTypeId: this.selectedActionType.Id,
        DueDate: this.selectedDueDate,
        ProfessionalAssignedId: this.selectedProfessional?.Id
      };

    },

    async addAction(actionType) {

      await this.$HTTPUtilities.post(
        this,
        `/api/action/patient/${this.$route.params.id}`,
        this.mountNewActionJSON(),
        (res) => {
          this.pendingActions.push({
            Id: res.data.Id,
            title: actionType.Name,
            type: "",
            color: this.chooseCategoryColor(actionType),
            dueDate: this.selectedDueDate,
            assignedProfessional: {
              Id: this.selectedProfessional?.Id,
              Name: this.selectedProfessional?.Name,
              Photo: this.selectedProfessional?.Photo
            },
            status: 0
          });
        },
        this.alert
      );

      this.cleanNewActionOptions();
    },

    cleanNewActionOptions() {
      this.showActionTypesPopup = false;
      this.showCategoriesPopup = false;
      this.selectedCategory = null;
      this.selectedActionType = null;
      this.closePopup();
    },

    filterActionTypesByCategories() {
      if (this.thereIsNoCategories()) return this.actionTypes;
      if (this.otherOptionWasSelected()) return this.allTypesWithoutACategory();
      return this.actionTypes.filter(
        (type) => type.Category?.Id == this.selectedCategory.Id
      );
    },
    thereIsNoCategories() {
      return !this.categories?.length > 0;
    },
    otherOptionWasSelected() {
      return this.selectedCategory?.Id == 0;
    },

    allTypesWithoutACategory() {
      return this.actionTypes.filter((type) => type.Category == null);
    },

    extractCategoriesFromTypes() {

      let hasTypesNotAssignedToAnyCategory = false;

      this.actionTypes.forEach((actionType) => {
        if (!actionType.Category) hasTypesNotAssignedToAnyCategory = true;
        else {
          const categoryAlreadyExists = this.categories.some(
            (category) => category.Id === actionType.Category.Id
          );
          if (!categoryAlreadyExists) this.categories.push(actionType.Category);
        }
      });

      if (this.categories?.length > 0 && hasTypesNotAssignedToAnyCategory)
        this.createOtherCategoryOption();
    },

    createOtherCategoryOption() {
      this.categories.push(Object.create({ Id: 0, Name: "Outros" }));
    },

    chooseCategoryColor(actionType) {
      const hue = (actionType.Id * 137.508) % 360;
      const saturation = 60 + ((actionType.Id * 29) % 20);
      const lightness = 70 + ((actionType.Id * 23) % 10);
      return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    },

    closePopup() {
      this.$emit("close");
    }
  }
};
</script>
  
  <style scoped>
.popupActions {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.popupActions-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-height: 80%;
  max-width: 80%;
  overflow-y: auto;
}

.popupActions-content h2,
.popupActions-content h3 {
  margin: 0 0 10px 0;
}

.popupActions-content ul {
  list-style-type: none;
  padding: 0;
}

.popupActions-content ul li:hover {
  text-decoration: underline;
}

.popupActions ul li {
  margin-bottom: 10px;
  cursor: pointer;
  color: #6a1b9a;
  font-weight: bold;
}

.popupActions-subtitle {
  font-size: 16px;
  margin-top: 1em;
}

.popupActions .professional-assigned-box {
  display: flex;
  align-items: center;
  padding: 4px;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 210px;
  margin-top: 15px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  position: relative;
}

.popupActions .professionals-assigned-dropdown-list {
  padding: 0;
  margin: 5px 0 0;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 210px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow-y: auto;
  position: relative;
  z-index: 10000;
}

.popupActions .dropdown-item {
  padding: 8px;
  cursor: pointer;
}

.popupActions .dropdown-item:hover {
  background-color: #f0f0f0;
}

#add-action-buttons {
  padding-top: 2%;
  display: flex;
  justify-content: space-between;
}

.popupActions-container-button {
  background-color: #6a1b9a;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.popupActions-container-button:disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
}

.action-type-selected {
  background-color: #4a148c;
  color: white !important;
  border-radius: 8px;
  padding: 8px;
}

.popupActions-content .professional-assigned-box p {
  font-size: 14px;
  padding-left: 5px;
}

@media (max-width: 1214px) {

  .popupActions .professional-assigned-box {
    width: 180px;
  }
}
</style>
  