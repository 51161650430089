// message-types.enum.ts
export enum AlertMessage {
    ERROR = 'Ocorreu um erro. Tente novamente.',
    SUCCESS = 'Sua ação foi concluída com sucesso.',
    EMPTY_LIST = 'Nenhum resultado foi encontrado.',
    DELETE = 'O registro foi deletado com sucesso.',
    SAVE = 'O registro foi inserido com sucesso.',
    EDIT = 'O registro foi editado com sucesso.',
    FIRST_PASSWORD_CREATED = 'Senha cadastrada com sucesso.',
    PASSWORD_CHANGED = 'Sua senha foi alterada com sucesso.',
    EXPIRED_TOKEN = 'Token incorreto ou expirado.',
    BASIC_INFORMATION = 'Dados básicos salvos com sucesso.',
    COMPLEMENTARY_INFORMATION = 'Dados complementares salvos com sucesso.',
    SESSION_EXPIRED = 'Sessão expirada. Realize o login novamente.',
    FORGOT_PASSWORD = 'Confira seu e-mail para encontrar instruções para recuperar sua senha.'
  }
  