<template>
  <button
    type="button"
    class="btn-export"
    :class="exporting ? 'loading export' : ''"
    @click="exportData"
  >
    <lila-svgIcon
      v-show="!exporting"
      :src="require('@assets/icons/export.svg')"
    />
    <span>Exportar Dados</span>
  </button>
</template>

<script>
import SvgIcon from "@components/shared/images/SvgIcon.vue";

export default {
  components: {
    "lila-svgIcon": SvgIcon
  },
  props: {
    apiUrl: {
      required: true,
      type: String,
      default: ""
    },
    alert: {
      required: true,
      type: Object,
      default: () => {
        return {
          message: "",
          type: "",
          show: false
        };
      }
    }
  },
  data(){
    return {
      exporting: false
    };
  },
  methods: {
    exportData(){
      this.exporting = true;

      this.$axios
        .get(
          this.apiUrl,
          {
            headers: {
              "Authorization": `bearer ${this.$Session.get(this.$userSessionName).token.Value}`,
              "Content-Type": "application/json"
            },
            responseType: "arraybuffer"
          }
        )
        .then((res) => this.fileDownload(res.data))
        .catch(error => {
          this.$Utilities.verifyToken(error.response.status);
          this.$Utilities.controlAlert(error.response.data, this.alert, "danger");
        })
        .finally(() => this.exporting = false);
    },
    fileDownload(fileData){
      let blob = new Blob([fileData], { type: "application/pdf" });
      let url = window.URL.createObjectURL(blob);

      window.open(url);
    }
  }
};
</script>

<style>
    @import '~@assets/css/components/exportButton.css';
</style>
