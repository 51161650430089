<template>
  <lila-default-template>
    <template #mainContent>
      <div :class="loadingContent ? 'loading large' : ''">
        <form
          v-show="!loadingContent"
          class="container basic-info-form"
          @submit.prevent="submitForm()"
        >
          <h1 class="title">
            Novo Paciente
          </h1>

          <lila-message
            v-if="alert.location == 'page'"
            :alert-data="alert"
          />

          <div class="form-content columns">
            <div class="column is-5">
              <div class="field-group">
                <div class="field-group-header align-title">
                  <p>Paciente</p>
                </div>

                <div class="field-group-body">
                  <lila-input
                    v-model="v.data.name.$model"
                    placeholder="Nome*"
                    :validation="v.data.name"
                  />

                  <lila-input
                    v-model="v.data.registerNumber.$model"
                    placeholder="Número de Matrícula"
                    :validation="v.data.registerNumber"
                  />

                  <lila-select
                    v-model="v.data.gender.$model"
                    placeholder="Sexo*"
                    :options="genderList"
                    :validation="v.data.gender"
                  />

                  <lila-mask
                    v-model="v.data.cpf.$model"
                    placeholder="CPF*"
                    mask="###.###.###-##"
                    :validation="v.data.cpf"
                  />

                  <lila-mask
                    v-model="v.data.cns.$model"
                    placeholder="CNS*"
                    mask="### #### #### ####"
                    :validation="v.data.cns"
                  />


                  <lila-mask
                    v-model="v.data.phone.$model"
                    placeholder="Telefone*"
                    :mask="['(##) ####-####', '(##) #####-####']"
                    :validation="v.data.phone"
                  />

                  <lila-input
                    v-model="v.data.email.$model"
                    placeholder="E-mail"
                    :validation="v.data.email"
                  />

                  <lila-select
                    v-model="v.data.attendancePlaceId.$model"
                    :placeholder="$Session.get($userSessionName).profile == 'professional_leader' ? 'Equipes*' : 'Local de Atendimento*'"
                    :options="medicalCareLocationList"
                    :validation="v.data.attendancePlaceId"
                  />
                </div>
              </div>
            </div>

            <div class="column is-2 middle-column">
              <div class="line" />
            </div>
          </div>

          <div class="sticky-submit">
            <lila-button
              id="save-patient"
              type="submit"
              title="Salvar"
              :loading="loading"
            />
          </div>
        </form>
      </div>
    </template>
  </lila-default-template>
</template>


<script>
import DefaultTemplate from "@components/template/DefaultTemplate.vue";

import Input from "@components/shared/fields/Input.vue";
import Select from "@components/shared/fields/Select.vue";
import Button from "@components/shared/button/Button.vue";
import Message from "@components/shared/message/Message.vue";
import LilaMask from "@components/shared/fields/LilaMask.vue";

import { required, email } from "@vuelidate/validators";

import GenderOptions from "@assets/options/gender.json";

const validCpf = function (value) {
  if (value) return this.$Utilities.validateCPF(value);

  return true;
};

const atLeastOne = (value, context) => {
  return !!context.cpf || !!context.cns; // Verifica se pelo menos um dos dois está preenchido
};

const verifyLastName = function (value) {
  let name = value.trim().replace(/\s\s+/g, " ").split(" ");

  if (typeof name[1] == "undefined" || (typeof name[1] != "undefined" && name[1].length < 1)) return false;

  return true;
};

export default {
  components: {
    "lila-input": Input,
    "lila-select": Select,
    "lila-button": Button,
    "lila-message": Message,
    "lila-default-template": DefaultTemplate,
    LilaMask
  },
  data() {
    return {
      data: {
        id: "",
        name: "",
        gender: "",
        cpf: "",
        cns: "",
        phone: "",
        email: "",
        registerNumber: "",
        attendancePlaceId: null
      },
      genderList: GenderOptions.options,
      medicalCareLocationList: this.$Session.get(this.$userSessionName).doctor.Addresses[0] ? this.$Session.get(this.$userSessionName).doctor.Addresses.map(item => {
        return {
          _id: item.Id,
          name: item.Street
        };
      }) : [],
      appCodeModal: {
        active: false,
        invalidPhone: false
      },
      loading: false,
      loadingContent: false,
      sendingCode: false,
      alert: {
        message: "",
        type: "",
        show: false,
        location: ""
      }
    };
  },
  validations() {
    return {
      data: {
        name: {
          required,
          verifyLastName
        },
        gender: { required },
        cpf: {
          validCpf
        },
        cns: {}, // Adiciona o CNS aqui sem required
        phone: {
          required
        },
        email: { email },
        registerNumber: {},
        attendancePlaceId: { required },
        cpfOrCns: { atLeastOne } // Validação para garantir que um dos dois seja preenchido
      }
    };
  },
  created() {
    if (this.$Session.get(this.$userSessionName).profile == "professional_leader") {
      this.getTeams();
    }
  },
  methods: {
    submitForm() {
      this.alert.show = false;
      this.alert.location = "page";

      this.v.$touch();

      if (this.v.$invalid) return;

      this.loading = true;

      let patient = Object.assign({}, this.data);

      // Remove CPF ou CNS se estiver vazio
      if (!patient.cpf)
        patient.cpf = '';
      if (!patient.cns)
        patient.cns = '';


      if (patient.email == "") delete patient.email;
      if (this.$Session.get(this.$userSessionName).profile == "professional_leader") {
        patient.TeamId = patient.attendancePlaceId;

        delete patient.attendancePlaceId;
      }

      this.$axios({
        method: "POST",
        url: "api/patient",
        data: patient,
        headers: { "Authorization": `bearer ${this.$Session.get(this.$userSessionName).token.Value}` }
      })
        .then(res => {
          this.data.id = res.data.Id;

          //this.openModal();
          // this.sendCode();
          this.sendCodeV2();
        })
        .catch((error) => {
          console.error("API Error:", error);  // Exibe o erro no console para depuração

          this.alert.type = 'warning';

          // Verifica se há resposta da API
          if (error.response && error.response.data) {
            // Extrai a mensagem de erro da API
            let apiMessage = error.response.data.Message || "Erro ao salvar paciente.";

            // Verifica se há um array de erros adicionais
            if (error.response.data.Errors && Array.isArray(error.response.data.Errors)) {
              const errorMessages = error.response.data.Errors.join(" ");
              this.alert.message = `${apiMessage} Detalhes: ${errorMessages}`;
            } else {
              // Caso contrário, usa apenas a mensagem da API
              this.alert.message = apiMessage;
            }

            // Exibe o alerta com a mensagem de erro
            this.$Utilities.controlAlert(this.alert.message, this.alert);
          } else if (error.message) {
            // Trata erros de rede ou outros erros sem resposta da API
            this.alert.message = error.message;
            this.$Utilities.controlAlert(this.alert.message, this.alert);
          } else {
            // Mensagem de erro genérica caso não haja outras informações
            this.alert.message = "Ocorreu um erro desconhecido.";
            this.$Utilities.controlAlert(this.alert.message, this.alert);
          }

          this.$Utilities.verifyToken(error.response ? error.response.status : null);
        })
        .finally(() => this.loading = false);
    },
    getTeams() {
      this.loadingContent = true;

      this.$axios({
        method: "GET",
        url: "api/team",
        headers: { "Authorization": `bearer ${this.$Session.get(this.$userSessionName).token.Value}` }
      })
        .then((res) => {
          this.medicalCareLocationList = res.data.map(item => {
            return {
              _id: item.Id,
              name: item.Name
            };
          });
        })
        .catch(error => this.$Utilities.controlAlert(error.response.data, this.alert, "danger"))
        .finally(() => this.loadingContent = false);
    },
    openModal() {
      this.appCodeModal.active = true;
    },
    sendCodeV2() {
      this.alert.show = false;
      this.alert.location = "modal";
      this.sendingCode = true;
      this.loading = true;

      this.$axios({
        method: "POST",
        url: `api/patient/token/${this.data.id}`,
        headers: { "Authorization": `bearer ${this.$Session.get(this.$userSessionName).token.Value}` }
      })
        .then(() => {
          // Redirecionar para a rota da ficha do paciente com o ID
          this.$router.push({
            path: `/pacientes/ficha/${this.data.id}`, // Navegar para o caminho completo do paciente
            query: {
              customMessageType: "success",
              customMessage: "O paciente foi inserido com sucesso e as instruções para o uso do aplicativo foram encaminhadas por SMS e e-mail. Se desejar, você pode cadastrar informações complementares."
            }
          });
        })
        .catch(error => {
          this.$Utilities.verifyToken(error.response.status);

          if (error.response.status == 400) {
            this.appCodeModal.invalidPhone = true;
            this.$router.push({
              path: `/pacientes/ficha/${this.data.id}`,
              query: {
                customMessageType: "warning",
                customMessage: `O paciente foi inserido com sucesso, mas não foi possível enviar o SMS. Verifique se o telefone do paciente está correto.`
              }
            });
          } else {
            this.$Utilities.controlAlert(error.response.data, this.alert, "danger");
            alert.type = "danger";
            alert.show = true;
            this.appCodeModal.invalidPhone = true;
            this.$router.push({
              path: `/pacientes/ficha/${this.data.id}`,
              query: {
                customMessageType: "warning",
                customMessage: `O paciente foi inserido com sucesso, mas não foi possível enviar o SMS. Verifique se o telefone do paciente está correto.`
              }
            });
          }
        })
        .finally(() => {
          this.sendingCode = false;
          this.loading = false;
        });
    }
  }
};
</script>

<style>
@import '~@assets/css/views/complementaryRegistration.css';
</style>
