// src/enums/PerformanceStatus.ts

export enum PerformanceStatus {
    PS0 = 0,
    PS1 = 1,
    PS2 = 2,
    PS3 = 3,
    PS4 = 4,
  }
  
  // Centralized performance status list
  export const performanceStatusList = [
    { _id: PerformanceStatus.PS0, name: "PS 0" },
    { _id: PerformanceStatus.PS1, name: "PS I" },
    { _id: PerformanceStatus.PS2, name: "PS II" },
    { _id: PerformanceStatus.PS3, name: "PS III" },
    { _id: PerformanceStatus.PS4, name: "PS IV" },
  ];
  