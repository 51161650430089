<template>
  <article
    v-show="alertData.show"
    class="message"
    :class="messageType"
  >
    <div
      class="message-body"
      @click="onClick"
    >
      {{ alertData.message }}
    </div>
  </article>
</template>

<script>
export default {
  props: {
    alertData: {
      required: true,
      type: Object
    }
  },
  computed: {
    messageType() {
      if (this.alertData.type == "danger") return "is-danger";
      if (this.alertData.type == "success") return "is-success";
      if (this.alertData.type == "warning") return "is-warning";

      return "";
    }
  },
  methods: {
    onClick() {
      console.log("");
    }
  }
};
</script>

<style></style>