<template>
  <div
    v-if="type == 'route'"
    class="field button-component"
  >        
    <div 
      class="control" 
      :class="[buttonAlignment, buttonIcon ? 'has-button-icon' : '']"
    >
      <lila-svg-icon 
        v-if="buttonIcon" 
        class="button-icon" 
        :src="require(`@assets/icons/${buttonIcon}`)"
      />

      <router-link 
        :to="{name: path}"
        class="button is-rounded"
        :class="style"
      >
        {{ title }}
      </router-link>

      <button 
        v-if="secondButton.type == 'button'"
        type="button"
        class="button is-rounded cancel" 
        :class="secondButton.class"
        :disabled="loading"
        @click="clickCancelEvent()"
      >
        {{ secondButton.title }}
      </button>
            
      <router-link 
        v-if="secondButton.type == 'route'" 
        :to="{name: secondButton.route}" 
        class="button is-rounded cancel"
        :class="secondButton.class"
      >
        {{ secondButton.title }}
      </router-link>

      <router-link 
        v-if="secondButton.type == 'routeText'"
        :to="{name: secondButton.route}"
        class="text-button"
        :class="secondButton.class"
      >
        {{ secondButton.title }}
      </router-link>
    </div>
  </div>

  <div
    v-else-if="type == 'routeText'"
    class="field button-component"
  >        
    <div 
      class="control" 
      :class="[buttonAlignment, buttonIcon ? 'has-button-icon' : '']"
    >
      <lila-svg-icon 
        v-if="buttonIcon" 
        class="button-icon" 
        :src="require(`@assets/icons/${buttonIcon}`)"
      />

      <router-link 
        :to="{name: path}"
        class="text-button"
      >
        {{ title }}
      </router-link>
    </div>
  </div>

  <div
    v-else
    class="field button-component"
  >
    <div 
      class="control" 
      :class="buttonAlignment"
    >
      <button        
        v-if="type == 'button' || type == 'submit'"   
        v-show="mainShow"       
        :type="type" 
        class="button is-rounded"
        :class="style" 
        :disabled="isDisabled"
        @click="clickEvent()"
      >
        {{ title }}
      </button>

      <button 
        v-else-if="type == 'textButton'"
        class="text-button" 
        :disabled="isDisabled"
        @click="clickEvent()"
      >
        {{ title }}
      </button>

      <button 
        v-if="secondButton.type == 'button'"
        type="button"
        class="button is-rounded cancel" 
        :class="secondButton.class"
        :disabled="loading"
        @click="clickCancelEvent()"
      >
        {{ secondButton.title }}
      </button>

      <button 
        v-if="secondButton.type == 'textButton'"
        class="text-button" 
        :class="secondButton.class"
        :disabled="loading"
        @click="clickCancelEvent()"
      >
        {{ secondButton.title }}
      </button>
            
      <router-link 
        v-if="secondButton.type == 'route'" 
        :to="{name: secondButton.route}" 
        class="button is-rounded cancel"
        :class="secondButton.class"
      >
        {{ secondButton.title }}
      </router-link>

      <router-link 
        v-if="secondButton.type == 'routeText'"
        :to="{name: secondButton.route}"
        class="text-button"
        :class="secondButton.class"
      >
        {{ secondButton.title }}
      </router-link>

      <button 
        v-if="secondButton.type == 'externalLink'" 
        type="button"
        class="text-button" 
        :class="secondButton.class"
        @click="openExternalLink(secondButton.route)"
      >
        {{ secondButton.title }}
      </button>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@components/shared/images/SvgIcon.vue";

export default {
  components: {
    "lila-svg-icon": SvgIcon
  },
  props: {
    type: {
      required: true,
      type: String
    },
    title: {
      required: true,
      type: String
    },
    path: {
      required: false,
      default: ''
    },
    buttonStyle: {
      required: false,
      default: "default",
      type: String
    },
    buttonIcon: {
      required: false,
      type: String
    },
    isCentralized: {
      required: false,
      type: Boolean,
      default: false
    },
    alignment: {
      required: false,
      type: String,
      default: ""
    },
    loading: {
      required: false,
      default: false,
      type: Boolean
    },
    isDisabled: {
      required: false,
      default: false,
      type: Boolean
    },
    secondButton: {
      required: false,
      type: Object,
      default: () => {
        return {
          type: "",
          route: "",
          title: "",
          class: ""
        };
      }
    },
    mainShow: {
      required: false,
      default: true,
      type: Boolean
    }
  },
  emits: ['customClickEvent', 'customClickCancelEvent'],
  computed: {
    style() {
      let buttonStyle = "";

      switch (this.buttonStyle) {
      case "default":
        buttonStyle = `button ${this.buttonStyle}`;
        break;
                    
      case "add-new":
        buttonStyle = `button is-rounded ${this.buttonStyle}`;
        break;
                
      default:
        buttonStyle = this.buttonStyle;
        break;
      }

      return this.loading ? `${buttonStyle} is-loading` : buttonStyle;
    },
    buttonAlignment(){
      let alignmentClass = "";

      if(this.isCentralized) return "has-text-centered";

      switch (this.alignment) {
      case "left":
        alignmentClass = "has-text-left";
        break;

      case "right":
        alignmentClass = "has-text-right";
        break;

      case "centered":
        alignmentClass = "has-text-centered";
        break;

      case "justified":
        alignmentClass = "has-text-justified";
        break;
      }

      return alignmentClass;
    }
  },
  methods: {
    clickEvent(){
      this.$emit("customClickEvent");
    },
    clickCancelEvent(){
      this.$emit("customClickCancelEvent");
    },
    openExternalLink(route) {
      window.open(route, "_blank");
    }
  }
};
</script>

<style scoped>
    @import '~@assets/css/components/button.css';
</style>
