import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, TransitionGroup as _TransitionGroup, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "box-export-medical-record"
}
const _hoisted_2 = { class: "field-group" }
const _hoisted_3 = {
  key: 0,
  class: "field-group"
}
const _hoisted_4 = { class: "field-group-body" }
const _hoisted_5 = { class: "field-body columns is-align-items-center" }
const _hoisted_6 = { class: "column is-narrow action-icon-container" }
const _hoisted_7 = { class: "tooltip-icon" }
const _hoisted_8 = {
  key: 0,
  class: "field-group-header align-title"
}
const _hoisted_9 = { class: "field-group-body" }
const _hoisted_10 = { class: "field-body columns" }
const _hoisted_11 = { class: "field-group-body" }
const _hoisted_12 = { class: "field-body columns" }
const _hoisted_13 = { class: "column is-narrow is-6" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { class: "field-body columns is-multiline" }
const _hoisted_16 = { class: "column is-narrow is-6" }
const _hoisted_17 = { class: "field-group" }
const _hoisted_18 = { class: "field-group-body" }
const _hoisted_19 = { class: "field-group-body" }
const _hoisted_20 = { class: "field-group-body" }
const _hoisted_21 = { class: "field-group-body" }
const _hoisted_22 = { class: "field-group-body" }
const _hoisted_23 = { class: "field-group-body" }
const _hoisted_24 = { class: "field-group-body symptoms-start-date-group is-fullwidth" }
const _hoisted_25 = { class: "symptoms-start-date-row columns is-mobile" }
const _hoisted_26 = { class: "symptoms-start-date-input column" }
const _hoisted_27 = {
  key: 0,
  class: "field-group-body field-group-add-cid"
}
const _hoisted_28 = {
  key: 1,
  class: "field-group-body"
}
const _hoisted_29 = { class: "table is-fullwidth table-cid" }
const _hoisted_30 = { key: 0 }
const _hoisted_31 = { key: 1 }
const _hoisted_32 = { key: 2 }
const _hoisted_33 = { key: 3 }
const _hoisted_34 = { key: 4 }
const _hoisted_35 = { key: 5 }
const _hoisted_36 = {
  key: 6,
  class: "has-text-centered"
}
const _hoisted_37 = { key: 0 }
const _hoisted_38 = { key: 0 }
const _hoisted_39 = { key: 0 }
const _hoisted_40 = {
  key: 0,
  class: "has-text-centered table-cid-delete"
}
const _hoisted_41 = { class: "field-group-body" }
const _hoisted_42 = { class: "field-group-body" }
const _hoisted_43 = { class: "modal-card" }
const _hoisted_44 = { class: "modal-card-foot" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lila_message = _resolveComponent("lila-message")!
  const _component_lila_export_button = _resolveComponent("lila-export-button")!
  const _component_lila_button = _resolveComponent("lila-button")!
  const _component_lila_svg_icon = _resolveComponent("lila-svg-icon")!
  const _component_lila_datepicker = _resolveComponent("lila-datepicker")!
  const _component_lila_input = _resolveComponent("lila-input")!
  const _component_lila_select = _resolveComponent("lila-select")!
  const _component_lila_textarea = _resolveComponent("lila-textarea")!
  const _component_lila_mask = _resolveComponent("lila-mask")!
  const _component_lila_cid_modal = _resolveComponent("lila-cid-modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("form", {
      class: _normalizeClass(["container basic-info-form medical-card-form", _ctx.loadingContent ? 'loading large' : '']),
      onSubmit: _cache[41] || (_cache[41] = _withModifiers(($event: any) => (_ctx.submitForm()), ["prevent"]))
    }, [
      _createVNode(_component_lila_message, { "alert-data": _ctx.alert }, null, 8, ["alert-data"]),
      _withDirectives(_createElementVNode("div", null, [
        (!_ctx.$AccessControl.cannot(
            _ctx.$Session.get(_ctx.$userSessionName).profile,
            'export_patient_data'
          ))
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_lila_export_button, {
                "api-url": `/api/patient/export/${_ctx.data.Id}`,
                alert: _ctx.alert
              }, null, 8, ["api-url", "alert"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_2, [
          (_ctx.utilities.hasAccessToFeature(_ctx.FeatureEnum.Automacoes))
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _createVNode(_component_lila_button, {
                        type: "button",
                        title: _ctx.data.HasNavAdmStarted ? `Navegação administrativa iniciada ${_ctx.formattedNavigationStartDate}` : 'Iniciar navegação administrativa',
                        class: "button-automated-actions",
                        loading: _ctx.endpointLoading,
                        "is-disabled": _ctx.endpointLoading || _ctx.data.HasNavAdmStarted,
                        onCustomClickEvent: _ctx.callEndpoint
                      }, null, 8, ["title", "loading", "is-disabled", "onCustomClickEvent"]),
                      _createElementVNode("div", _hoisted_7, [
                        _createVNode(_component_lila_svg_icon, {
                          src: require('@assets/icons/info.svg'),
                          class: "is-cursor-pointer"
                        }, null, 8, ["src"]),
                        _cache[44] || (_cache[44] = _createElementVNode("span", { class: "tooltip-text" }, " Este botão cria ações automatizadas para o paciente com base nas configurações atuais. ", -1))
                      ])
                    ])
                  ]),
                  (!_ctx.data.HasNavAdmStarted)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        _cache[45] || (_cache[45] = _createElementVNode("p", { class: "field-group-label-type" }, " Data de Início da Navegação ", -1)),
                        _createVNode(_component_lila_datepicker, {
                          modelValue: _ctx.data.NavigationStartDate,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data.NavigationStartDate) = $event)),
                          placeholder: _ctx.data.HasNavAdmStarted == true ? _ctx.data.NavigationStartDate : 'Selecione a data de início da navegação',
                          disabled: _ctx.data.HasNavAdmStarted,
                          class: "datepicker-small"
                        }, null, 8, ["modelValue", "placeholder", "disabled"])
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]))
            : _createCommentVNode("", true),
          _cache[60] || (_cache[60] = _createElementVNode("div", { class: "field-group-header align-title" }, [
            _createElementVNode("p", { class: "field-group-label-type" }, " Estado de acesso ao sistema ")
          ], -1)),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              (_ctx.data.Status === 'Pending')
                ? (_openBlock(), _createBlock(_component_lila_input, {
                    key: 0,
                    class: "column is-narrow is-6",
                    "model-value": "Não fez primeiro acesso",
                    disabled: true
                  }))
                : (_openBlock(), _createBlock(_component_lila_select, {
                    key: 1,
                    modelValue: _ctx.data.Status,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.data.Status) = $event)),
                    class: "column is-narrow is-6",
                    options: _ctx.statusList,
                    readonly: !_ctx.checkInstalledApp || _ctx.viewOnly
                  }, null, 8, ["modelValue", "options", "readonly"]))
            ])
          ]),
          _cache[61] || (_cache[61] = _createElementVNode("div", { class: "division-line" }, null, -1)),
          _cache[62] || (_cache[62] = _createElementVNode("div", { class: "field-group-header align-title" }, [
            _createElementVNode("p", { class: "field-group-label-type" }, " Fase da jornada ")
          ], -1)),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                _createVNode(_component_lila_select, {
                  modelValue: _ctx.data.selectedJourneyStage,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.data.selectedJourneyStage) = $event)),
                  options: _ctx.journeyStageList,
                  onCustomChangeEvent: _ctx.clearLifeStatus
                }, null, 8, ["modelValue", "options", "onCustomChangeEvent"])
              ])
            ]),
            (Number(_ctx.data.selectedJourneyStage) ===
                _ctx.PatientJourneyOptions.Falecido
              )
              ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                  _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("div", _hoisted_16, [
                      _createVNode(_component_lila_datepicker, {
                        modelValue: _ctx.v.data.DeathDate.$model,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.v.data.DeathDate.$model) = $event)),
                        "disable-future-dates": _ctx.$moment().format('MM/DD/YYYY'),
                        placeholder: "Data de falecimento",
                        validation: _ctx.v.data.DeathDate
                      }, null, 8, ["modelValue", "disable-future-dates", "validation"])
                    ])
                  ]),
                  _createVNode(_component_lila_textarea, {
                    modelValue: _ctx.v.data.DeathNotes.$model,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.v.data.DeathNotes.$model) = $event)),
                    placeholder: "Notas",
                    validation: _ctx.v.data.DeathNotes
                  }, null, 8, ["modelValue", "validation"])
                ]))
              : _createCommentVNode("", true)
          ]),
          _cache[63] || (_cache[63] = _createElementVNode("div", { class: "division-line" }, null, -1)),
          _createElementVNode("div", _hoisted_17, [
            _cache[49] || (_cache[49] = _createElementVNode("div", { class: "field-group-header align-title" }, [
              _createElementVNode("p", { class: "field-group-label-type" }, " Paciente ")
            ], -1)),
            _createElementVNode("div", _hoisted_18, [
              _createVNode(_component_lila_input, {
                modelValue: _ctx.v.data.Name.$model,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.v.data.Name.$model) = $event)),
                placeholder: "Nome",
                validation: _ctx.v.data.Name
              }, null, 8, ["modelValue", "validation"]),
              _createVNode(_component_lila_input, {
                modelValue: _ctx.data.SocialName,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.data.SocialName) = $event)),
                placeholder: "Nome Social",
                validation: _ctx.v.data.SocialName
              }, null, 8, ["modelValue", "validation"]),
              _createVNode(_component_lila_input, {
                modelValue: _ctx.v.data.RegisterNumber.$model,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.v.data.RegisterNumber.$model) = $event)),
                placeholder: "Número de Matrícula",
                validation: _ctx.v.data.RegisterNumber
              }, null, 8, ["modelValue", "validation"]),
              _createVNode(_component_lila_select, {
                modelValue: _ctx.v.data.Gender.$model,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.v.data.Gender.$model) = $event)),
                placeholder: "Sexo",
                options: _ctx.genderList,
                validation: _ctx.v.data.Gender
              }, null, 8, ["modelValue", "options", "validation"]),
              _createVNode(_component_lila_input, {
                modelValue: _ctx.v.data.Email.$model,
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.v.data.Email.$model) = $event)),
                placeholder: "E-mail",
                validation: _ctx.v.data.Email
              }, null, 8, ["modelValue", "validation"])
            ]),
            _cache[50] || (_cache[50] = _createElementVNode("div", { class: "field-group-header align-title" }, [
              _createElementVNode("p", { class: "field-group-label-type" }, " Dados do Paciente ")
            ], -1)),
            _createElementVNode("div", _hoisted_19, [
              _createVNode(_component_lila_mask, {
                modelValue: _ctx.v.data.CPF.$model,
                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.v.data.CPF.$model) = $event)),
                placeholder: "CPF",
                mask: "###.###.###-##",
                validation: _ctx.v.data.CPF,
                readonly: _ctx.viewOnly
              }, null, 8, ["modelValue", "validation", "readonly"]),
              _createVNode(_component_lila_input, {
                modelValue: _ctx.v.data.CNS.$model,
                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.v.data.CNS.$model) = $event)),
                placeholder: "CNS",
                validation: _ctx.v.data.CNS
              }, null, 8, ["modelValue", "validation"]),
              _createVNode(_component_lila_mask, {
                modelValue: _ctx.v.data.Phone.$model,
                "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.v.data.Phone.$model) = $event)),
                placeholder: "Telefone",
                mask: ['(##) ####-####', '(##) #####-####'],
                validation: _ctx.v.data.Phone,
                readonly: _ctx.viewOnly
              }, null, 8, ["modelValue", "validation", "readonly"]),
              _createVNode(_component_lila_datepicker, {
                modelValue: _ctx.v.data.BirthDate.$model,
                "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.v.data.BirthDate.$model) = $event)),
                placeholder: "Data de Nascimento",
                validation: _ctx.v.data.BirthDate,
                readonly: _ctx.viewOnly
              }, null, 8, ["modelValue", "validation", "readonly"])
            ]),
            _cache[51] || (_cache[51] = _createElementVNode("div", { class: "field-group-header align-title" }, [
              _createElementVNode("p", { class: "field-group-label-type" }, " Atributos do Paciente ")
            ], -1)),
            _createElementVNode("div", _hoisted_20, [
              _createVNode(_component_lila_select, {
                modelValue: _ctx.data.selectedRace,
                "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.data.selectedRace) = $event)),
                placeholder: "Raça",
                options: _ctx.raceList
              }, null, 8, ["modelValue", "options"]),
              _createVNode(_component_lila_select, {
                modelValue: _ctx.data.selectedEducation,
                "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.data.selectedEducation) = $event)),
                placeholder: "Educação",
                options: _ctx.educationList
              }, null, 8, ["modelValue", "options"]),
              _createVNode(_component_lila_select, {
                modelValue: _ctx.data.selectedEmployment,
                "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.data.selectedEmployment) = $event)),
                placeholder: "Profissão",
                options: _ctx.employmentList
              }, null, 8, ["modelValue", "options"]),
              _createVNode(_component_lila_select, {
                modelValue: _ctx.data.selectedMaritalStatus,
                "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.data.selectedMaritalStatus) = $event)),
                placeholder: "Estado Civil",
                options: _ctx.maritalStatusList
              }, null, 8, ["modelValue", "options"])
            ]),
            _cache[52] || (_cache[52] = _createElementVNode("div", { class: "field-group-header align-title" }, [
              _createElementVNode("p", { class: "field-group-label-type" }, " Acompanhante ")
            ], -1)),
            _createElementVNode("div", _hoisted_21, [
              _createVNode(_component_lila_select, {
                modelValue: _ctx.data.selectedCompanionType,
                "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.data.selectedCompanionType) = $event)),
                placeholder: "Tipo de Acompanhante",
                options: _ctx.companionTypeList
              }, null, 8, ["modelValue", "options"]),
              _createVNode(_component_lila_input, {
                modelValue: _ctx.data.CompanionName,
                "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.data.CompanionName) = $event)),
                placeholder: "Nome do Acompanhante",
                readonly: _ctx.viewOnly
              }, null, 8, ["modelValue", "readonly"]),
              _createVNode(_component_lila_mask, {
                modelValue: _ctx.data.CompanionPhone,
                "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.data.CompanionPhone) = $event)),
                placeholder: "Telefone do Acompanhante",
                mask: ['(##) ####-####', '(##) #####-####'],
                readonly: _ctx.viewOnly
              }, null, 8, ["modelValue", "readonly"])
            ]),
            _cache[53] || (_cache[53] = _createElementVNode("div", { class: "field-group-header align-title" }, [
              _createElementVNode("p", { class: "field-group-label-type" }, " Contato Parente, Amigo(a)...? ")
            ], -1)),
            _createElementVNode("div", _hoisted_22, [
              _createVNode(_component_lila_input, {
                modelValue: _ctx.data.RelativeName,
                "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.data.RelativeName) = $event)),
                placeholder: "Nome do Parente",
                readonly: _ctx.viewOnly
              }, null, 8, ["modelValue", "readonly"]),
              _createVNode(_component_lila_mask, {
                modelValue: _ctx.data.RelativePhone,
                "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.data.RelativePhone) = $event)),
                placeholder: "Telefone",
                mask: ['(##) ####-####', '(##) #####-####'],
                readonly: _ctx.viewOnly
              }, null, 8, ["modelValue", "readonly"])
            ]),
            _cache[54] || (_cache[54] = _createElementVNode("div", { class: "division-line" }, null, -1)),
            _cache[55] || (_cache[55] = _createElementVNode("div", { class: "field-group-header align-title" }, [
              _createElementVNode("p", { class: "field-group-label-type" }, " Endereço ")
            ], -1)),
            _createElementVNode("div", _hoisted_23, [
              _createVNode(_component_lila_mask, {
                modelValue: _ctx.data.CEP,
                "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.data.CEP) = $event)),
                placeholder: "CEP",
                mask: "#####-###",
                loading: _ctx.loadingCep,
                readonly: _ctx.viewOnly,
                onKeyup: _cache[24] || (_cache[24] = ($event: any) => (_ctx.changeCep()))
              }, null, 8, ["modelValue", "loading", "readonly"]),
              _createVNode(_component_lila_input, {
                modelValue: _ctx.data.Street,
                "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.data.Street) = $event)),
                placeholder: "Endereço",
                readonly: _ctx.viewOnly
              }, null, 8, ["modelValue", "readonly"]),
              _createVNode(_component_lila_input, {
                modelValue: _ctx.data.Number,
                "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.data.Number) = $event)),
                placeholder: "Número",
                readonly: _ctx.viewOnly
              }, null, 8, ["modelValue", "readonly"]),
              _createVNode(_component_lila_input, {
                modelValue: _ctx.data.Complement,
                "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.data.Complement) = $event)),
                placeholder: "Complemento",
                readonly: _ctx.viewOnly
              }, null, 8, ["modelValue", "readonly"]),
              _createVNode(_component_lila_input, {
                modelValue: _ctx.data.Neighborhood,
                "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.data.Neighborhood) = $event)),
                placeholder: "Bairro",
                readonly: _ctx.viewOnly
              }, null, 8, ["modelValue", "readonly"]),
              _createVNode(_component_lila_input, {
                modelValue: _ctx.data.City,
                "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.data.City) = $event)),
                placeholder: "Cidade",
                readonly: _ctx.viewOnly
              }, null, 8, ["modelValue", "readonly"]),
              _createVNode(_component_lila_select, {
                modelValue: _ctx.data.UF,
                "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.data.UF) = $event)),
                placeholder: "Estado",
                options: _ctx.statesList,
                readonly: _ctx.viewOnly
              }, null, 8, ["modelValue", "options", "readonly"])
            ]),
            _cache[56] || (_cache[56] = _createElementVNode("div", { class: "division-line" }, null, -1)),
            _cache[57] || (_cache[57] = _createElementVNode("div", { class: "field-group-header align-title" }, [
              _createElementVNode("p", { class: "field-group-label-type" }, " Diagnóstico ")
            ], -1)),
            _createElementVNode("div", _hoisted_24, [
              _createElementVNode("div", _hoisted_25, [
                _cache[46] || (_cache[46] = _createElementVNode("label", { class: "symptoms-start-date-label column is-narrow" }, "Início dos sintomas:", -1)),
                _createElementVNode("div", _hoisted_26, [
                  _createVNode(_component_lila_select, {
                    modelValue: _ctx.data.selectedSymptomStartPeriod,
                    "onUpdate:modelValue": _cache[31] || (_cache[31] = ($event: any) => ((_ctx.data.selectedSymptomStartPeriod) = $event)),
                    class: "input-data-inicio-sintomas",
                    placeholder: "Período de início dos sintomas",
                    options: _ctx.symptomStartPeriodList
                  }, null, 8, ["modelValue", "options"])
                ])
              ])
            ]),
            (!_ctx.viewOnly)
              ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                  _createVNode(_component_lila_input, {
                    modelValue: _ctx.v.newCid.CID.CIDCode.$model,
                    "onUpdate:modelValue": _cache[32] || (_cache[32] = ($event: any) => ((_ctx.v.newCid.CID.CIDCode.$model) = $event)),
                    placeholder: "Selecionar CID",
                    class: "is-cursor-pointer input-cid",
                    validation: _ctx.v.newCid.CID.CIDCode,
                    onCustomClickEvent: _ctx.openCidModal
                  }, null, 8, ["modelValue", "validation", "onCustomClickEvent"]),
                  (_ctx.isCancerRelated)
                    ? (_openBlock(), _createBlock(_component_lila_input, {
                        key: 0,
                        modelValue: _ctx.v.newCid.Morphology.$model,
                        "onUpdate:modelValue": _cache[33] || (_cache[33] = ($event: any) => ((_ctx.v.newCid.Morphology.$model) = $event)),
                        placeholder: "Morfologia",
                        class: "input-morphology",
                        validation: _ctx.v.newCid.Morphology
                      }, null, 8, ["modelValue", "validation"]))
                    : _createCommentVNode("", true),
                  (_ctx.isCancerRelated)
                    ? (_openBlock(), _createBlock(_component_lila_select, {
                        key: 1,
                        modelValue: _ctx.v.newCid.Estadio.$model,
                        "onUpdate:modelValue": _cache[34] || (_cache[34] = ($event: any) => ((_ctx.v.newCid.Estadio.$model) = $event)),
                        placeholder: "Estadio",
                        class: "input-estadio",
                        options: _ctx.estadioList,
                        validation: _ctx.v.newCid.Estadio
                      }, null, 8, ["modelValue", "options", "validation"]))
                    : _createCommentVNode("", true),
                  (_ctx.isCancerRelated)
                    ? (_openBlock(), _createBlock(_component_lila_datepicker, {
                        key: 2,
                        modelValue: _ctx.v.newCid.BiopsyDate.$model,
                        "onUpdate:modelValue": _cache[35] || (_cache[35] = ($event: any) => ((_ctx.v.newCid.BiopsyDate.$model) = $event)),
                        placeholder: "Data da biópsia",
                        class: "input-data-biopsia",
                        validation: _ctx.v.newCid.BiopsyDate
                      }, null, 8, ["modelValue", "validation"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_lila_button, {
                    type: "button",
                    title: "Adicionar",
                    class: "button-add-cid",
                    loading: _ctx.cidLoading,
                    disabled: _ctx.cidLoading,
                    onCustomClickEvent: _ctx.addCid2
                  }, null, 8, ["loading", "disabled", "onCustomClickEvent"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.data.CIDs && _ctx.data.CIDs.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                  _createElementVNode("table", _hoisted_29, [
                    _createElementVNode("thead", null, [
                      _createElementVNode("tr", null, [
                        _cache[47] || (_cache[47] = _createElementVNode("th", null, "CID", -1)),
                        (_ctx.data.CIDs.some((cidItem) => cidItem.Morphology))
                          ? (_openBlock(), _createElementBlock("th", _hoisted_30, " Morfologia "))
                          : (_openBlock(), _createElementBlock("th", _hoisted_31)),
                        (_ctx.data.CIDs.some((cidItem) => cidItem.Estadio))
                          ? (_openBlock(), _createElementBlock("th", _hoisted_32, " Estadio "))
                          : (_openBlock(), _createElementBlock("th", _hoisted_33)),
                        (_ctx.data.CIDs.some((cidItem) => cidItem.BiopsyDate))
                          ? (_openBlock(), _createElementBlock("th", _hoisted_34, " Data da Biópsia "))
                          : (_openBlock(), _createElementBlock("th", _hoisted_35)),
                        (!_ctx.viewOnly)
                          ? (_openBlock(), _createElementBlock("th", _hoisted_36, " Excluir "))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _createVNode(_TransitionGroup, {
                      tag: "tbody",
                      name: "fade",
                      "enter-active-class": "animated fadeIn",
                      "leave-active-class": "animated fadeOut"
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.CIDs, (cidItem, index) => {
                          return (_openBlock(), _createElementBlock("tr", {
                            key: cidItem.Id,
                            class: _normalizeClass(cidItem.Id ? 'no-animation' : '')
                          }, [
                            _createElementVNode("td", null, [
                              _createElementVNode("span", null, _toDisplayString(cidItem.CID.CIDCode), 1),
                              _cache[48] || (_cache[48] = _createTextVNode(" - ")),
                              _createElementVNode("span", null, _toDisplayString(cidItem.CID.Description), 1)
                            ]),
                            _createElementVNode("td", null, [
                              (cidItem.Morphology)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_37, _toDisplayString(cidItem.Morphology), 1))
                                : _createCommentVNode("", true)
                            ]),
                            _createElementVNode("td", null, [
                              (cidItem.Estadio)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_38, "Estadio " + _toDisplayString(cidItem.Estadio), 1))
                                : _createCommentVNode("", true)
                            ]),
                            _createElementVNode("td", null, [
                              (cidItem.BiopsyDate)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_39, _toDisplayString(_ctx.$moment(cidItem.BiopsyDate).format("DD/MM/YYYY")), 1))
                                : _createCommentVNode("", true)
                            ]),
                            (!_ctx.viewOnly)
                              ? (_openBlock(), _createElementBlock("td", _hoisted_40, [
                                  _createVNode(_component_lila_svg_icon, {
                                    src: require('@assets/icons/delete.svg'),
                                    class: "is-cursor-pointer",
                                    onCustomClickEvent: ($event: any) => (_ctx.openDeleteModal(cidItem.Id, index))
                                  }, null, 8, ["src", "onCustomClickEvent"])
                                ]))
                              : _createCommentVNode("", true)
                          ], 2))
                        }), 128))
                      ]),
                      _: 1
                    })
                  ])
                ]))
              : _createCommentVNode("", true),
            _cache[58] || (_cache[58] = _createElementVNode("div", { class: "division-line" }, null, -1)),
            _createElementVNode("div", _hoisted_41, [
              _createVNode(_component_lila_select, {
                modelValue: _ctx.data.PS,
                "onUpdate:modelValue": _cache[36] || (_cache[36] = ($event: any) => ((_ctx.data.PS) = $event)),
                label: `Performance Status${_ctx.data.HasWeeklyReport ? ' informado pelo paciente' : ''}`,
                options: _ctx.performanceStatusList,
                readonly: true
              }, null, 8, ["modelValue", "label", "options"])
            ]),
            _cache[59] || (_cache[59] = _createElementVNode("div", { class: "division-line" }, null, -1)),
            _createElementVNode("div", _hoisted_42, [
              _createVNode(_component_lila_textarea, {
                modelValue: _ctx.data.Allergy,
                "onUpdate:modelValue": _cache[37] || (_cache[37] = ($event: any) => ((_ctx.data.Allergy) = $event)),
                label: "Alergia Medicamentosa",
                placeholder: "Digite aqui...",
                readonly: _ctx.viewOnly
              }, null, 8, ["modelValue", "readonly"]),
              _createVNode(_component_lila_textarea, {
                modelValue: _ctx.data.Comorbidity,
                "onUpdate:modelValue": _cache[38] || (_cache[38] = ($event: any) => ((_ctx.data.Comorbidity) = $event)),
                label: "Comorbidades",
                placeholder: "Digite aqui...",
                readonly: _ctx.viewOnly
              }, null, 8, ["modelValue", "readonly"]),
              _withDirectives(_createVNode(_component_lila_textarea, {
                modelValue: _ctx.data.Diagnostic,
                "onUpdate:modelValue": _cache[39] || (_cache[39] = ($event: any) => ((_ctx.data.Diagnostic) = $event)),
                label: "Diagnóstico Oncológico",
                placeholder: "Digite aqui...",
                readonly: _ctx.viewOnly
              }, null, 8, ["modelValue", "readonly"]), [
                [_vShow, _ctx.utilities.hasAccessToFeature(_ctx.FeatureEnum.CampoDiagnostico)]
              ])
            ])
          ]),
          _cache[64] || (_cache[64] = _createElementVNode("div", { class: "division-line" }, null, -1)),
          _createVNode(_component_lila_textarea, {
            modelValue: _ctx.v.data.Observations.$model,
            "onUpdate:modelValue": _cache[40] || (_cache[40] = ($event: any) => ((_ctx.v.data.Observations.$model) = $event)),
            label: "Observações",
            placeholder: "Digite aqui...",
            readonly: _ctx.viewOnly,
            validation: _ctx.v.data.Observations
          }, null, 8, ["modelValue", "readonly", "validation"]),
          (!_ctx.viewOnly)
            ? (_openBlock(), _createBlock(_component_lila_button, {
                key: 1,
                type: "submit",
                title: "Salvar",
                class: "submit-buttons",
                loading: _ctx.loading
              }, null, 8, ["loading"]))
            : _createCommentVNode("", true)
        ])
      ], 512), [
        [_vShow, !_ctx.loadingContent]
      ])
    ], 34),
    _createVNode(_component_lila_cid_modal, {
      "modal-active": _ctx.cidModalActive,
      onCustomClickEvent: _ctx.selectCid
    }, null, 8, ["modal-active", "onCustomClickEvent"]),
    _createElementVNode("div", {
      class: _normalizeClass(["modal default-modal", _ctx.deleteModal.active ? 'is-active' : ''])
    }, [
      _cache[67] || (_cache[67] = _createElementVNode("div", { class: "modal-background" }, null, -1)),
      _createElementVNode("div", _hoisted_43, [
        _cache[65] || (_cache[65] = _createElementVNode("header", { class: "modal-card-head" }, [
          _createElementVNode("p", { class: "modal-card-title" }, " Excluir ")
        ], -1)),
        _cache[66] || (_cache[66] = _createElementVNode("section", { class: "modal-card-body" }, [
          _createElementVNode("p", null, "Deseja excluir esse registro?")
        ], -1)),
        _createElementVNode("footer", _hoisted_44, [
          _createVNode(_component_lila_button, {
            type: "submit",
            title: "Excluir",
            class: "submit-buttons",
            "second-button": {
              type: 'button',
              title: 'Cancelar',
              class: 'dark-lilas',
            },
            loading: _ctx.deleteLoading,
            onCustomClickEvent: _cache[42] || (_cache[42] = ($event: any) => (_ctx.controllDeleteCid())),
            onCustomClickCancelEvent: _cache[43] || (_cache[43] = ($event: any) => (_ctx.closeDeleteModal()))
          }, null, 8, ["loading"])
        ])
      ])
    ], 2)
  ]))
}