<template>
  <div
    class="textarea-component field"
    :class="validation.$error ? 'error' : ''"
  >
    <label
      v-if="label"
      class="label"
    >{{ label }}</label>

    <div class="control">
      <textarea
        class="textarea has-fixed-size custom-scroll"
        :placeholder="placeholder"
        :value="modelValue"
        :readonly="readonly"
        @input="$emit('update:modelValue', $event.target.value)"
      />

      <span
        v-if="validation.$error"
        class="validation-error"
      >{{ $Utilities.getErrorMsg(validation) }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      required: false,
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    modelValue: {
      type: [String, Number, Boolean, Object, null],
      default: null
    },
    readonly: {
      required: false,
      default: false,
      type: Boolean
    },
    validation: {
      required: false,
      type: Object,
      default: () => {
        return {
          $error: false
        };
      }
    },
    error: {
      required: false,
      type: Boolean,
      default: false
    },
    errorMessage: {
      required: false,
      type: String,
      default: ""
    }
  },
  emits: ['update:modelValue']

};
</script>

<style>
    @import '~@assets/css/components/textarea.css';
</style>
