<template>
  <div
    class="barriers-history-popup-overlay"
    @click="$emit('close')"
  >
    <div
      class="barriers-history-popup"
      @click.stop
    >
      <h3>Histórico de barreiras</h3>
      <div class="barriers-history-popup-content">
        <div
          v-if="history.length > 0"
          class="barrier-history"
        >
          <div
            v-for="(item, index) in history"
            :key="index"
            class="barrier-history-item"
          >
            <p>Barreiras anotadas em {{ Utilities.formatDateTimeAndMinutes(item.DateAndTime) }}</p>
            
            <!-- Verifica se o BarrierItems está vazio -->
            <ul v-if="item.BarrierItems.length > 0">
              <li
                v-for="(barrier, idx) in item.BarrierItems"
                :key="idx"
              >
                <span class="barrier-name">{{ barrier.BarrierType.Name }}</span>
                <span
                  v-if="barrier.Description"
                  class="barrier-description"
                > - {{ barrier.Description }}</span>
              </li>
            </ul>
            
            <!-- Se o BarrierItems estiver vazio, exibe esta mensagem -->
            <span
              v-else
              class="no-barriers"
            >
              Nenhuma barreira foi registrada para o paciente.
            </span>
          </div>
        </div>

        <!-- Caso não haja histórico, exibe uma mensagem de aviso -->
        <div
          v-else
          class="no-history"
        >
          <p>Nenhuma barreira foi registrada para este paciente até o momento.</p>
        </div>
      </div>
      <button
        class="button barrier-history-close-button"
        @click="$emit('close')"
      >
        Fechar
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import axios, { userSessionName } from "../../js/axios";
import { useRoute } from "vue-router"; // Para acessar os parâmetros da rota
import { Session } from "../../plugins/Session";
import { Utilities } from "../../plugins/Utilities.js";

const emit = defineEmits(['close']);

// Estado reativo para armazenar o histórico de barreiras
const history = ref([]);

// Obtendo o patientId da rota
const route = useRoute();
const patientId = route.params.id;

// Função para buscar o histórico de barreiras do paciente
async function getBarrierHistory() {
  try {
    const response = await axios.get(
      `/api/barrier/history/${patientId}`,
      { headers: { "Authorization": `bearer ${Session.get(userSessionName).token.Value}` } }
    );

    history.value = response.data;

    if (history.value.length === 0) {
      console.log("Nenhum dado de histórico disponível");
    }
  } catch (error) {
    console.error(error);
    Utilities.verifyToken(error.response?.status);
    Utilities.controlAlert(error.response?.data, { show: true, type: "danger", message: "Erro ao buscar histórico de barreiras" });
  }
}

// Chama a função para buscar o histórico quando o componente for montado
onMounted(() => {
  getBarrierHistory();
});
</script>


<style scoped>
.barriers-history-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.barriers-history-popup {
  background: #fff;
  min-height: 500px;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 600px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.barriers-history-popup h3 {
  margin-top: 0;
  font-size: 30px;
  font-weight: 700;
  color: #4f3f82;
}

.barriers-history-popup-content {
  max-height: 400px;
  overflow-y: auto;
}

.barrier-history {
  margin-top: 10px;
}

.barrier-history-item {
  background: #f9f9f9;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.barrier-history-item p {
  margin: 0;
  font-weight: bold;
  color: #4f3f82;
}

.barrier-history-item ul {
  margin: 5px 0 0 0;
  padding-left: 20px;
}

.barrier-history-item li {
  margin-top: 5px;
  list-style-type: disc;
  color: #333;
}

.barrier-history-close-button {
  background: #9a397d;
  color: #fff;
  border: none;
  margin: 15px;
  padding: 10px 20px;
  border-radius: 30px;
  cursor: pointer;
  top: 10px;
  right: 10px;
}

.barrier-history-close-button:hover {
  background: #3e3270;
}

.barrier-name {
  font-weight: bold; /* Negrito para a barreira */
  color: #333; /* Cor mais escura */
}

.barrier-description {
  color: #666; /* Cor mais suave */
}

.no-barriers {
  color: #444;
  display: flex;
  margin-top: 5px;
}

.no-history {
  text-align: center;
  font-size: 18px;
  color: #666;
  padding: 20px;
}
</style>
