<template>
  <div class="login hero is-fullheight">
    <div class="hero-head">
      <div class="container">
        <img
          src="~@assets/images/logo-lila.png"
          class="logo-lila"
        >
      </div>
    </div>

    <div class="hero-body">
      <div class="container">
        <div class="login-area-box">
          <slot name="mainContent" />
        </div>
      </div>
    </div>
  </div>
</template>

<script></script>

<style>
    @import '~@assets/css/views/login.css';
</style>