<template>
  <div 
    v-if="modelValue" 
    class="modal modal-confirm-close"
  >
    <div class="modal-content">
      <h1>
        Deseja confirmar o encerramento do chamado?
      </h1>

      <lila-message :alert-data="alertData" />

      <div class="buttons-container">
        <button 
          class="button modal-btn" 
          @click="closeTicket"
        >
          Sim
        </button>

        <button 
          class="button modal-btn" 
          @click="closeModal"
        >
          Não
        </button>
      </div>

      <button 
        class="close-btn delete" 
        @click="closeModal"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, inject } from "vue";
import axios, { userSessionName } from "../../js/axios";
import { Session } from "../../plugins/Session";
import { Utilities } from "../../plugins/Utilities.js";
import LilaMessage from "../shared/message/Message.vue";

const p = defineProps({
  modelValue: Boolean,
  ticketId: null,
  alert: null
});

const emit = defineEmits(["update:modelValue"]);
const controlAlertMessage = inject("alert-message");
const alertData = ref({
  show: false,
  type: "",
  message: ""
});

function closeModal() {
  emit("update:modelValue", false);
}

async function closeTicket() {
  try {
    // eslint-disable-next-line no-unused-vars
    const response = await axios.post(
      `/api/ticket/${p.ticketId}/outcomes`,
      {}, 
      {headers: {Authorization: `bearer ${Session.get(userSessionName).token.Value}`}}
    );

    closeModal();

    Utilities.controlAlert(null, controlAlertMessage.value, "success", "Chamado encerrado com sucesso.");
  } catch (error) {
    console.error(error);
    if (error.response) {
      Utilities.verifyToken(error.response.status);
      Utilities.controlAlert(error.response.data, alertData.value, "danger");
    } else if (error.request) {
      Utilities.controlAlert(null, alertData.value, "danger", "O servidor não responde");
    } else {
      Utilities.controlAlert(error.message, alertData.value, "danger");
    }
  }
}
</script>

<style>
.modal-confirm-close {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-confirm-close h1 {
  font-size: 30px;
  font-weight: 700;
  color: #4f3f82;
}

.modal-confirm-close .modal-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #f2f2f2;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  width: 600px;
  height: 400px;
  overflow: auto;
}

.modal-confirm-close .buttons-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-top: auto;
}

.modal-confirm-close .modal-btn,
.modal-confirm-close .modal-save-btn {
  color: #9a397d;
  border: 2px solid #9a397d;
  border-radius: 10px;
  background-color: white;
  padding: 10px 30px;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  width: 250px;
  height: 35px;
  margin: 0;
}

.modal-confirm-close .modal-save-btn {
  width: 100px;
}

.modal-confirm-close .modal-btn:hover,
.modal-confirm-close .modal-save-btn:hover {
  background-color: #9a397d;
  color: white;
}

.modal-confirm-close .close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
}
</style>
