<template>
  <div
    id="terms"
    class="terms terms-component"
    :class="activeToggle == modalActive ? 'animate-terms' : ''"
  >
    <div class="container">
      <figure>
        <img
          src="~@assets/images/logo-lila.png"
          class="logo-lila"
        >
      </figure>

      <h1 class="title">
        Termos e condições gerais de uso do aplicativo Lila
      </h1>

      <lila-terms-doc />

      <lila-button 
        v-if="showAcceptTerms"
        type="submit" 
        title="Li e Aceito os termos de Uso" 
        class="submit-buttons"
        :second-button="{type: 'textButton', title: 'Voltar'}"
        @custom-click-event="acceptTerms"
        @custom-click-cancel-event="notAcceptTerms"
      />

      <lila-button 
        v-else
        type="textButton" 
        title="Voltar" 
        class="submit-buttons"
        @custom-click-event="closeTerms"
      />
    </div>
  </div>
</template>

<script>
import TermsDoc from "@components/shared/lila/TermsDoc.vue";
import Button from "@components/shared/button/Button.vue";

export default {
  components: {
    "lila-terms-doc": TermsDoc,
    "lila-button": Button
  },
  props: {
    showAcceptTerms: {
      required: false,
      type: Boolean,
      default: true
    },
    modalActive: {
      required: true,
      type: Boolean,
      default: false
    }
  },
  emits: ['acceptTerms'],
  data() {
    return {
      activeToggle: true
    };
  },
  watch: {          
    "activeToggle": {
      handler: function(){
        if(this.activeToggle != this.modalActive){
          document.querySelector("#terms .container").scrollTo(0,0);
        }
      }
    }
  },
  methods: {
    acceptTerms(){
      this.closeTerms();
      this.$emit("acceptTerms", true);
    },
    notAcceptTerms(){
      this.closeTerms();
      this.$emit("acceptTerms", false);                
    },
    closeTerms(){
      this.activeToggle = !this.activeToggle;
    }
  }
};
</script>

<style>
    @import '~@assets/css/components/terms.css';
</style>