
<template>
  <div class="symptom-item">
    <time>{{ dateTime }}</time>

    <div
      v-if="symptom.NoSymptoms"
      class="symptom background"
    >
      Estou me sentindo bem
    </div>

    <template v-if="symptom.MissingSymptom">
      <div class="symptom background">
        Não encontrei meu Sintoma
      </div>

      <div class="box-message">
        <p class="box-message-title">
          Mensagem do Paciente
        </p>
        <div class="message background">
          <p>{{ symptom.Message }}</p>
        </div>
      </div>
    </template>

    <template v-else>
      <div
        v-for="(evolution, index) in symptom.Evolutions"
        :key="`evolution_${index}`"
        class="symptom-pain rating background"
        :class="getSymptomsIntensityClass(evolution.Intensity, evolution.Symptom)"
      >
        {{ evolution.Symptom.Name }}
        <span>Intensidade {{ evolution.Intensity }}</span>
      </div>
    </template>
  </div>
</template>

<script setup>
import { computed } from "vue";
import moment from "moment";

const p = defineProps({
  symptom: {
    type: Object,
    default: () => ({
      NoSymptoms: false,
      MissingSymptom: false,
      Message: '',
      Evolutions: [],
      DateAndTime: ''
    })
  }
});

const dateTime = computed(() =>{
  const d = p.symptom.DateAndTime.replace(/Z/, "");
  return moment(d, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY HH:mm");
});

function inRange(minValue, maxValue, value){
  if(minValue == null) return value <= maxValue;
  if(maxValue == null) return value >= minValue;

  return value >= minValue && value <= maxValue;
}

function getSymptomsIntensityClass(intensity, symptom){
  if(symptom.Id == 13){
    if(inRange(36.0, 36.7, intensity)) return "fever0";
    if(inRange(36.8, 37.0, intensity)) return "fever1";
    if(inRange(37.1, 37.3, intensity)) return "fever2";
    if(inRange(37.4, 37.7, intensity)) return "fever3";
    if(inRange(37.8, null, intensity)) return "fever4";
  }

  if(symptom.IsEva) return `pain${intensity}`;

  return `level${intensity}`;
}
</script>
