<template>
  <div class="lila-chat-message">
    <div
      v-if="showMessageDate"
      class="has-text-centered"
    >
      <div class="message-date">
        {{ $Utilities.formatDateWithTime(message.DateAndTime, "YYYY-MM-DDTHH:mm:ss", "DD/MM/YYYY") }}
      </div>
    </div>

    <div
      :class="message.Origin === 'Patient' || message.Origin === 1 ? 'patient-message' : 'doctor-message'"
    >
      <div v-if="message.AttachedDocument">
        <p
          v-if="$Utilities.getFileExtension(message.AttachedDocument) === 'pdf'"
          class="file-link"
        >
          <a
            :href="message.AttachedDocument"
            target="_blank"
            download
          >
            Baixar PDF
          </a>
        </p>

        <figure
          v-else
          class="image"
          @click="onClick"
        >
          <img :src="message.AttachedDocument">
        </figure>
      </div>

      <p
        v-if="message.Message"
        v-html="$Utilities.addHiperlinkinText(message.Message)"
      />

      <div class="message-footer">
        <p class="message-origin">
          {{ messageOrigin }}
        </p>

        <p class="has-text-right">
          <time>{{ $Utilities.formatDateWithTime(message.DateAndTime, "YYYY-MM-DDTHH:mm:ss", "HH:mm") }}</time>
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import moment from "moment";
import { computed } from "vue";
import getValueIgnoreCase from "@js/getValueIgnoreCase.js";
import useDialog from "@composables/useDialog.js";
import LilaImg from "../Common/LIlaImg.vue";

const p = defineProps({
    message: null,
    lastDate: null
});

const {
    create
} = useDialog();

const messageOrigin = computed(() => {
  let result = "";

  if (
    p.message.Doctor == null &&
    p.message.Professional == null &&
    p.message.Origin !== "Patient" && p.message.Origin !== 1
  ) {
    result = "Mensagem Automática";
  } else if (
    p.message.Origin === "Professional" ||
    p.message.Origin === 2
  ) {
    if (
      p.message.Professional != null &&
      getValueIgnoreCase(p.message.Professional, "Name") != null
    ) {
      result = getValueIgnoreCase(p.message.Professional, "Name");
    }
  }
  return result;
});

const showMessageDate = computed(() => {
    return !p.lastDate || moment(p.lastDate, "YYYY-MM-DD").unix() < moment(p.message.DateAndTime, "YYYY-MM-DD").unix();
});

function onClick() {
    create({
        component: LilaImg,
        componentProps: {
            src: p.message.AttachedDocument,
            style: {
              width: "40%",
              alingSelf: "center",
              margin: "auto"
            }
        }
    });
}
</script>

<style>
.lila-chat-message {
  width: 100%
}

.lila-chat-message figure {
  cursor: pointer;
}

.lila-chat-message .doctor-message {
  margin-left: auto
}
</style>
