import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "historyRecord" }
const _hoisted_2 = { class: "field-group-header align-title" }
const _hoisted_3 = { class: "semi-bold-text" }
const _hoisted_4 = { class: "field-group-body" }
const _hoisted_5 = {
  ref: "historyRecordForm",
  class: "field-group"
}
const _hoisted_6 = { class: "field-group-body" }
const _hoisted_7 = { class: "columns" }
const _hoisted_8 = { class: "column is-6" }
const _hoisted_9 = { class: "column is-6" }
const _hoisted_10 = { class: "modal-card" }
const _hoisted_11 = { class: "modal-card-foot" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lila_message = _resolveComponent("lila-message")!
  const _component_lila_history_record_card = _resolveComponent("lila-history-record-card")!
  const _component_lila_pagination = _resolveComponent("lila-pagination")!
  const _component_lila_select = _resolveComponent("lila-select")!
  const _component_lila_datepicker = _resolveComponent("lila-datepicker")!
  const _component_lila_textarea = _resolveComponent("lila-textarea")!
  const _component_lila_button = _resolveComponent("lila-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_lila_message, { "alert-data": _ctx.alert }, null, 8, ["alert-data"]),
    _createElementVNode("div", {
      class: _normalizeClass(["field-group history-record-container", [
        _ctx.loadingContent ? 'loading large' : '',
        _ctx.loadingContent || _ctx.data.historyRecordList.length > 0
          ? 'history-record-container-default-height'
          : '',
      ]])
    }, [
      _withDirectives(_createElementVNode("div", _hoisted_2, [
        _withDirectives(_createElementVNode("p", _hoisted_3, " Você ainda não incluiu nenhum Registro. ", 512), [
          [_vShow, _ctx.data.historyRecordList.length == 0]
        ])
      ], 512), [
        [_vShow, !_ctx.loadingContent]
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_4, [
        _createVNode(_component_lila_history_record_card, {
          "data-list": _ctx.data.historyRecordList,
          "extended-description": true,
          "card-size": "is-12-desktop",
          onCustomClickEditEvent: _ctx.mountEditHistoryRecord,
          onCustomClickDeleteEvent: _ctx.openDeleteModal
        }, null, 8, ["data-list", "onCustomClickEditEvent", "onCustomClickDeleteEvent"])
      ], 512), [
        [_vShow, !_ctx.loadingContent]
      ])
    ], 2),
    _withDirectives(_createVNode(_component_lila_pagination, {
      "pagination-config": _ctx.pagination,
      onCustomChangePageEvent: _ctx.changePage
    }, null, 8, ["pagination-config", "onCustomChangePageEvent"]), [
      [_vShow, !_ctx.loadingContent && _ctx.data.historyRecordList.length > 0]
    ]),
    _createElementVNode("form", {
      class: "container basic-info-form medical-card-form history-record-form",
      onSubmit: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (_ctx.submitForm()), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_5, [
        _cache[8] || (_cache[8] = _createElementVNode("div", { class: "field-group-header align-title" }, [
          _createElementVNode("p", { class: "field-group-label-type" }, " Adicionar Registro ")
        ], -1)),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_lila_select, {
            modelValue: _ctx.v.newHistoryRecord.HistoryRecordType.Id.$model,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.v.newHistoryRecord.HistoryRecordType.Id.$model) = $event)),
            placeholder: "Escolha o tipo do Registro",
            options: _ctx.historyRecordTypeList,
            validation: _ctx.v.newHistoryRecord.HistoryRecordType.Id,
            onCustomChangeEvent: _ctx.changeHistoryRecordType
          }, null, 8, ["modelValue", "options", "validation", "onCustomChangeEvent"]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _withDirectives(_createVNode(_component_lila_datepicker, {
                modelValue: _ctx.v.newHistoryRecord.InitialDate.$model,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.v.newHistoryRecord.InitialDate.$model) = $event)),
                placeholder: _ctx.newHistoryRecord.HistoryRecordType?.HasEndDate ? 'Data início' : 'Data',
                validation: _ctx.v.newHistoryRecord.InitialDate
              }, null, 8, ["modelValue", "placeholder", "validation"]), [
                [_vShow, _ctx.newHistoryRecord.HistoryRecordType?.Id != 4]
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _withDirectives(_createVNode(_component_lila_datepicker, {
                modelValue: _ctx.v.newHistoryRecord.EndDate.$model,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.v.newHistoryRecord.EndDate.$model) = $event)),
                placeholder: "Data fim",
                validation: _ctx.v.newHistoryRecord.EndDate
              }, null, 8, ["modelValue", "validation"]), [
                [_vShow, _ctx.newHistoryRecord.HistoryRecordType?.HasEndDate]
              ])
            ])
          ]),
          _withDirectives(_createVNode(_component_lila_textarea, {
            modelValue: _ctx.v.newHistoryRecord.HistoryRecordNotes.$model,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.v.newHistoryRecord.HistoryRecordNotes.$model) = $event)),
            placeholder: "Anotações do Registro",
            validation: _ctx.v.newHistoryRecord.HistoryRecordNotes
          }, null, 8, ["modelValue", "validation"]), [
            [_vShow, _ctx.newHistoryRecord.HistoryRecordType?.Id != 4]
          ]),
          _createVNode(_component_lila_button, {
            type: "submit",
            title: _ctx.newHistoryRecord.Id ? 'Editar' : 'Adicionar',
            "second-button": _ctx.newHistoryRecord.Id ? { type: 'button', title: 'Cancelar' } : {},
            loading: _ctx.loading,
            onCustomClickCancelEvent: _cache[4] || (_cache[4] = ($event: any) => (_ctx.clearHistoryRecord()))
          }, null, 8, ["title", "second-button", "loading"])
        ])
      ], 512)
    ], 32),
    _createElementVNode("div", {
      class: _normalizeClass(["modal default-modal", _ctx.deleteModal.active ? 'is-active' : ''])
    }, [
      _cache[11] || (_cache[11] = _createElementVNode("div", { class: "modal-background" }, null, -1)),
      _createElementVNode("div", _hoisted_10, [
        _cache[9] || (_cache[9] = _createElementVNode("header", { class: "modal-card-head" }, [
          _createElementVNode("p", { class: "modal-card-title" }, " Excluir ")
        ], -1)),
        _cache[10] || (_cache[10] = _createElementVNode("section", { class: "modal-card-body" }, [
          _createElementVNode("p", null, "Deseja excluir esse registro?")
        ], -1)),
        _createElementVNode("footer", _hoisted_11, [
          _createVNode(_component_lila_button, {
            type: "submit",
            title: "Excluir",
            class: "submit-buttons",
            "second-button": { type: 'button', title: 'Cancelar', class: 'dark-lilas' },
            onCustomClickEvent: _cache[6] || (_cache[6] = ($event: any) => (_ctx.deleteHistoryRecord(_ctx.deleteModal.deleted))),
            onCustomClickCancelEvent: _cache[7] || (_cache[7] = ($event: any) => (_ctx.closeDeleteModal()))
          })
        ])
      ])
    ], 2)
  ]))
}