<template>
  <lila-default-template>
    <template #mainContent>
      <form
        class="container basic-info-form"
        @submit.prevent="submitForm()"
      >
        <h1 class="title">
          Dados Cadastrais
        </h1>

        <lila-message :alert-data="alert" />

        <div class="columns">
          <div class="column is-5">
            <div class="field-group bottom-gap">
              <div class="field-group-header align-title">
                <p>Dados</p>
              </div>

              <div class="field-group-body">
                <lila-input
                  v-model="v.data.name.$model"
                  placeholder="Nome"
                  :validation="v.data.name"
                />

                <lila-mask
                  v-model="v.data.phone.$model"
                  placeholder="Telefone"
                  :mask="['(##) ####-####', '(##) #####-####']"
                  :validation="v.data.phone"
                />
              </div>
            </div>

            <div class="field-group bottom-gap">
              <div class="field-group-header align-title">
                <p>Dados Adicionais</p>
              </div>

              <div class="field-group-body">
                <lila-select
                  v-model="v.data.gender.$model"
                  placeholder="Sexo"
                  :options="genderList"
                  :validation="v.data.gender"
                />
              </div>
            </div>

            <div>
              <div class="field-group-header align-title">
                <p>Sua Foto</p>
              </div>

              <div class="field-group-body">
                <div class="icon-upload">
                  <lila-svg-icon
                    v-show="!file.uploaded && !file.photoUrl"
                    class="upload-placeholder is-cursor-pointer"
                    :src="require(`@assets/icons/your_photo.svg`)"
                    @custom-click-event="openFolderDirectory"
                  />

                  <figure
                    v-show="file.uploaded"
                    class="image"
                  >
                    <img
                      id="imagePreview"
                      ref="imagePreview"
                      src="#"
                      class="is-rounded"
                    >
                  </figure>

                  <figure
                    v-show="file.photoUrl && !file.uploaded"
                    class="image doctor-profile-picture is-cursor-pointer"
                    @click="openFolderDirectory"
                  >
                    <img
                      id="imagePreview"
                      ref="imagePreview"
                      :src="file.photoUrl"
                      class="is-rounded"
                    >

                    <p
                      v-show="file.photoUrl && !file.uploaded"
                      class="photo-text is-cursor-pointer"
                    >
                      Clique para mudar a sua foto
                    </p>
                  </figure>


                  <input
                    id="fileInput"
                    ref="file"
                    type="file"
                    class="file-input"
                    @change="handleFileUpload"
                  >
                </div>

                <button
                  v-show="file.uploaded"
                  type="button"
                  class="remove-image"
                  @click="deleteImage"
                >
                  <lila-svg-icon :src="require(`@assets/icons/delete.svg`)" /> Excluir
                </button>
              </div>
            </div>

            <div id="save-registration-data">
              <lila-button
                type="submit"
                title="Salvar"
                class="submit-buttons"
                :loading="loading"
              />
            </div>
          </div>
          


          <div class="column is-2 middle-column">
            <div class="line" />
          </div>
        </div>
      </form>
    </template>
  </lila-default-template>
</template>

<script>
import DefaultTemplate from "@components/template/DefaultTemplate.vue";
import Input from "@components/shared/fields/Input.vue";
import LilaMask from "@components/shared/fields/LilaMask.vue";
import Select from "@components/shared/fields/Select.vue";
import Button from "@components/shared/button/Button.vue";
import SvgIcon from "@components/shared/images/SvgIcon.vue";
import Message from "@components/shared/message/Message.vue";

import { required } from "@vuelidate/validators";

import GenderOptions from "@assets/options/gender.json";

const verifyLastName = function(value){
  let name = value.trim().replace(/\s\s+/g, " ").split(" ");

  if(typeof name[1] == "undefined" || (typeof name[1] != "undefined" && name[1].length < 1)) return false;

  return true;
};



export default {
  components: {
    "lila-input": Input,
    "lila-select": Select,
    "lila-button": Button,
    "lila-svg-icon": SvgIcon,
    "lila-message": Message,
    "lila-default-template": DefaultTemplate,
    LilaMask
  },
  data() {
    return {
      data: {
        name: this.$Session.get(this.$userSessionName).professional.Name,
        phone: this.$Session.get(this.$userSessionName).professional.Phone,
        gender: this.$Session.get(this.$userSessionName).professional.Gender
      },
      file: {
        title: "",
        content: "",
        uploaded: false,
        photoUrl: this.$Session.get(this.$userSessionName).professional.Photo
      },
      genderList: GenderOptions.options,
      loading: false,
      alert: {
        message: "",
        type: "",
        show: false
      }
    };
  },
  validations() {
    return {
      data: {
        name: {
          required,
          verifyLastName
        },
        phone: {required},
        gender: {required}
      }
    };
  },
  methods: {
    submitForm(){
      this.alert.show = false;

      this.v.$touch();

      if(this.v.$invalid) return;

      this.loading = true;

      let requestList = [
        this.saveRegistrationData(),
        this.uploadImage()
      ];

      Promise
        .all(requestList)
        .then(() => {
          this.$Utilities.controlAlert(null, this.alert, "edit");
          this.loading = false;
        })
        .catch(error => {
          this.$Utilities.verifyToken(error.response.status);
          this.$Utilities.controlAlert(error.response.data, this.alert, "danger");
        })
        .finally(() => this.loading = false);
    },
    updateSessionProfile(){
      
          let sessionData = this.$Session.get(this.$userSessionName);
          sessionData.professional.Name = this.data.name;
          sessionData.professional.Gender = this.data.gender;
          sessionData.professional.Phone = this.data.phone;
          this.$Session.set(this.$userSessionName, sessionData);
    },
    updateSessionPhoto(data){
      let sessionData = this.$Session.get(this.$userSessionName);
      sessionData.professional.Photo = data.photo;
      this.$Session.set(this.$userSessionName, sessionData);
    },
    saveRegistrationData(){
      let professional = Object.assign({}, this.data);

      return this.$axios({
        method: "PUT",
        url: "api/professional/personalinfo",
        data: professional,
        headers: {"Authorization": `bearer ${this.$Session.get(this.$userSessionName).token.Value}`}
      })
        .then(() => this.updateSessionProfile());
    },

    uploadImage(){
      if(!this.file.uploaded) return;

      let formData = new FormData();

      formData.append("photo", this.file.content);

      return this.$axios({
        method: "PUT",
        url: "/api/professional/uploadPhoto",
        data: formData,
        headers: {
          "Authorization": `bearer ${this.$Session.get(this.$userSessionName).token.Value}`,
          "Content-Type": "multipart/form-data"
        }
      })
        .then(res => this.updateSessionPhoto(res.data));
    },
    openFolderDirectory(){
      this.$refs.file.click();
    },
    handleFileUpload(){
      let reader = new FileReader();

      this.file.content = this.$refs.file.files[0];
      this.file.title = this.file.content.name;

      reader.onload = (e) => {
        document.querySelector("#imagePreview").src = e.target.result;

        this.file.uploaded = true;
      };

      reader.readAsDataURL(this.file.content);
    },
    deleteImage(){
      document.querySelector("#imagePreview").src = "";

      this.$refs.file.value = "";

      this.file.title = "";
      this.file.content = "";
      this.file.uploaded = false;
    }
  }
};
</script>

<style>
    @import '~@assets/css/views/complementaryRegistration.css';

    #save-registration-data{
      display: flex;
      justify-content: start;
      margin: 0 0 0 5px;
      padding: 0;
    }
</style>
