<template>
  <div
    class="messages chat"
    :class="[
      loadingContent ? 'loading large' : ''
    ]"
    :data-cy="data.ticketId ? `ticket-id-${data.ticketId}` : null"
  >
    <div
      v-show="!loadingContent"
      class="box-chat"
    >
      <lila-message :alert-data="alert" />
      
      <div
        class="buttons-chat-container"
      >
        <button 
          v-show="data.ticketId != null && (userProfile == 'doctor'
            || userProfile == 'professional_leader'
            || (userProfile == 'professional_default' && chatOwner))"
          :class="[ 'button', 'transfer-ticket', { 'transfer-ticket-premium': !hasFeatureId(1) }]"
          :disabled="!hasFeatureId(1)"
          @click="openTransferToProfessionalModal()"
          @mouseover="showText = true"
          @mouseout="showText = false"
        >
          <span
            v-show="showText && !hasFeatureId(1)"
            class="tooltip"
          >
            Disponível apenas para assinantes!
          </span>
          Transferir
          <i
            v-if="!hasFeatureId(1)"
            class="fas fa-star"
          />
        </button>

        <button
          v-show="data.ticketId != null && (userProfile == 'doctor'
            || userProfile == 'professional_leader'
            || (userProfile == 'professional_default' && chatOwner))"
          class="button close-ticket"
          @click="closeTicket()"
        >
          Encerrar
        </button>
        <lila-chat-outcome
          v-model="showModalOutcome"
          :ticket-id="data.ticketId"
          :alert="alert"
        />
        <lila-confirm-close-ticket
          v-model="showModalconfirmCloseTicket"
          :ticket-id="data.ticketId"
          :alert="alert"
        />
      </div>

      <div
        v-show="data.ticketId == null && !openChat"
        class="idle-chat-text"
      >
        <p
          v-if="data.closedTicket"
          class="idle-text purple-text has-text-centered"
        >
          O chamado foi encerrado
        </p>
        <p
          v-else
          class="idle-text purple-text has-text-centered"
        >
          Você não tem uma conversa aberta com esse paciente
        </p>

        <p
          v-if="data.closedTicket"
          class="text-1 grey-text has-text-centered"
        >
          Deseja iniciar uma nova conversa?
        </p>
        <p
          v-else
          class="text-1 grey-text has-text-centered"
        >
          Deseja iniciar uma conversa?
        </p>

        <lila-button
          type="button"
          title="Iniciar Conversa"
          class="button"
          :is-centralized="true"
          @custom-click-event="openTheChat()"
        />
      </div>

      <div
        ref="messagesListBox"
        class="messages-list custom-scroll"
      >
        <div v-if="data.ticketId != null || openChat">
          <lila-chat-message
            v-for="(item, index) in data.messageList"
            :key="`chat-message-${index}`"
            :message="item"
            :last-date="index <= 0 ? undefined : data.messageList[index - 1].DateAndTime"
          />
        </div>
      </div>

      <lila-transfer-ticket
        :modal-transfer-to-professional-active="modalTransferToProfessionalActive"
        :close-transfer-to-professional-modal="closeTransferToProfessionalModal"
        :patient-id="data.patientId"
        :user-id="userId"
        :ticket-id="data.ticketId"
        :alert="alert"
      />

      <lila-message-typer
        :data="data"
        :open-chat="openChat"
        :chat-owner="chatOwner"
        :loading="loading"
        :alert="alert"
        :scroll-chat-to-bottom="scrollChatToBottom"
        :set-chat-attributes="setChatAttributes"
        :open-transfer-to-professional-modal="openTransferToProfessionalModal"
      />
    </div>
  </div>
</template>

<script>
import Button from "@components/shared/button/Button.vue";
import Message from "@components/shared/message/Message.vue";
import LilaChatMessage from "@components/Chat/LilaChatMessage.vue";
import LilaChatOutcome from "@components/Chat/LilaChatOutcome.vue";
import LilaConfirmCloseTicket from "@components/Chat/LilaConfirmCloseTicket.vue";
import LilaMessageTyper from "@components/Chat/LilaMessageTyper.vue";
import decodeToken from "../../../js/decodeToken.js";
import LilaTransferTicket from "../../../components/Chat/LilaTransferTicket.vue";

require("@microsoft/signalr");

export default {
  components: {
    "lila-button": Button,
    "lila-message": Message,
    LilaChatMessage,
    LilaChatOutcome,
    LilaMessageTyper,
    LilaConfirmCloseTicket,
    LilaTransferTicket
  },
  props: {
    closedTicketId: {
      type: Number, // Definindo o tipo como Number
      required: false, // A prop não é obrigatória
      default: null // Valor padrão quando não for fornecida
    },
    newMessageForPatient: {
      type: Object, // Definindo o tipo como Object
      required: false, // A prop não é obrigatória
      default: () => ({}) // Valor padrão como um objeto vazio
    }
  },
  emits: [
    "updateTicketMessage", // Adiciona o evento emitido
    "removeTicket" // Adiciona o evento emitido
  ],
  data(){
    return {
      userId: null,
      chatOwnerId: 0,
      userProfile: "",
      data: {
        patientId: this.$route.params.id,
        ticketId: null,
        closedTicket: false,
        date: "",
        doctorPriority: "",
        messageList: [],
        message: "",
        file: {
          title: "",
          content: ""
        },
        tempDate: "",
        tempDoctorPriority: ""
      },
      tempDate: "",
      professionalList: [],
      selectedProfessional: null,
      modalReclassifyUrgencyActive: false,
      loadingCreateChat: false,
      loadingContent: false,
      loadingSignalR: false,
      sendingMessage: false,
      changingUrgency: false,
      showModalOutcome: false,
      showModalconfirmCloseTicket: false,
      openChat: false,
      alert: {
        message: "",
        type: "",
        show: false
      },
      chatSignalR: {
        active: false,
        connection: null,
        timeout: false,
        retryCount: 0
      },
      signalRTimeout: 5000,
      signalRMaxRetry: 3,
      loading: false,
      deletingAllTickets: false,
      deletingAllSymptomsAlerts: false,
      patientColumnHeight: 0,
      patientColumnHeightInterval: null,
      modalTransferToProfessionalActive: false,
      sendingTransferToProfessional: false,
      showText: false
    };
  },
  computed: {
    chatOwner(){
      return this.chatOwnerId == 0
                       || (this.$Session.get(this.$userSessionName).professional != null && this.chatOwnerId == this.$Session.get(this.$userSessionName).professional.Id)
                       || this.$Session.get(this.$userSessionName).profile == "professional_leader"
                       || this.$Session.get(this.$userSessionName).profile == "doctor";
    }
  },
  watch:{
    $route (to){
      this.data.patientId = to.params.id;
      this.chatOwnerId = 0;
      this.v.$reset();
      this.getChat();

    },
    "closedTicketId": {
      handler: function(){
        if(this.closedTicketId == this.data.ticketId) this.closeChat();
      }
    },
    "newMessageForPatient": {
      handler: function(){
        if(this.newMessageForPatient.patientId == this.data.patientId && this.data.ticketId == null)
          this.getChat();
      }
    }
  },
  created(){
    this.userProfile = this.$Session.get(this.$userSessionName).profile;
    this.userId = this.$Session.get(this.$userSessionName).professional.Id;

    this.getChat();

    this.startChatSignalR();
  },
  methods: {
    getChat(){
      this.alert.show = false;
      this.loadingContent = true;

      this.closeChat();

      this.$axios
        .get(
          `/api/chat/patient/${this.data.patientId}`,
          {headers: {"Authorization": `bearer ${this.$Session.get(this.$userSessionName).token.Value}`}}
        )
        .then((res) => {
          this.setChatAttributes(res.data);

          this.scrollChatToBottom();
        })
        .catch(error => {
          this.$Utilities.verifyToken(error.response.status);

          if(error.response.status != 404) this.$Utilities.controlAlert(error.response.data, this.alert, "danger");
        })
        .finally(() => this.loadingContent = false);
    },
    setChatAttributes(data){
      this.data.ticketId = data.Id;
      this.data.closedTicket = false;
      this.data.date = data.Date;
      this.data.doctorPriority = data.DoctorPriority;
      this.data.tempDoctorPriority = data.DoctorPriority;
      this.data.messageList = data.Messages[0] != null ? data.Messages : [];
      this.chatOwnerId = data.ClosingProfessionalId;
    },
    openTheChat(){
      this.openChat = true;
    },
    closeTicket(){
      const featureCloseTicketWithOutcomes = 0;
      if (this.hasFeatureId(featureCloseTicketWithOutcomes)) {
        this.openLilaChatOutcome();
      } else {
        this.openConfirmCloseTicket();
      }
    },
    openLilaChatOutcome() {
      this.showModalOutcome = true;
    },
    openConfirmCloseTicket() {
      this.showModalconfirmCloseTicket = true;
    },
    hasFeatureId(featureId) {
      const decodedToken = decodeToken(this.$Session.get(this.$userSessionName).token.Value);
      return decodedToken && decodedToken.FeaturesId.includes(featureId);
    },
    scrollChatToBottom(){
      setTimeout(function($this){
        if (!$this.$refs.messagesListBox || !$this.$refs.messagesListBox.scrollTo || !$this.$refs.messagesListBox.scrollHeight) return;
        $this.$refs.messagesListBox.scrollTo(0, $this.$refs.messagesListBox.scrollHeight);
      }, 500, this);
    },
    verifyCurrentDate(date){
      if(this.tempDate == "" || (this.$moment(date, "YYYY-MM-DD").unix() > this.$moment(this.tempDate, "YYYY-MM-DD").unix())){
        this.tempDate = date;

        return true;
      }else{
        return false;
      }
    },
    openTransferToProfessionalModal(){                
      this.modalTransferToProfessionalActive = true;
    },
    closeTransferToProfessionalModal(){
      this.v.$reset();
      this.modalTransferToProfessionalActive = false;
    },
    resetCardOnMessageFromProfessional(priority, message){
      this.$emit("updateTicketMessage", priority, this.data.patientId, message, this.data.messageList[this.data.messageList.length - 1].DateAndTime);
    },
    removeTicket(){
      this.$emit("removeTicket", this.data.patientId);
    },
    closeChat(){
      this.openChat = false;
      this.data.ticketId = null;
      this.data.closedTicket = true;
      this.data.date = "";
      this.data.doctorPriority = "";
      this.data.tempDoctorPriority = "";
      this.data.messageList = [];
    },
    updateChatMessageByProfessional(data){
      if (data.professional.id == this.userId) return;

      let formData = new FormData();
      let newData = Object.entries(data).reduce(function(item, [key, value]) {
        item[key[0].toUpperCase() + key.slice(1)] = value;
        return item;
      }, {});

      if(data.ticketId == this.data.ticketId) {

        this.data.messageList.push(newData);
      }

      if(data.message) formData.append("Message", data.message.trim());
      if(data.attachedDocument) formData.append("AttachedDocument", data.attachedDocument);

      this.scrollChatToBottom();
    },
    updateChatMessageByPatient(data){
      let formData = new FormData();
      let newData = {};
      for (let key in data) {
          newData[key[0].toUpperCase() + key.slice(1)] = data[key]; 
      }
      // Obtendo a data e hora atuais, ajustando para o fuso horário local e formatando no estilo ISO
      const tzoffset = (new Date()).getTimezoneOffset() * 60000;
      newData.DateAndTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0,19);

      if(data.ticketId == this.data.ticketId) this.data.messageList.push(newData);
      if(data.message) formData.append("Message", data.message.trim());
      if(data.attachedDocument) formData.append("AttachedDocument", data.attachedDocument);

      this.scrollChatToBottom();
    },
    handleOpenTransferencias() {
      this.showTransferenciasComponent = true;
    },
    startChatSignalR(){

      try{
        if(this.chatSignalR.connection != null) return true;

        this.chatSignalR.connection = this.$SignalRConnector.startSignalR("messageprofessionalhub", (connection) => {
          this.startChatSignalRListeners(connection);
        }, this.signalRTimeout, String(this.userId));
      }

      catch(erro) {
          console.log("Erro na comunicação com o SignalR: " + erro);

          if(this.chatSignalR.retryCount < this.signalRMaxRetry){
            ++this.chatSignalR.retryCount;

            this.startChatSignalR();
          }
        }
    },

    async startChatSignalRListeners(connection){

          connection.on("PatientMessage", (res) => {
            this.loadingSignalR = true;
            this.updateChatMessageByPatient(res);
            this.loadingSignalR = false;
          });

          connection.on("ProfessionalMessage", (chat, message) => {
            this.loadingSignalR = true;
            this.updateChatMessageByProfessional(message);
            this.loadingSignalR = false;
          });

        } 
  }
};
</script>

<style>
  @import '~@assets/css/views/chat.css';
</style>
