<template>
  <div class="doneAction">
    <div class="doneAction-content">
      <h2>{{ card.title }}</h2>

      <div class="form-group">
        <label for="prazo">Prazo</label>
        <div class="input-box">
          <p>{{ $Utilities.formatDateWithTime(card.dueDate) }}</p>
        </div>
      </div>

      <div class="form-group">
        <label for="conclusao">Conclusão</label>
        <div class="input-box">
          <p>{{ $Utilities.formatDate(card.conclusionDate, "YYYY-MM-DD", "DD/MM/YYYY") }}</p>
        </div>
      </div>

      <div class="form-group">
        <label for="responsavel">Responsável</label>
        <div class="input-box">
          <div class="dispose-itens-horizontally">
            <img
              v-show="card.assignedProfessional?.Id"
              :src="
                card.assignedProfessional?.Photo ||
                  require('@/assets/images/default_avatar_neutral.png')
              "
              class="circular-mask small-image-25 is-cursor-pointer"
            >
            <p>
              {{ card.assignedProfessional?.Name }}
            </p>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="desfecho">Desfecho</label>
        <div class="textarea-box">
          <p>{{ card.actionOutcomeObservations }}</p>
        </div>
      </div>

      <button
        class="btn-close"
        @click="closeModal"
      >
        Fechar
      </button>
    </div>
  </div>
</template>
  
  <script>
export default {
  props: {
    card: {
      type: Object,
      required: false,
      default: () => ({}) // Default to an empty object
    }
  },
  emits: ['close'],
  methods: {
    closeModal() {
        this.$emit("close");
    }
  }
};
  </script>
  
  <style scoped>

.doneAction{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);

}

.doneAction-content {
  max-width: 600px;
  min-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.doneAction-content .form-group {
  margin-bottom: 20px;
}

.doneAction-content p{
    font-size: 14px;
}

.doneAction-content h2{
    font-size: 20px;
    margin-bottom: 20px;
}

.doneAction-content label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}

.doneAction-content .input-box {
  padding: 10px;
  background-color: #e9e9e9;
  border-radius: 4px;
  min-height: 45px;
}

.doneAction-content .textarea-box {
  padding: 10px;
  background-color: #e9e9e9;
  border-radius: 4px;
  min-height: 80px;
}

.doneAction-content .btn-close {
  background-color: #6a1b9a;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.doneAction-content .btn-close:hover {
  background-color: #4a148c;
}
</style>
  