<template>
  <div
    class="modal modal-tutorial"
    :class="activeModal ? 'is-active' : ''"
  >
    <div class="modal-background" />
        
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title title">
          Tutorial
        </p>
        <button
          class="delete"
          aria-label="close"
          @click="closeModal()"
        />
      </header>

      <section class="modal-card-body">
        <figure class="image">
          <img :src="tutorialControl.currentImg">
        </figure>
      </section>

      <footer class="modal-card-foot">
        <button 
          class="arrow-model2 left" 
          :disabled="tutorialControl.current == 1"
          @click="prevTutorialImg()"
        />

        <button 
          class="arrow-model2 right" 
          :disabled="tutorialControl.current == tutorialControl.max"
          @click="nextTutorialImg()"
        />
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeModal: false,
      tutorialControl: {
        current: 1,
        max: 25,
        currentImg: ""
      }
    };
  },
  created(){
    this.updateTutorial();
  },
  methods: {
    updateTutorial(){
      this.tutorialControl.currentImg = require(`@assets/tutorial/${this.tutorialControl.current}.png`);
    },
    prevTutorialImg(){
      --this.tutorialControl.current;

      this.updateTutorial();
    },
    nextTutorialImg(){
      ++this.tutorialControl.current;

      this.updateTutorial();
    },
    openModal(){
      this.tutorialControl.current = 1;
      this.updateTutorial();

      this.activeModal = true;
    },
    closeModal(){
      this.activeModal = false;
    }
  }
};
</script>

<style>
    @import '~@assets/css/components/tutorial.css';
</style>