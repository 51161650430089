<template>
  <form
    v-show="data.ticketId !== null || openChat"
    ref="formChatMessage"
    class="messages-bottom"
    @submit.prevent="sendMessage()"
  >
    <div
      v-show="chatOwner"
      class="send-message"
    >
      <button
        type="button"
        class="borderless btn-attachment"
        :class="data.file.content ? 'attached-file' : ''"
        @click="openFolderDirectory"
      >
        <lila-svgIcon :src="require(`@assets/icons/attachment.svg`)" />

        <input
          ref="fileInputRef"
          type="file"
          class="file-input"
          @change="handleFileUpload"
        >
      </button>

      <lila-input
        v-model="data.message"
        placeholder="Digite uma mensagem"
        :validation="data.message"
      />

      <button
        type="submit"
        class="borderless button btn-send-message"
        :class="sendingMessage ? 'is-loading' : ''"
      >
        <lila-svgIcon
          v-show="!sendingMessage"
          :src="require(`@assets/icons/send_message.svg`)"
        />
      </button>
    </div>
  </form>
</template>

<script setup>
import { ref, computed } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import LilaInput from "@components/shared/fields/Input.vue";
import LilaSvgIcon from "@components/shared/images/SvgIcon.vue";
import { Utilities } from "../../plugins/Utilities.js";
import axios from "../../js/axios";
import { Session } from "../../plugins/Session";
import { userSessionName } from "../../js/axios";
import { eventBus } from "../../eventBus.js";

const p = defineProps({
  data: {
    message: {
      type: String,
      default: ""
    }
  },
  openChat: Boolean,
  chatOwner: Boolean,
  loading: Boolean,
  alert: {
    message: null,
    type: null,
    show: false
  },
  scrollChatToBottom: {
    type: Function,
    required: true
  },
  setChatAttributes: {
    type: Function,
    required: true
  },
  openTransferToProfessionalModal: {
    type: Function,
    required: true
  }
});

const sendingMessage = ref(false);
const fileInputRef = ref(); 

const rules = computed(() => {
  return {
      data: {
        message: {
          required: required
        }
      }
  };
});

const v = useVuelidate(rules, p.data.message);

function openFolderDirectory() {
    fileInputRef.value.click();
}

async function handleFileUpload(event) {
  p.alert.show = false;

  const file = event.target.files[0];

  if (verifyFileExtension(file)) { 
    p.data.file.content = file;
    p.data.file.title = file.name;
  } else {
    p.data.file.content = null;
    p.data.file.title = "";

    Utilities.controlAlert(null, p.alert, "warning", "É permitido apenas upload de arquivos no formato jpeg, jpg, png e pdf.");
  }
}

function verifyFileExtension(file) {
  return ["image/jpeg", "image/png", "application/pdf"].includes(file.type);
}

function resetFileInput(){
  p.data.file.title = "";
  p.data.file.content = "";
  if (fileInputRef.value) {
    fileInputRef.value.value = "";
  }
}

async function sendMessage() {
  p.alert.show = false;

  v.value.$touch();

  if(!p.data.message && !p.data.file.content) return; 

  sendingMessage.value = true;

  let formData = new FormData();
  let messageEndpoint = "";

  if(p.data.ticketId == null){
    messageEndpoint = `api/chat/withmessage/patient/${p.data.patientId}`;
    formData.append("Priority", "Normal");
  } else {
      messageEndpoint = `api/chatmessage/chat/${p.data.ticketId}`;
  }

  if(p.data.message) formData.append("Message", p.data.message.trim());
  if(p.data.file.content) formData.append("AttachedDocument", p.data.file.content);

  p.loading = true;

  try {
    const res = await axios({
      method: "POST",
      url: messageEndpoint,
      data: formData,
      headers: {
        "Authorization": `bearer ${Session.get(userSessionName).token.Value}`,
        "Content-Type": "multipart/form-data"
      }
    });

    if(p.data.ticketId == null){
      p.setChatAttributes(res.data);
      eventBus.emit("openTicket", res.data.Id);
    }else{
      p.data.messageList.push(res.data);
    }

    p.data.message = "";
    resetFileInput();

    v.value.$reset();

    p.scrollChatToBottom();

  } catch (error) {
    if (error && error.response && error.response.status) {
      console.error(error);
      Utilities.verifyToken(error.response.status);
      Utilities.controlAlert(error.response.data, p.alert, "danger", "Ocorreu um erro, tente novamente mais tarde!");
    }
  }

  sendingMessage.value = false;
}

</script>