<template>
  <div
    class="clinical-evolution"
    :class="loadingContent ? 'loading large' : ''"
  >
    <div v-show="!loadingContent">
      <h1 class="title is-sr-only">
        Relato Semanal
      </h1>

      <lila-message :alert-data="alert" />

      <div class="columns">
        <div
          id="suportColumn"
          class="column is-7"
        />
        <div class="column is-1" />
        <div class="column is-4" />
      </div>

      <div class="columns">
        <div class="column is-7">
          <div class="box-column-title">
            <h2 class="column-title">
              Relato Semanal
            </h2>

            <lila-export-button
              v-if="data.chartData.datasets[0].data.length > 0"
              :api-url="`/api/weeklyreport/export/${data.patientId}`"
              :alert="alert"
            />
          </div>

          <h3>Performance Status (PS)</h3>

          <div
            v-if="data.chartData.datasets[0].data.length > 0"
            id="chartWrapper"
            ref="chartWrapper"
            class="chart-wrapper"
          >
            <h4>Evolução de Performance Status (PS)</h4>

            <div class="chart-container custom-scroll">
              <lila-chart
                v-if="data.chartDataLoaded"
                id="weeklyReportChart"
                ref="weeklyReportChart"
                :data="data.chartData"
                :options="data.chartOptions"
              />
            </div>
          </div>

          <article
            v-show="data.chartData.datasets[0].data.length == 0"
            class="message is-warning"
          >
            <div class="message-body">
              O paciente não cadastrou nenhum relato semanal.
            </div>
          </article>

          <div
            v-if="data.selectedWeeklyReportDetail"
            class="box-record-detail"
          >
            <h3>Qualidade de Vida</h3>

            <p class="report-date">
              Data: <time>{{ $Utilities.formatDateWithTime(data.selectedWeeklyReportDetail.DateAndTime, 'YYYY-MM-DDTHH:mm:ss', 'DD/MM/YYYY HH:mm') }}</time>
            </p>

            <div class="record-detail">
              <ul>
                <li
                  v-for="report in data.selectedWeeklyReportDetail.ReportRequests"
                  :key="report.WeeklyReportItem.Item"
                >
                  <lila-svgIcon :src="icons[report.Intensity - 1]" />
                  <div>
                    <p class="symptom-title">
                      {{ report.WeeklyReportItem.Item }}
                    </p>
                    <p>{{ getReportDescription(report) }}</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <!-- INÍCIO: TERMÕMETRO DE DISTRESS -->
          <div v-if="hasAccessToDistressTermometerFeature()">
            <h3 id="distress-termometer-graph">
              Termômetro de distress
            </h3>

            <div
              v-if="data.chartData.datasets[0].data.length > 0"
              id="chartWrapper"
              ref="chartWrapper"
              class="chart-wrapper"
            >
              <h4>Evolução do nível de angústia</h4>

              <div class="chart-container custom-scroll">
                <lila-chart
                  v-if="data.distressTermometerChartDataLoaded"
                  id="distressTermometerChart"
                  ref="distressTermometerChart"
                  :data="data.distressTermometerChartData"
                  :options="data.distressTermometerChartOptions"
                />
              </div>
            </div>
          </div>
          <!-- FIM: TERMÕMETRO DE DISTRESS -->
        </div>

        <div class="column is-1 middle-column">
          <div class="line" />
        </div>

        <div class="column is-4">
          <h2 class="column-title">
            Como o Paciente está se sentindo (Sintomas)
          </h2>

          <lila-export-button
            v-if="data.symptomsList.length > 0"
            :api-url="`/api/clinicalevolution/export/${data.patientId}`"
            :alert="alert"
          />

          <article
            v-show="data.symptomsList.length == 0"
            class="message is-warning"
          >
            <div class="message-body">
              O paciente não cadastrou nenhum sintoma.
            </div>
          </article>

          <div
            v-show="data.symptomsList.length > 0"
            ref="symptomList"
            class="symptom-list custom-scroll"
            @scroll="symptomsScroll"
          >
            <template v-if="data.symptomsList?.length > 0">
              <template
                v-for="(symptom, index) in data.symptomsList"
                :key="`symptom_${index}`"
              >
                <symptom-item :symptom="symptom" />
              </template>
            </template>
            <!-- <div v-html="symptomsList" /> -->

            <div
              v-show="loadingInfiniteScroll"
              class="loading small"
            />
            <div
              v-show="pagination.lastPage"
              class="last-page has-text-centered"
            >
              - - -
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@components/shared/images/SvgIcon.vue";
import Chart from "@components/shared/lila/Chart.vue";
import ExportButton from "@components/shared/lila/ExportButton.vue";
import Message from "@components/shared/message/Message.vue";
import SymptomItem from "@components/symptoms/SymptomItem.vue";
import image0 from "@assets/images/weekly_report_level_pain_0.png";
import image1 from "@assets/images/weekly_report_level_pain_1.png";
import image2 from "@assets/images/weekly_report_level_pain_2.png";
import image3 from "@assets/images/weekly_report_level_pain_3.png";
import image4 from "@assets/images/weekly_report_level_pain_4.png";
import svg1 from "@assets/icons/weekly_report_level_pain_1.svg";
import svg2 from "@assets/icons/weekly_report_level_pain_2.svg";
import svg3 from "@assets/icons/weekly_report_level_pain_3.svg";
import { WeeklyReportItemTypes } from '@/enums/WeeklyReportItemTypes.js';

export default {
  components: {
    "lila-svgIcon": SvgIcon,
    "lila-chart": Chart,
    "lila-export-button": ExportButton,
    "lila-message": Message,
    SymptomItem,
    WeeklyReportItemTypes
  },
  data() {
    return {
      data: {
        patientId: this.$route.params.id,
        symptomsList: [],
        weeklyReportList: [],
        selectedWeeklyReportDetail: null,
        chartDataLoaded: false,
        chartData: {
          labels: [],
          datasets: [
            {
              label: "",
              backgroundColor: "transparent",
              borderColor: "#9A397D",
              borderWidth: 1,
              pointBackgroundColor: [],
              pointBorderColor: "transparent",
              pointRadius: 8,
              pointHoverRadius: 9,
              lineTension: 0,
              hitRadius: 8,
              pointStyle: [],
              data: []
            },
          ]
        },
        distressTermometerChartData: {
          labels: [],
          datasets: [
            {
              label: "",
              backgroundColor: "transparent",
              borderColor: "#9A397D",
              borderWidth: 1,
              pointBackgroundColor: [],
              pointBorderColor: "transparent",
              pointRadius: 8,
              pointHoverRadius: 9,
              lineTension: 0,
              hitRadius: 8,
              pointStyle: [],
              data: []
            },
          ]
        },
        distressTermometerChartDataLoaded: false,
        chartOptions: {
          onClick: this.selectWeeklyReport,
          onHover: function (e, chartElement) {
            e.native.target.style.cursor = chartElement[0] ? "pointer" : "default";
          },
          plugins: {
            legend: {
              display: false
            },

            tooltip: {
              enabled: false
            }
          },
          responsive: true,
          layout: {
            padding: {
              left: 25
            }
          },
          maintainAspectRatio: false,
          chartArea: {
            backgroundColor: "red"
          },
          scales: {
            y: {
              gridLines: {
                color: "#DFDFDF"
              },
              reverse: true,
              min: 0,
              max: 4,
              ticks: {
                autoSkip: false,
                font: {
                  size: 14,
                  family: "Montserrat",
                  color: "#2C2C2C"
                },
                precision: 0
              },
              scaleLabel: {
                align: "start"
              }
            },
            x: {
              gridLines: {
                color: "#DFDFDF"
              },
              ticks: {
                font: {
                  size: 14,
                  family: "Montserrat",
                  color: "#2C2C2C"
                },
                backdropColor: "black"
              }
            }
          }
        },
        distressTermometerChartOptions: {
          onHover: function (e, chartElement) {
            e.native.target.style.cursor = chartElement[0] ? "pointer" : "default";
          },
          plugins: {
            legend: {
              display: false
            },

            tooltip: {
              enabled: false
            }
          },
          responsive: true,
          layout: {
            padding: {
              left: 25
            }
          },
          maintainAspectRatio: false,
          chartArea: {
            backgroundColor: "red"
          },
          scales: {
            y: {
              gridLines: {
                color: "#DFDFDF"
              },
              reverse: true,
              min: 0,
              max: 10,
              ticks: {
                autoSkip: false,
                font: {
                  size: 14,
                  family: "Montserrat",
                  color: "#2C2C2C"
                },
                precision: 0
              },
              scaleLabel: {
                align: "start"
              }
            },
            x: {
              gridLines: {
                color: "#DFDFDF"
              },
              ticks: {
                font: {
                  size: 14,
                  family: "Montserrat",
                  color: "#2C2C2C"
                },
                backdropColor: "black"
              }
            }
          }
        }


      },
      adjustChart: true,
      showChatArrow: false,
      loadingContent: false,
      loadingInfiniteScroll: false,
      alert: {
        message: "",
        type: "",
        show: false
      },
      pagination: {
        current: 0,
        totalPages: 0,
        perPage: 5,
        lastPage: false
      },
      images: {
        0: image0,
        1: image1,
        2: image2,
        3: image3,
        4: image4
      },
      icons: {
        0: svg1,
        1: svg2,
        2: svg3
      }
    };
  },
  created() {
    this.getContent();
  },
  methods: {
    symptomsScroll() {
      if (this.$refs.symptomList.scrollTop + this.$refs.symptomList.clientHeight >= this.$refs.symptomList.scrollHeight) {
        this.getSymptomsListInfiniteScroll();
      }
    },
    getContent() {
      this.loadingContent = true;

      Promise
        .all([
          this.getWeeklyReport(),
          this.getSymptomsList()
        ])
        .catch(error => {
          console.error(error);
          this.$Utilities.verifyToken(error.response.status);
          this.$Utilities.controlAlert(error.response.data, this.alert, "danger");
        })
        .finally(() => this.loadingContent = false);
    },
    getSymptomsList(page = 1) {
      return this.$axios
        .get(
          `/api/clinicalevolution/patient/${this.data.patientId}?page=${page}&limit=${this.pagination.perPage}`,
          { headers: { "Authorization": `bearer ${this.$Session.get(this.$userSessionName).token.Value}` } }
        )
        .then((res) => {
          if (res.data.Pagination.TotalPage == res.data.Pagination.CurrentPage) this.pagination.lastPage = true;

          this.pagination.current = res.data.Pagination.CurrentPage;
          this.pagination.totalPages = res.data.Pagination.TotalPage;

          res.data.Data.forEach(item => this.data.symptomsList.push(item));
        });
    },
    getSymptomsListInfiniteScroll() {
      if (this.loadingContent || this.loadingInfiniteScroll || this.pagination.lastPage) return;

      this.loadingInfiniteScroll = true;

      this.scrollSymptomsListToBottom();

      Promise
        .all([
          this.getSymptomsList(this.pagination.current + 1)
        ])
        .catch(error => {
          this.$Utilities.verifyToken(error.response.status);
          this.$Utilities.controlAlert(error.response.data, this.alert, "danger");
        })
        .finally(() => this.loadingInfiniteScroll = false);
    },
    scrollSymptomsListToBottom() {
      setTimeout(function ($this) {
        $this.$refs.symptomList.scrollTo(0, $this.$refs.symptomList.scrollHeight);
      }, 50, this);
    },
    getWeeklyReport() {
      return this.$axios
        .get(
          `/api/weeklyreport/patient/${this.data.patientId}`,
          { headers: { "Authorization": `bearer ${this.$Session.get(this.$userSessionName).token.Value}` } }
        )
        .then((res) => {
          this.data.weeklyReportList = res.data;

          res.data.forEach(item => {
            let myImage = new Image(40, 40);
            let pointColorList = [
              "#46DBD4",
              "#46DB8C",
              "#F5CF13",
              "#DBAE46",
              "#DB4646"
            ];

            // myImage.src = require(`@assets/images/weekly_report_level_pain_${item.PS}.png`);
            myImage.src = this.images[item.PS];

            this.data.chartData.labels.push(this.$Utilities.formatDateWithTime(item.DateAndTime, "YYYY-MM-DDTHH:mm:ss", "DD/MM"));
            this.data.chartData.datasets[0].pointBackgroundColor.push(pointColorList[item.PS]);
            this.data.chartData.datasets[0].data.push(item.PS);
            this.data.chartDataLoaded = true;

            this.fillDistressTermometerChart(item);
          });
        });
    },

    fillDistressTermometerChart(item) {
      let pointColorList = [
        "#46DBD4",
        "#3EDBC0",
        "#46DB8C",
        "#3EDB6E",
        "#34DB50",
        "#F5CF13",
        "#FDBF30",
        "#DBAE46",
        "#DB8E46",
        "#DB6A46",
        "#DB4646"
      ];


      let distressReport = item.ReportRequests.find(report => report.WeeklyReportItem.Id == WeeklyReportItemTypes.NIVEL_DE_ANGUSTIA);
      if (distressReport) {
        this.data.distressTermometerChartData.labels.push(this.$Utilities.formatDateWithTime(item.DateAndTime, "YYYY-MM-DDTHH:mm:ss", "DD/MM"));

        this.data.distressTermometerChartData.datasets[0].data.push(distressReport.Intensity);
        this.data.distressTermometerChartData.datasets[0].pointBackgroundColor.push(pointColorList[distressReport.Intensity]);
        this.data.distressTermometerChartDataLoaded = true;
      }
    },

    changeChartWidth() {
      if (this.adjustChart) this.showSelectedWeeklyReport(this.data.chartData.datasets[0].data.length - 1);

      if (this.adjustChart && this.data.chartData.datasets[0].data.length > 6) {
        this.$refs.chartWrapper.querySelector(".chart-container > div").style.width = `${this.data.chartData.datasets[0].data.length * 80}px`;

        setTimeout(function ($this) {
          $this.$refs.chartWrapper.querySelector(".chart-container").scrollTo($this.$refs.chartWrapper.querySelector(".chart-container").scrollWidth, 0);
        }, 250, this);
      }
      this.adjustChart = false;
    },
    selectWeeklyReport(point, event) {
      if (event[0]) {
        this.showSelectedWeeklyReport(event[0].index);

        this.data.chartData.labels.forEach((item, index) => {
          // eslint-disable-next-line no-useless-escape
          this.data.chartData.labels[index] = this.data.chartData.labels[index].replace(/[\[ \]]/g, "");
        });
        this.data.chartData.labels[event[0].index] = `[ ${this.data.chartData.labels[event[0].index]} ]`;
        this.$refs.weeklyReportChart.update();
        this.$refs.distressTermometerChart.update();
      }

    },
    showSelectedWeeklyReport(index) {
      this.data.selectedWeeklyReportDetail = this.data.weeklyReportList[index];
    },
    getReportDescription(report) {
      let description = {
        mobilidade: [
          "Não tenho problemas em andar",
          "Tenho alguns problemas em andar",
          "Estou limitado a ficar na cama"
        ],
        cuidadosPessoais: [
          "Não tenho problemas comos meus cuidados pessoais",
          "Tenho alguns problemas para me lavar ou me vestir",
          "Sou incapaz de me lavar ou vestir sozinho"
        ],
        atividadesHabituais: [
          "Não tenho problemas em desempenhar as minhas atividades habituais",
          "Tenho alguns problemas em desempenhar as minhas atividades habituais",
          "Sou incapaz de desempenhar as minhas atividades habituais"
        ],
        dorDesconforto: [
          "Não tenho dores ou desconforto",
          "Tenho dores ou desconforto moderados",
          "Tenho dores ou desconforto extremos "
        ],
        ansiedadeDepressao: [
          "Não estou ansioso(a) ou deprimido(a)",
          "Estou moderadamente ansioso(a) ou deprimido(a)",
          "Estou extremamente ansioso(a) ou deprimido(a)"
        ]
      };

      switch (report.WeeklyReportItem.Id) {
        case WeeklyReportItemTypes.MOBILIDADE:
          return description.mobilidade[report.Intensity - 1];

        case WeeklyReportItemTypes.CUIDADOS_PESSOAIS:
          return description.cuidadosPessoais[report.Intensity - 1];

        case WeeklyReportItemTypes.ATIVIDADES_HABITUAIS:
          return description.atividadesHabituais[report.Intensity - 1];

        case WeeklyReportItemTypes.DOR_DESCONFORTO:
          return description.dorDesconforto[report.Intensity - 1];

        case WeeklyReportItemTypes.ANSIEDADE_DEPRESSAO:
          return description.ansiedadeDepressao[report.Intensity - 1];

        case WeeklyReportItemTypes.NIVEL_DE_ANGUSTIA:
          return report.Intensity;
        default:
          return false;
      }
    },
    hasAccessToDistressTermometerFeature(){
      return this.$Utilities.hasAccessToFeature(this, 9);
    }
  }
};
</script>

<style scoped>
@import "~@assets/css/views/clinicalEvolution.css";
</style>
