<template>
  <lila-default-template>
    <template #mainContent>
      <div class="container who-we-are">
        <div class="columns">
          <div class="column is-5">
            <section class="hero is-fullheight">
              <div class="hero-body">
                <div class="container">
                  <h1 class="title">
                    Bem-vindo ao Lila!
                  </h1>

                  <p>Estamos aqui para facilitar a comunicação entre equipes de saúde e pacientes, agilizando as interações e promovendo uma jornada de confiança mútua. Ajudamos as equipes de saúde a gerenciar as solicitações diárias de seus pacientes e simplificamos aos pacientes registrar seus sintomas e organizar suas demandas de cuidado.</p>
                  <br>
                                    
                  <p class="our-mission">
                    <b>Esta é a nossa missão:</b><br> Facilitar a vida de quem cuida e de quem busca cuidado.
                  </p>
                  <br>
                  <!--
                                    <p>Telefone <br> <strong>{{ data.telefone }}</strong></p>
                                    <br>
                                    -->
                  <p>Email <br> <strong>{{ data.email }}</strong></p>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </template>
  </lila-default-template>
</template>

<script>
import DefaultTemplate from "@components/template/DefaultTemplate.vue";

export default {
  components: {
    "lila-default-template": DefaultTemplate
  },
  data() {
    return {
      data: {
        telefone: "(11) 9999-9999",
        email: "contato@lilasaude.com.br"
      }
    };
  },        
  methods: {            
            
  }
};
</script>

<style>
    @import '~@assets/css/views/whoWeAre.css';
</style>