import { signalApi } from "../js/axios";
import { HubConnectionState } from "@microsoft/signalr";

let signalR = require("@microsoft/signalr");

export const SignalRConnector = {
    async startSignalR(endpoint, startListenersCallback, timeout, userId, base_url = signalApi) {
      

        const connection = new signalR.HubConnectionBuilder()
            .withUrl(`${base_url}/${endpoint}`)
            .withAutomaticReconnect([0, 5000, 10000, 15000, 20000])
            .configureLogging(signalR.LogLevel.Debug)
            .build();

        await this.setupConnection(connection, userId);

        if (startListenersCallback) {
            setTimeout(() => {
              startListenersCallback(connection);
            }, timeout, this);

            connection.onreconnected(async () => {
                console.log("Reconnected to SignalR hub");
                await this.setupConnection(connection, userId);
            });
        }

        return connection;
    },

    async setupConnection(connection, userId){
      if( !connection.state || connection.state !== HubConnectionState.Connected){

        await connection.start().then(() => {
          connection.invoke("getConnectionId");
          connection.invoke("SetUser", userId);
        });
        }

        else{
          connection.invoke("getConnectionId");
          connection.invoke("SetUser", userId);
        }

    },

    stopSignalR(connection) {
        if (connection
            && connection != null
            && typeof connection.state != "undefined"
            && connection.state != signalR.HubConnectionState.Disconnected) connection.stop();
    }
};

export default function (app) {
    app.config.globalProperties.$SignalRConnector = SignalRConnector;
}