<template>
  <lila-login-template>
    <template #mainContent>
      <form
        class="login-form full-width"
        @submit.prevent="submitForm()"
      >
        <h1 class="title">
          Recuperar senha
        </h1>

        <lila-message :alert-data="alert" />

        <p class="bottom-gap">
          Caso seu <strong>e-mail</strong> esteja cadastrado, enviaremos para ele as instruções de recuperação da sua senha.
        </p>

        <lila-input
          v-model="v.data.email.$model"
          placeholder="Digite seu E-mail"
          :validation="v.data.email"
        />

        <lila-button
          type="submit"
          title="Confirmar"
          class="submit-buttons"
          :is-centralized="true"
          :second-button="{type: 'routeText', route: 'login', title: 'Realizar login'}"
          :loading="loading"
        />
      </form>
    </template>
  </lila-login-template>
</template>

<script>
import LoginTemplate from "@components/template/LoginTemplate.vue";

import Input from "@components/shared/fields/Input.vue";
import Button from "@components/shared/button/Button.vue";
import Message from "@components/shared/message/Message.vue";

import { required, email, maxLength } from "@vuelidate/validators";

export default {
  components: {
    "lila-input": Input,
    "lila-button": Button,
    "lila-message": Message,
    "lila-login-template": LoginTemplate
  },
  data() {
    return {
      data: {
        email: ""
      },
      alert: {
        message: "",
        type: "",
        show: false
      },
      loading: false
    };
  },
  validations() {
    return {
      data: {
        email: {
          required,
          email,
          maxLength: maxLength(255)
        }
      }
    };
  },
  methods: {
    submitForm(){
      this.v.$touch();

      if(this.v.$invalid) return;

      this.loading = true;

      this.$axios
        .post("api/account/forgot-password", this.data)
        .then(() => this.$Utilities.controlAlert(null, this.alert, "forgotPassword"))
        .catch(error => this.$Utilities.controlAlert(error.response.data, this.alert, "danger"))
        .finally(() => this.loading = false);
    }
  }
};
</script>

<style></style>
