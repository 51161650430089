<template>
  <lila-login-template>
    <template #mainContent>
      <form
        class="login-form full-width"
        @submit.prevent="handleSubmit"
      >
        <h1 class="title">
          Bem Vindo!
        </h1>

        <lila-message :alert-data="alert" />

        <lila-input
          v-model="v.data.login.$model"
          placeholder="Login"
          :validation="v.data.login"
        />

        <lila-input
          v-model="v.data.password.$model"
          placeholder="Senha"
          type="password"
          :validation="v.data.password"
        />

        <lila-button
          type="routeText"
          path="forgotPassword"
          class="forgot-password"
          button-icon="lock.svg"
          title="Esqueci a senha"
        />

        <lila-button
          type="submit"
          title="Entrar"
          class="submit-buttons"
          :is-centralized="true"
          :second-button="{
            type: 'externalLink',
            route: 'https://bit.ly/lila-cadastro-profissional',
            title: 'Quero me Cadastrar',
          }"
          :loading="loading"
        />
      </form>
    </template>
  </lila-login-template>
</template>

<script>
import { required, email, maxLength } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import LoginTemplate from "@components/template/LoginTemplate.vue";
import Input from "@components/shared/fields/Input.vue";
import Button from "@components/shared/button/Button.vue";
import Message from "@components/shared/message/Message.vue";

export default {
  components: {
    "lila-input": Input,
    "lila-button": Button,
    "lila-message": Message,
    "lila-login-template": LoginTemplate,
  },
  setup() {
    const v = useVuelidate();
    return { v };
  },
  data() {
    return {
      data: {
        login: "",
        password: "",
        manterConectado: false,
      },
      alert: {
        message: "",
        type: "",
        show: false,
      },
      loading: false,
    };
  },
  validations() {
    return {
      data: {
        login: { required, email, maxLength: maxLength(255) },
        password: { required, maxLength: maxLength(255) },
      },
    };
  },
  created() {
    this.checkRouteParamsForMessages();
  },
  methods: {
    handleSubmit() {
      this.alert.show = false;
      this.v.$touch();

      if (this.v.$invalid) return;

      this.loading = true;

      this.loginUser()
        .then(() => this.fetchInstitutionLogo())
        .then(() => this.redirectUser())
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    checkRouteParamsForMessages() {
      const currentUrl = new URLSearchParams(window.location.search);

      if (this.$Utilities.hasProperty(this.$route.params, "message")) {
        this.$Utilities.controlAlert(
          null,
          this.alert,
          this.$route.params.message
        );
      }

      if (currentUrl.get("passwordCreated") != null) {
        this.$Utilities.controlAlert(null, this.alert, "firstPasswordCreated");
      }
    },

    async loginUser() {
        const response = await this.$axios.post("api/account/login", this.data);
        this.storeSessionData(response.data);
    },

    storeSessionData(data) {
      let profile = "";

      if (typeof data.Professional !== "undefined") {
        profile = `professional_${data.Professional.Profile.toLowerCase()}`;
        data.Professional.Doctor.BirthDate = data.Professional.Doctor.BirthDate
          ? this.$Utilities.formatDate(
              data.Professional.Doctor.BirthDate,
              "MM/DD/YYYY",
              "DD/MM/YYYY"
            )
          : "";
      } else {
        profile = "doctor";
        data.Doctor.BirthDate = data.Doctor.BirthDate
          ? this.$Utilities.formatDate(
              data.Doctor.BirthDate,
              "MM/DD/YYYY",
              "DD/MM/YYYY"
            )
          : "";
      }

      this.$Session.set(this.$userSessionName, {
        token: data.Token,
        doctor:
          typeof data.Professional !== "undefined"
            ? data.Professional.Doctor
            : data.Doctor,
        professional:
          typeof data.Professional !== "undefined" ? data.Professional : null,
        profile: profile,
        isTrial: data.Professional.IsTrial,
        trial: data.Professional.TrialRemainingDays,
      });
    },

    async fetchInstitutionLogo() {
      const token = this.$Session.get(this.$userSessionName).token.Value;

      try {
        const response = await this.$axios.get("/api/InstitutionLogo", {
          headers: { Authorization: `Bearer ${token}` },
        });

        const logoUrl = response.data || require("@/assets/images/logo-lila.png");

        // Update the session to include the logo
        const sessionData = this.$Session.get(this.$userSessionName);
        sessionData.logoUrl = logoUrl;
        this.$Session.set(this.$userSessionName, sessionData);
      } catch (error) {
        console.error("Error fetching institution logo:", error);

        // Use default logo if API call fails
        const sessionData = this.$Session.get(this.$userSessionName);
        sessionData.logoUrl = require("@/assets/images/logo-lila.png");
        this.$Session.set(this.$userSessionName, sessionData);
      }
    },

    redirectUser() {
      this.$router.push({ name: "completeYourRegistration" });
    },

    handleError(error) {
      if (error.response && error.response.data) {
        this.$Utilities.controlAlert(
          error.response.data,
          this.alert,
          "danger"
        );
      } else {
        this.alert = {
          message: "Ocorreu um erro inesperado.",
          type: "danger",
          show: true,
        };
      }
    },
  },
};
</script>

<style>
/* Your styles here */
</style>
