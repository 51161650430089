<template>
  <nav
    v-if="paginationConfig.current > 0"
    class="pagination is-centered"
    role="navigation"
    aria-label="pagination"
  >
    <a
      v-if="paginationConfig.current > 1"
      class="pagination-previous"
      @click="changePage(paginationConfig.current - 1)"
    >Página Anterior</a>

    <a
      v-if="paginationConfig.current != paginationConfig.totalPages && paginationConfig.totalPages != 0"
      class="pagination-next"
      @click="changePage(paginationConfig.current + 1)"
    >Próxima Página</a>

    <ul class="pagination-list">
      <li
        v-for="(page, index) in pages"
        :key="index"
      >
        <span
          v-if="page == 'blank'"
          class="pagination-ellipsis"
        >&hellip;</span>
        <a
          v-else
          class="pagination-link"
          :class="paginationConfig.current == page ? 'is-current' : ''"
          aria-label="Pagina"
          @click="changePage(page)"
        >{{ page }}</a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    paginationConfig: {
      required: true,
      type: Object,
      default: () => {
        return {
          current: 0,
          totalPages: 0,
          perPage: 10
        };
      }
    }
  },
  emits: ["customChangePageEvent"],
  computed: {
    pages(){
      let pages = [];
      let range = 3;

      if(this.paginationConfig.totalPages <= 4){
        for(let page = 1; page <= this.paginationConfig.totalPages; page++){
          pages.push(page);
        }
      }else{
        if(this.paginationConfig.current <= range - 1){
          //1, 2, 3, ..., 8
          for(let page = 1; page <= range; page++){
            pages.push(page);
          }

          pages.push("blank");
          pages.push(this.paginationConfig.totalPages);
        }else if((this.paginationConfig.current - 1) + range < this.paginationConfig.totalPages){
          //1, ..., 3, 4, 5, ..., 8
          pages.push(1);
          pages.push("blank");

          for(let page = 0; page < range; page++){
            pages.push((this.paginationConfig.current - 1) + page);
          }

          pages.push("blank");
          pages.push(this.paginationConfig.totalPages);
        }else{
          //1, ..., 6, 7, 8
          pages.push(1);
          pages.push("blank");

          for(let page = (this.paginationConfig.totalPages - range); page < this.paginationConfig.totalPages; page++){
            pages.push(page + 1);
          }
        }
      }

      return pages;
    }
  },
  methods: {
    changePage(page){
      this.$emit("customChangePageEvent", page);
    }
  }
};
</script>

<style></style>
