<template>
  <div
    class="field select-component"
    :class="validation.$error ? 'error' : ''"
  >
    <label
      v-if="label"
      class="label"
    >{{ label }}</label>
    <div class="control">
      <div class="select">
        <select
          :value="modelValue !== null ? modelValue.toString() : ''"
          :disabled="readonly"
          @input="handleInput"
          @change="changeEvent"
        >
          <option
            value=""
            class="placeholder"
            :selected="modelValue === null || modelValue === ''"
          >
            {{ placeholder }}
          </option>
          <option
            v-for="item in options"
            :key="item._id"
            :selected="modelValue == item._id"
            :value="item._id"
          >
            {{ item.name }}
          </option>
        </select>
      </div>

      <span
        v-if="validation.$error"
        class="validation-error"
      >
        {{ $Utilities.getErrorMsg(validation) }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: [String, Number, null],
      default: null
    },
    options: {
      required: true,
      type: Array
    },
    label: {
      required: false,
      type: String,
      default: ''
    },
    placeholder: {
      required: false,
      type: String,
      default: "Selecione"
    },
    readonly: {
      required: false,
      default: false,
      type: Boolean
    },
    validation: {
      required: false,
      type: Object,
      default: () => ({
        $error: ""
      })
    },
    error: {
      required: false,
      type: Boolean,
      default: false
    },
    errorMessage: {
      required: false,
      type: String,
      default: ""
    }
  },
  emits: ['customChangeEvent', 'update:modelValue'],
  methods: {
    handleInput(event) {
      const value = event.target.value;
      // Emite apenas o valor para v-model
      this.$emit('update:modelValue', value === '' ? null : value);
    },
    changeEvent(event) {
      const value = event.target.value;
      let processedValue = value;

      if (value === '') {
        processedValue = null;
      } else {
        // Tenta converter para número se possível
        const parsedNumber = Number(value);
        if (!isNaN(parsedNumber) && value === parsedNumber.toString()) {
          processedValue = parsedNumber;
        }
      }

      this.$emit("customChangeEvent", processedValue);
    }
  }
};
</script>

<style>
  @import '~@assets/css/components/select.css';
</style>
