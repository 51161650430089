<template>
  <lila-default-template>
    <template #mainContent>
      <form
        class="container basic-info-form"
        :class="loadingContent ? 'loading large' : ''"
        @submit.prevent="submitForm()"
      >
        <h1 class="title">
          Novo Paciente
        </h1>

        <lila-message :alert-data="alert" />

        <div v-show="!loadingContent">
          <div class="columns">
            <div class="column is-5">
              <p>Essas informações podem ser preenchidas pelo próprio paciente</p>

              <div class="field-group">
                <div class="field-group-header align-title">
                  <p class="field-group-label-type">
                    Dados do Paciente
                  </p>
                </div>

                <div class="field-group-body">
                  <lila-datepicker
                    v-model="v.data.birthdate.$model"
                    placeholder="Data de Nascimento"
                    :validation="v.data.birthdate"
                  />
                </div>
              </div>

              <div class="field-group">
                <div class="field-group-header align-title">
                  <p class="field-group-label-type">
                    Deseja adicionar outro contato (Parente, amigo...)?
                  </p>
                </div>

                <div class="field-group-body">
                  <lila-input
                    v-model="data.relativename"
                    placeholder="Nome do Parente"
                  />

                  <lila-mask
                    v-model="data.relativephone"
                    placeholder="Telefone"
                    :mask="['(##) ####-####', '(##) #####-####']"
                  />
                </div>
              </div>
            </div>

            <div class="column is-2 middle-column">
              <div class="line" />
            </div>

            <div class="column is-5 middle-column">
              <div class="field-group">
                <div class="field-group-header align-title">
                  <p class="field-group-label-type">
                    Endereço
                  </p>
                </div>

                <div class="field-group-body">
                  <lila-mask
                    v-model="data.cep"
                    placeholder="CEP"
                    mask="#####-###"
                    :loading="loadingCep"
                    @keyup="changeCep()"
                  />

                  <lila-input
                    v-model="data.street"
                    placeholder="Endereço"
                  />

                  <lila-input
                    v-model="data.number"
                    placeholder="Número"
                  />

                  <lila-input
                    v-model="data.complement"
                    placeholder="Complemento"
                  />

                  <lila-input
                    v-model="data.neighborhood"
                    placeholder="Bairro"
                  />

                  <lila-input
                    v-model="data.city"
                    placeholder="Cidade"
                  />

                  <lila-select
                    v-model="data.uf"
                    placeholder="Estado"
                    :options="statesList"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="registration-steps has-text-centered">
            <ul class="steps">
              <li />
              <li />
              <li />
              <li class="active" />
            </ul>
          </div>

          <lila-button
            type="submit"
            title="Salvar"
            class="submit-buttons"
            :second-button="{type: 'routeText', route: 'myPatients', title: 'Continuar mais tarde', class: 'dark-lilas'}"
            :loading="loading"
          />
        </div>
      </form>
    </template>
  </lila-default-template>
</template>

<script>
import DefaultTemplate from "@components/template/DefaultTemplate.vue";

import LilaInput from "@components/shared/fields/Input.vue";
import LilaMask from "@components/shared/fields/LilaMask.vue";
import LilaDatepicker from "@components/shared/fields/LilaDatepicker.vue";
import Select from "@components/shared/fields/Select.vue";
import Button from "@components/shared/button/Button.vue";
import Message from "@components/shared/message/Message.vue";

import StatesOptions from "@assets/options/states.json";

const dateLessOrEqualToCurrent = function(value){
  if(value) return this.$moment(value, "DD/MM/YYYY").unix() <= this.$moment.utc().unix();

  return true;
};

export default {
  components: {
    LilaDatepicker,
    "lila-select": Select,
    "lila-button": Button,
    "lila-message": Message,
    "lila-default-template": DefaultTemplate,
    LilaInput,
    LilaMask
  },
  data() {
    return {
      data: {
        id: this.$route.params.id,
        accesscode: "",
        allergy: "",
        attendanceplaceid: 0,
        birthdate: "",
        cep: "",
        cids: [],
        cpf: "",
        city: "",
        comorbidity: "",
        complement: "",
        diagnostic: "",
        email: "",
        gender: "",
        name: "",
        neighborhood: "",
        number: "",
        ps: "",
        phone: "",
        photo: "",
        relativename: "",
        relativephone: "",
        street: "",
        uf: "",
        teamId: 0,
        registernumber: ""
      },
      statesList: StatesOptions.options,
      loadingContent: false,
      loadingCep: false,
      loading: false,
      alert: {
        message: "",
        type: "",
        show: false
      }
    };
  },
  validations() {
    return {
      data: {
        birthdate: {dateLessOrEqualToCurrent}
      }
    };
  },
  created(){
    this.$Utilities.showResultMessage(this.$route.query, this.alert);

    this.verifyPatientStatus();
  },
  methods: {
    submitForm(){
      this.alert.show = false;

      this.v.$touch();

      if(this.v.$invalid) return;

      if(this.data.cpf == null
                   && this.data.phone == null
                   && this.data.birthdate == null
                   && this.data.cep == null
                   && this.data.street == null
                   && this.data.number == null
                   && this.data.complement == null
                   && this.data.neighborhood == null
                   && this.data.city == null
                   && this.data.uf == null) {
        this.alert.message = "É necessario informar alguma das informações complementares.";
        this.alert.type = "warning";
        this.alert.show = true;

        return;
      }

      this.loading = true;

      this.$axios({
        method: "PUT",
        url: `api/patient/${this.data.id}`,
        data: {
          accesscode: this.data.accesscode,
          allergy: this.data.allergy,
          attendanceplaceid: this.data.attendanceplaceid,
          birthdate: this.data.birthdate ? this.$Utilities.formatDate(this.data.birthdate, "DD/MM/YYYY", "MM/DD/YYYY") : "",
          cep: this.data.cep,
          cids: this.data.cids,
          cpf: this.data.cpf,
          city: this.data.city,
          comorbidity: this.data.comorbidity,
          complement: this.data.complement,
          diagnostic: this.data.diagnostic,
          email: this.data.email,
          gender: this.data.gender,
          name: this.data.name,
          registernumber: this.data.registernumber,
          neighborhood: this.data.neighborhood,
          number: this.data.number,
          ps: this.data.ps,
          phone: this.data.phone,
          photo: this.data.photo,
          relativename: this.data.relativename,
          relativephone: this.data.relativephone,
          street: this.data.street,
          uf: this.data.uf,
          teamId: this.data.teamId
        },
        headers: {"Authorization": `bearer ${this.$Session.get(this.$userSessionName).token.Value}`}
      })
        .then(() => {
          this.$router.push({name: "myPatients", query: {message: "complementaryInformation"}});
        })
        .catch(error => {
          this.$Utilities.verifyToken(error.response.status);

          this.$Utilities.controlAlert(error.response.data, this.alert, "danger");
        })
        .finally(() => this.loading = false);
    },
    verifyPatientStatus(){
      this.loadingContent = true;

      this.$axios
        .get(
          `api/patient/record/${this.data.id}`,
          {headers: {"Authorization": `bearer ${this.$Session.get(this.$userSessionName).token.Value}`}}
        )
        .then((res) => {
          if(res.data.Status != "Pending"){
            this.$router.push({name: "myPatients"});
          }else{
            this.getPatient();
          }
        });
    },
    getPatient(){
      this.loadingContent = true;

      this.$axios
        .get(
          `api/patient/${this.data.id}`,
          {headers: {"Authorization": `bearer ${this.$Session.get(this.$userSessionName).token.Value}`}}
        )
        .then((res) => {
          if(res.data.BirthDate != null
                           || res.data.CEP != null
                           || res.data.Street != null
                           || res.data.Number != null
                           || res.data.Complement != null
                           || res.data.Neighborhood != null
                           || res.data.City != null
                           || res.data.UF != null) this.$router.push({name: "myPatients"});

          for(let index in res.data){
            if(index.toLowerCase() == "attendanceplace"){
              this.data.attendanceplaceid = res.data[index].Id;
            }else{
              this.data[index.toLowerCase()] = res.data[index];
            }
          }
          this.data["teamId"] = res.data.Doctor.Id;
        })
        .catch(error => {
          this.$Utilities.verifyToken(error.response.status);
          this.$router.push({name: "myPatients"});
        })
        .finally(() => this.loadingContent = false);
    },
    changeCep(){
      this.$Utilities.buscarEndereco(this.data, this);
    }
  }
};
</script>

<style>
    @import '~@assets/css/views/complementaryRegistration.css';
</style>
