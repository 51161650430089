<template>
  <div
    class="input-component field"
    :class="validation.$error ? 'error' : ''"
  >
    <label
      v-if="label"
      class="label"
    >{{ label }}</label>

    <div :class="addon ? 'has-addons' : ''">
      <div
        class="control"
        :class="[controlStyle, type == 'datepicker' ? 'has-icons-right' : '']"
      >
        <div :class="[clearOption && modelValue != '' ? 'not-empty' : '', type == 'inputButton' ? 'input-button' : '']">
          <span
            class="clear-field"
            @click="clearClickEvent()"
          ><i class="clear-icon" /></span>
          <!-- Calendario -->
          <lila-message
            v-if="type == 'datepicker'"
            :alert-data="{show: true, type: 'danger', message: 'Esse input esta com a opção de DATEPICKER, não use mais ele, use o LilaMask.'}"
          />
          <!-- <date-picker
            v-if="type == 'datepicker'"
            input-class="input"
            value-type="format"
            :lang="datepicker.lang"
            format="DD/MM/YYYY"
            :not-after="disableFutureDates"
            :value="modelValue"
            :placeholder="placeholder"
            :disabled="readonly"
            @input="setDate"
            @change="changeEvent"
          /> -->

          <!-- Calendario com apenas os anos -->
          <lila-message
            v-else-if="type == 'datepicker-year'"
            :alert-data="{show: true, type: 'danger', message: 'Esse input esta com a opção de DATEPICKER YEAR, não use mais ele, use o LilaMask.'}"
          />
          <!-- <date-picker
            v-else-if="type == 'datepicker-year'"
            input-class="input"
            value-type="format"
            :lang="datepicker.lang"
            type="year"
            format="YYYY"
            :value="modelValue"
            :disabled="readonly"
            @input="setDate"
            @change="changeEvent"
          /> -->

          <!-- Mascara de dinheiro -->
          <lila-message
            v-else-if="isMoney"
            :alert-data="{show: true, type: 'danger', message: 'Esse input esta com a opção de MONEY, ele esta deprecado, crie um novo componente para isto.'}"
          />
          <!-- <money
            v-else-if="isMoney"
            :mask="inputMask"
            class="input"
            :value="modelValue"
            v-bind="money"
            :readonly="readonly"
            @input="moneyValue()"
            @keyup="keyUpEvent()"
          /> -->

          <!-- Input padrão com botão -->
          <input
            v-else-if="type == 'inputButton'"
            class="input"
            type="text"
            :placeholder="placeholder"
            :value="modelValue"
            :readonly="readonly"
            @input="$emit('update:modelValue', $event.target.value)"
            @keyup="keyUpEvent"
            @blur="blurEvent"
          >

          <!-- Input padrão ou busca -->
          <input
            v-else-if="inputMask == false || type == 'inputSearch'"
            class="input"
            :class="type == 'inputSearch' ? 'input-search' : ''"
            :autocomplete="!autocomplete ? 'new-password' : 'on'"
            :type="type"
            :placeholder="placeholder"
            :value="modelValue"
            :readonly="readonly"
            :disabled="disabled"
            @input="$emit('update:modelValue', $event.target.value)"
            @keyup="keyUpEvent"
            @blur="blurEvent"
            @click="clickInputEvent()"
          >

          <!-- Input com mascara -->
          <lila-message
            v-else-if="inputMask"
            :alert-data="{show: true, type: 'danger', message: 'Esse input esta com a opção de inputMask, não use mais ele, use o LilaMask.'}"
          />
          <!-- <the-mask v-else-if="inputMask"
                        :mask="inputMask"
                        class="input"
                        :type="type"
                        :placeholder="placeholder"
                        :value="modelValue"
                        @input.native="maskValue()"
                        @keyup.native="keyUpEvent()"
                        @blur.native="blurEvent()"
                        :readonly="readonly" /> -->

          <div
            v-if="type == 'inputButton'"
            class="field button-component"
          >
            <div class="control">
              <button
                type="button"
                class="button is-rounded button default"
                @click="buttonClickEvent()"
              >
                {{ buttonTitle }}
              </button>
            </div>
          </div>

          <div
            v-if="type == 'inputSearch'"
            class="input-search-icon"
            :class="btnClearSearch ? 'btn-clear-search' : ''"
          >
            <button
              v-show="btnClearSearch"
              type="button"
              :disabled="readonly"
              @click="clickClearSearchInputEvent()"
            >
              <lila-svg-icon :src="require(`@assets/icons/delete.svg`)" />
            </button>

            <button
              v-show="!btnClearSearch"
              type="submit"
              :disabled="readonly"
            >
              <lila-svg-icon :src="require(`@assets/icons/search.svg`)" />
            </button>
          </div>
        </div>

        <span
          v-if="example"
          class="example"
        >{{ example }}</span>

        <span
          v-if="(typeof hasIcon != 'undefined')"
          class="icon is-small"
          :class="hasIcon.position == 'right' ? ' is-right' : 'is-left'"
        >
          <i :class="iconStyle" />
        </span>

        <span
          v-if="validation.$error"
          class="validation-error"
        >{{ $Utilities.getErrorMsg(validation) }}</span>
      </div>

      <div
        v-if="addon"
        class="control"
      >
        <!--button v-if="!showAddonClear" class="button is-info btn-addon" @click="addonClickEvent()">{{ addon }}</button-->
        <button
          class="button is-info btn-addon"
          :class="showAddonClear ? 'with-clear' : ''"
          @click="addonClickEvent()"
        >
          {{ addon }}
        </button>
        <button
          v-if="addonClear && showAddonClear"
          class="button is-info btn-addon"
          @click="addonClearClickEvent()"
        >
          {{ addonClear }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// import {TheMask} from 'vue-the-mask';
// import {Money} from "v-money";
// import DatePicker from "vue2-datepicker";
import SvgIcon from "@components/shared/images/SvgIcon.vue";
import LilaMessage from "@components/shared/message/Message.vue";

export default {
  components: {
    // 'TheMask': TheMask,
    // "money": Money,
    // "date-picker": DatePicker,
    "lila-svg-icon": SvgIcon,
    LilaMessage
  },
  props: {
    type: {
      required: false,
      default: "text",
      type: String
    },
    label: {
      required: false,
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    autocomplete: {
      required: false,
      default: false,
      type: Boolean
    },
    readonly: {
      required: false,
      default: false,
      type: Boolean
    },
    disabled: {
      required: false,
      default: false,
      type: Boolean
    },
    modelValue: {
      default: null,
      required: false,
      type: [String, Number, Boolean, Object, null]
    },
    disableFutureDates: {
      required: false,
      default: '',
      type: String
    },
    example: {
      required: false,
      type: String,
      default: ''
    },
    inputMask: {
      required: false,
      default: false,
      type: Boolean
    },
    isMoney: {
      required: false,
      default: false,
      type: Boolean
    },
    keepMask: {
      required: false,
      default: false,
      type: Boolean
    },
    clearOption: {
      required: false,
      default: false,
      type: Boolean
    },
    addon: {
      required: false,
      default: '',
      type: String
    },
    addonClear: {
      required: false,
      default: '',
      type: String
    },
    showAddonClear: {
      required: false,
      default: false,
      type: Boolean
    },
    btnClearSearch: {
      required: false,
      default: false,
      type: Boolean
    },
    buttonTitle: {
      required: false,
      type: String,
      default: ''
    },
    loading: {
      required: false,
      default: false,
      type: Boolean
    },
    isCentralized: {
      required: false,
      type: Boolean,
      default: false
    },
    hasIcon: {
      required: false,
      type: Object,
      default: () => {
        
      }
    },
    validation: {
      required: false,
      type: Object,
      default: () => {
        return {
          $error: false
        };
      }
    }
  },
  emits: [
  'update:modelValue',
  'customChangeEvent',
  'customBlurEvent',
  'customClickEvent',
  'customClearClickEvent',
  'customAddonClickEvent',
  'customAddonClearClickEvent',
  'customButtonClickEvent',
  'customClearSearchInputEvent',
  'customKeyUpEvent'
],
  data(){
    return {
      datepicker: {
        lang: {
          days: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
          months: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
          pickers: ["next 7 days", "next 30 days", "previous 7 days", "previous 30 days"],
          placeholder: {
            date: "",
            dateRange: "Select Date Range"
          }
        }
      },
      price: 0,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false
      },
      file: {
        title: "",
        content: ""
      }
    };
  },
  computed: {
    controlStyle(){
      let style = "";

      if(this.loading) style += " is-loading ";
      if(this.isCentralized) style += " has-text-centered ";
      if(typeof this.hasIcon != "undefined" && this.hasIcon.position == "left") style += " has-icons-left";
      if(typeof this.hasIcon != "undefined" && this.hasIcon.position == "right") style += " has-icons-right";

      return style;
    },
    iconStyle(){
      if(typeof this.hasIcon != "undefined" && this.hasIcon.icon == "search") return "fas fa-search";

      return "";
    }
  },
  methods: {
    keyUpEvent(){
      this.$emit("customKeyUpEvent", this.returnOnlyNumbers(event.target.value));
    },
    keyUpDatepicker(){
      let date = event.target.value;

      if(event.target.value.length > 10){
        event.target.value = event.target.value.substring(0, 10);
      }else{
        switch (event.target.value.length) {
        case 2:
          date = `${date}/`;
          break;

        case 5:
          date = `${date}/`;
          break;
        }

        event.target.value = date;
      }

      if(event.target.value.length == 10){
        this.setDate(date);
      }

      return true;
    },
    // maskValue(){
    //     if(this.keepMask){
    //         return this.$emit('update:modelValue', event.target.value);
    //     }else{
    //         return this.$emit('update:modelValue', this.returnOnlyNumbers(event.target.value));
    //     }
    // },
    moneyValue(){
      return this.$emit("update:modelValue", this.returnMoneywhithoutMask(event.target.value));
    },
    setDate(date){
      return this.$emit("update:modelValue", date);
    },
    changeEvent(){
      this.$emit("customChangeEvent");
    },
    blurEvent(){
      this.$emit("customBlurEvent");
    },
    clickInputEvent(){
      this.$emit("customClickEvent");
    },
    clearClickEvent(){
      this.$emit("customClearClickEvent");
    },
    addonClickEvent(){
      this.$emit("customAddonClickEvent");
    },
    addonClearClickEvent(){
      this.$emit("customAddonClearClickEvent");
    },
    buttonClickEvent(){
      this.$emit("customButtonClickEvent");
    },
    clickClearSearchInputEvent(){
      this.$emit("customClearSearchInputEvent");
    },
    openFolderDirectory(){
      this.$refs.file.click();
    },
    returnMoneywhithoutMask(number){
      number = number.replace(/[.]+/g,"");
      return number.replace(/[,]+/g,".");
    },
    returnOnlyNumbers(number){
      return number.replace(/[^0-9a-zA-Z]+/g,"");
    }
  }
};
</script>

<style>
    @import '~@assets/css/components/input.css';
</style>
