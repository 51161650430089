<template>
  <lila-default-template>
    <template #mainContent>
      <div
        class="calendar-container"
        @click="cancelEventCreationOrEdition()"
      >
        <div>
          <h1 class="title">
            Agenda
          </h1>
          <lila-message :alert-data="alert" />

          <vue-cal
            ref="vuecal"
            class="vuecal--purple-theme"
            style="height: 500px"
            today-button
            :time="false"
            locale="pt-br"
            events-on-month-view="short"
            events-count-on-year-view
            :disable-views="['years', 'year']"
            :events="events"
            :on-event-click="onEventClick"
            :editable-events="{ title: false, drag: true, resize: false, delete: true, create: false }"
            @cell-click="onCellClick($event)"
            @cell-dblclick="onCellDoubleClick()"
            @event-delete="onEventDelete($event)"
            @event-drop="onEventDrop($event)"
            @view-change="onCurrentViewChange"
          />
        </div>

        <!-- INÍCIO: Modal para exibir e editar evento -->
        <template>
          <v-dialog
            v-model="showDialog"
            class="show-event-dialog"
          >
            <v-card>
              <v-card-title class="calendar-show-event-dialog-title-box">
                <div class="calendar-show-event-dialog-title-box-left-column">
                  <img :src="chooseEventIcon(selectedEvent.eventTypeId, selectedEvent.historyRecord?.type?.categoryId || null)">

                  <span
                    v-if="!isEditingTitle || !isEventEditable(selectedEvent)"
                    class="calendar-show-event-dialog-title"
                    @click="editTitle"
                  >{{ selectedEvent.textTitle }}</span>
                  <input
                    v-else
                    v-model="newEventTitle"
                    type="text"
                    class="calendar-show-event-title-input"
                    @keyup.enter="saveTitle"
                  >
                </div>

                <p
                  v-if="showRequiredTitleError"
                  class="required-title-error-message"
                >
                  O campo Título é obrigatório.
                </p>
              </v-card-title>
              <v-card-text>
                <div
                  class="calendar-show-event-dialog-description"
                  @click="editDescription"
                >
                  <p>Descrição:</p>
                  <p
                    v-if="!isEditingDescription || !isEventEditable(selectedEvent)"
                    v-html="selectedEvent.contentDescription"
                  />
                  <input
                    v-else
                    v-model="newEventDescription"
                    type="text"
                    class="calendar-show-event-description-input"
                    @keyup.enter="saveDescription"
                  >
                </div>
                <div
                  v-if="!isEditingPatient || !isEventEditable(selectedEvent)"
                  class="calendar-show-event-dialog-bottom"
                  @click="editPatient"
                >
                  <img
                    :src="selectedEvent.patient.photo || require('@/assets/images/default_avatar_neutral.png')"
                    class="circular-mask small-image-30"
                  >
                  <p class="calendar-show-event-dialog-patient left-gap-10">
                    {{ selectedEvent.patient.name }}
                  </p>
                </div>

                <!-- INÍCIO: CAIXA DE SELEÇÃO DE PACIENTES-->
                <input
                  v-else
                  v-model="patientSearchQuery"
                  type="text"
                  placeholder="Paciente"
                  class="calendar-edit-event-dialog-patient"
                  @input="filterPatients"
                >
                <ul
                  v-if="filteredPatients.length"
                  class="calendar-edit-event-dialog-patient-suggestions"
                  @click.stop
                >
                  <li
                    v-for="patient in filteredPatients"
                    :key="patient.Id"
                    @click="selectPatient(patient)"
                  >
                    <img
                      :src="patient.Photo || require('@/assets/images/default_avatar_neutral.png')"
                      :alt="patient.Name"
                      class="circular-mask small-image-25"
                    >
                    <span>{{ patient.Name }}</span>
                  </li>
                </ul>
                <!-- FIM: CAIXA DE SELEÇÃO DE PACIENTES-->
              </v-card-text>
            </v-card>
          </v-dialog>
        </template>
        <!-- FIM: Modal para exibir e editar evento -->

        <!-- INÍCIO: Modal para criar novo evento -->
        <CreateCalendarEvent
          v-if="showEventCreationDialog"
          :event-date="eventDate"
          :alert="alert"
          :patients="patients"
          @click.stop="cancelEventCreationOrEdition()"
          @close="cancelEventCreationOrEdition()"
          @save-new-event="saveNewEvent"
        />
        <!-- FIM: Modal para criar novo evento -->
      </div>
    </template>
  </lila-default-template>
</template>
    
    <script>
import DefaultTemplate from "@components/template/DefaultTemplate.vue";
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
import { VDialog, VCard, VCardTitle, VCardText, VSpacer } from "vuetify/components";
import { calendarApi } from '@/js/axios.js';
import Message from "@components/shared/message/Message.vue";
import { CalendarEventTypes } from "@/enums/CalendarEventTypes.js";
import { HistoryRecordCategories } from "@/enums/HistoryRecordCategories.js";
import CreateCalendarEvent from "../components/Calendar/CreateCalendarEvent.vue";


export default {
  components: {
    "lila-default-template": DefaultTemplate,
    "vue-cal": VueCal,
    VDialog,
    calendarApi,
    "lila-message": Message,
    VCard,
    VCardTitle,
    VCardText,
    VSpacer,
    CalendarEventTypes,
    HistoryRecordCategories,
    CreateCalendarEvent,
  },
  data() {
    return {
      selectedEvent: {},
      eventDate: '',
      showDialog: false,
      events: [],
      isEditingTitle: false,
      isEditingDescription: false,
      isEditingPatient: false,
      newEventTitle: '',
      newEventDescription: '',
      showEventCreationDialog: false,
      alert: {
        message: "",
        type: "",
        show: false
      },
      singleClickTimeout: null,
      patients: [],
      filteredPatients: [],
      patientSearchQuery: '',
      currentViewStartDate: this.getFirstDayOfPreviousMonth(new Date()),
      currentViewEndDate: this.getLastDayOfNextMonth(new Date()),
      loadedMonths: [],
      showRequiredTitleError: false
    };
  },

  created() {
    this.loadEvents();
    this.loadPatients();
    this.registerFirstLoadedMonths();
  },
  methods: {

    /* INICIO: MÉTODOS PARA EXIBIR EVENTOS NO CALENDÁRIO */
    async loadEvents(startDate = null, endDate = null) {

      await this.$HTTPUtilities.get(
        this,
        `/api/calendar?startDate=${startDate ? startDate.format() : this.currentViewStartDate.format()}&endDate=${endDate ? endDate.format() : this.currentViewEndDate.format()}`,
        (res) => {

          let newEvents = res.data.map(event => ({
            id: event.id,
            start: event.date,
            end: event.date,
            title:
              `<div class="calendar-event-title">
                  <div class="calendar-event-title-left-column">
                      <img src="${this.chooseEventIcon(event.eventTypeId, event.historyRecord?.type?.categoryId || null)}" class="calendar-event-icon" />
                  </div>   
                  <div class="calendar-event-title-right-column">
                    <span>${event.title}</span>
                  </div>  
               </div>`,
            textTitle: event.title,
            contentDescription: event.description,
            eventTypeId: event.eventTypeId,
            patient: event.patient,
            content:
              `<div class="calendar-event-content">
               <img src="${event.patient.photo || require('@/assets/images/default_avatar_neutral.png')}" alt="${event.patient.name}" class="circular-mask small-image-25" />
               <span>${event.patient.name}</span>
               </div>`,
            historyRecordCategoryId: event.historyRecord?.type?.categoryId || null,
            class: `event-color-${event.id}`, // Classe única para cada evento,
            duration: 1,
            draggable: this.isEventEditable(event),
            deletable: this.isEventEditable(event)

          }));

          newEvents.forEach(newEvent => this.events.push(newEvent));

          this.registerLoadedMonths(startDate, endDate);
          this.addDynamicStyles();
        },
        this.alert,
        calendarApi
      );
    },

    registerFirstLoadedMonths() {
      this.loadedMonths.push({ month: this.currentViewStartDate.getMonth(), year: this.currentViewStartDate.getFullYear() },
        { month: new Date().getMonth(), year: new Date().getFullYear() },
        { month: this.currentViewEndDate.getMonth(), year: this.currentViewEndDate.getFullYear() }
      );
    },

    addDynamicStyles() {
      // Remova o estilo existente antes de adicionar novos estilos
      const existingStyleElement = document.getElementById('dynamic-event-styles');
      if (existingStyleElement) {
        existingStyleElement.remove();
      }

      // Crie um novo elemento de estilo
      const styleElement = document.createElement('style');
      styleElement.id = 'dynamic-event-styles';

      // Adicione regras CSS dinâmicas para cada evento
      this.events.forEach(event => {
        const eventColor = this.chooseEventColor(event.eventTypeId, event.historyRecordCategoryId).backgroundColor;
        const borderColor = this.chooseEventColor(event.eventTypeId, event.historyRecordCategoryId).borderColor;

        styleElement.innerHTML +=
          `.vuecal__event.event-color-${event.id} {
            background-color: ${eventColor} !important;
            border-color: ${borderColor} !important;}`;
      });

      // Adicione o elemento de estilo ao cabeçalho do documento
      document.head.appendChild(styleElement);
    },


    chooseEventColor(calendarEventTypeId, patientEventId = null) {
      const idToUse = patientEventId
        ? (calendarEventTypeId * 1000) + patientEventId
        : calendarEventTypeId;

      const hue = (idToUse * 137.508) % 360;
      const saturation = 56 + ((idToUse * 29) % 20);
      const lightness = 98 + ((idToUse * 23) % 10);

      const borderHue = (hue + 15) % 360;
      const borderLightness = lightness - 30;

      return {
        backgroundColor: `hsl(${hue}, ${saturation}%, ${lightness}%)`,
        borderColor: `hsl(${borderHue}, ${saturation}%, ${borderLightness}%)`
      };
    },
    chooseEventIcon(calendarEventTypeId, historyRecordCategoryId = null) {

      if (calendarEventTypeId == CalendarEventTypes.ActionDueDate)
        return require('@/assets/icons/task_due_date.svg');
      if (calendarEventTypeId == CalendarEventTypes.PatientEvent && historyRecordCategoryId != null) {
        if (historyRecordCategoryId == HistoryRecordCategories.MedicalMeet)
          return require('@/assets/icons/medical_meet.svg');
        if (historyRecordCategoryId == HistoryRecordCategories.Exam)
          return require('@/assets/icons/medical_exam.svg');
      }
      return require('@/assets/icons/clock.svg');
    },

    async loadPatients() {
      await this.$HTTPUtilities.get(
        this,
        "/api/patient?summarized=true",
        (res) => {
          res.data.forEach(patient => {
            this.patients.push(patient);
          });
        },
        this.alert);

    },
    /* FIM: MÉTODOS PARA EXIBIR EVENTOS NO CALENDÁRIO */


    /* INICIO: MÉTODOS PARA CONTROLAR CLIQUES NO CALENDÁRIO (EXCETO CLIQUES EM EVENTOS) */
    onCellClick(date) {

      clearTimeout(this.singleClickTimeout);

      this.singleClickTimeout = setTimeout(() => {
        this.createEvent(date);
      }, 200); // Delay para detectar clique único
    },

    onCellDoubleClick() {
      clearTimeout(this.singleClickTimeout);
    },

    onCurrentViewChange(view) {

      this.currentViewStartDate = view.startDate;
      this.currentViewEndDate = view.endDate;

      if (!this.previousMonthIsLoaded(this.currentViewStartDate))
        this.loadPreviousMonth(this.currentViewStartDate);

      if (!this.nextMonthIsLoaded(this.currentViewStartDate))
        this.loadNextMonth(this.currentViewStartDate);

    },

    previousMonthIsLoaded(date) {
      let previousMonth = date.getMonth() - 1;
      let previousYear = date.getFullYear();
      let adjustedMonth = previousMonth < 0 ? 11 : previousMonth;
      let adjustedYear = previousMonth < 0 ? previousYear - 1 : previousYear;

      return this.loadedMonths.some(monthObj =>
        monthObj.month === adjustedMonth && monthObj.year === adjustedYear
      );
    },

    nextMonthIsLoaded(date) {
      let nextMonth = date.getMonth() + 1;
      let nextYear = date.getFullYear();
      let adjustedMonth = nextMonth > 11 ? 0 : nextMonth;
      let adjustedYear = nextMonth > 11 ? nextYear + 1 : nextYear;

      return this.loadedMonths.some(monthObj =>
        monthObj.month === adjustedMonth && monthObj.year === adjustedYear
      );
    },

    async loadPreviousMonth(date) {
      let firstDayOfPreviousMonth = new Date(date.getFullYear(), date.getMonth() - 1, 1);
      let lastDayOfPreviousMonth = new Date(date.getFullYear(), date.getMonth(), 0);
      await this.loadEvents(firstDayOfPreviousMonth, lastDayOfPreviousMonth);
    },

    async loadNextMonth(date) {
      let firstDayOfNextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1);
      let lastDayOfNextMonth = new Date(date.getFullYear(), date.getMonth() + 2, 0);
      await this.loadEvents(firstDayOfNextMonth, lastDayOfNextMonth);
    },

    getFirstDayOfPreviousMonth(date) {
      let firstDayOfThisMonth = new Date(date.getFullYear(), date.getMonth(), 1);
      return new Date(firstDayOfThisMonth.setMonth(firstDayOfThisMonth.getMonth() - 1));
    },

    getLastDayOfNextMonth(date) {
      return new Date(date.getFullYear(), date.getMonth() + 2, 0);
    },

    registerLoadedMonths(startDate, endDate) {
      if (startDate != null && endDate != null)
        this.loadedMonths.push({ month: startDate.getMonth(), year: startDate.getFullYear() },
          { month: endDate.getMonth(), year: endDate.getFullYear() }
        );
    },

    /* FIM: MÉTODOS PARA CONTROLAR CLIQUES NO CALENDÁRIO (EXCETO CLIQUES EM EVENTOS) */


    /* INICIO: MÉTODOS PARA INTERCEPTAR INTERAÇÕES COM EVENTOS DO CALENDÁRIO */
    onEventClick(event) {
      this.selectedEvent = event;
      this.showDialog = true;
    },

    async onEventDelete(event) {
      await this.$HTTPUtilities.delete(
        this,
        `/api/calendar/patient/${event.patient.id}/event/${event.id}`,
        () => { },
        this.alert,
        calendarApi);
    },

    async onEventDrop(event) {
      this.selectedEvent = event.event;
      this.updateSelectedEvent();
    },
    /* FIM: MÉTODOS PARA INTERCEPTAR INTERAÇÕES COM EVENTOS DO CALENDÁRIO */


    /*INÍCIO: MÉTODOS DE CRIAÇÃO DE EVENTO */
    createEvent(date) {
      this.eventDate = date;
      this.showEventCreationDialog = true;
    },

    saveNewEvent(newEvent) {
      this.$refs.vuecal.createEvent(
        newEvent.start,
        120,
        {
          id: newEvent.id,
          start: newEvent.start,
          end: newEvent.end,
          title:
            `<div class="calendar-event-title">
                  <div class="calendar-event-title-left-column">
                      <img src="${this.chooseEventIcon(newEvent.eventTypeId, newEvent.historyRecord?.type?.categoryId || null)}" class="calendar-event-icon" />
                  </div>   
                  <div class="calendar-event-title-right-column">
                    <span>${newEvent.title}</span>
                  </div>  
               </div>`,
          textTitle: newEvent.title,
          contentDescription: newEvent.contentDescription,
          eventTypeId: newEvent.eventTypeId,
          patient: { id: newEvent.patient.Id, name: newEvent.patient.Name, photo: newEvent.patient.Photo },
          content:
            `<div class="calendar-event-content">
               <img src="${newEvent.patient.Photo || require('@/assets/images/default_avatar_neutral.png')}" alt="${newEvent.patient.Name}" class="circular-mask small-image-25" />
               <span>${newEvent.patient.Name}</span>
               </div>`,
          historyRecordCategoryId: newEvent.historyRecord?.type?.categoryId || null,
          class: `event-color-${newEvent.id}`
        }
      );
      this.addDynamicStyles();

      this.showEventCreationDialog = false;
      this.eventDate = {};

    },
    /*FIM: MÉTODOS DE CRIAÇÃO DE EVENTO */

    /* INÍCIO: MÉTODOS DE EXIBIÇÃO E EDICAÇÃO DE EVENTO */
    editTitle() {
      this.isEditingTitle = true;
      this.newEventTitle = this.selectedEvent.textTitle;
    },
    saveTitle() {

      if (!this.newEventTitle) {
        this.showRequiredTitleError = true;
        return;
      }

      this.selectedEvent.textTitle = this.newEventTitle;
      this.selectedEvent.title =
        `<div class="calendar-event-title">
                  <div class="calendar-event-title-left-column">
                      <img src="${this.chooseEventIcon(this.selectedEvent.eventTypeId, this.selectedEvent.historyRecord?.type?.categoryId || null)}" class="calendar-event-icon" />
                  </div>   
                  <div class="calendar-event-title-right-column">
                    <span>${this.selectedEvent.textTitle}</span>
                  </div>  
               </div>`;
      this.isEditingTitle = false;
      this.updateSelectedEvent();
      this.showRequiredTitleError = false;
    },

    editDescription() {
      this.isEditingDescription = true;
      this.newEventDescription = this.selectedEvent.contentDescription;
    },

    saveDescription() {
      this.selectedEvent.contentDescription = this.newEventDescription;
      this.isEditingDescription = false;
      this.updateSelectedEvent();
    },

    editPatient() {
      this.isEditingPatient = true;
      this.patientSearchQuery = this.selectedEvent.patient.name;
    },

    filterPatients() {
      this.filteredPatients = this.patients.filter(patient =>
        patient.Name.toLowerCase().includes(this.patientSearchQuery.toLowerCase())
      );
    },
    selectPatient(patient) {
      this.searchQuery = '';
      let oldPatientId = this.selectedEvent.patient.id;
      this.selectedEvent.patient = { id: patient.Id, name: patient.Name, photo: patient.Photo };
      this.filteredPatients = [];
      this.isEditingPatient = false;
      this.updateSelectedEvent(oldPatientId);
      this.selectedEvent.content =
        `<div class="calendar-event-content">
               <img src="${this.selectedEvent.patient.photo || require('@/assets/images/default_avatar_neutral.png')}" alt="${this.selectedEvent.patient.name}" class="circular-mask small-image-25" />
               <span>${this.selectedEvent.patient.name}</span>
               </div>`;
    },

    async updateSelectedEvent(patientId = null) {
      await this.$HTTPUtilities.put(
        this,
        `/api/calendar/patient/${patientId ? patientId : this.selectedEvent.patient.id}/event/${this.selectedEvent.id}`,
        this.mountCalendarEventJSON(),
        () => { },
        this.alert,
        calendarApi
      );
    },

    mountCalendarEventJSON() {
      return {
        date: this.selectedEvent.start.format(),
        title: this.selectedEvent.textTitle,
        description: this.selectedEvent.contentDescription,
        patientId: this.selectedEvent.patient.id
      };
    },

    isEventEditable(event) {
      return event.eventTypeId == CalendarEventTypes.CustomEvent;
    },

    /* FIM: MÉTODOS DE EXIBIÇÃO E EDICAÇÃO DE EVENTO */


    cancelEventCreationOrEdition() {
      this.showEventCreationDialog = false;
      this.eventDate = '';
      this.newEventTitle = '';
      this.newEventDescription = '';
      this.isEditingTitle = false;
      this.isEditingDescription = false;
      this.isEditingPatient = false;
      this.filteredPatients = [];
      this.patientSearchQuery = '';
    },
  }
};
</script>
    
<style>
@import "~@assets/css/views/lilaCalendar.css";

/*INÍCIO: Estilos gerais do calendário */
.calendar-container .vuecal__event {
  cursor: pointer;
  border: 1px solid;
  color: black;
}

.calendar-container .vuecal__event-content {
  margin-top: 20px;
}

.calendar-container .vuecal--no-time .vuecal__event {
  min-height: 60px !important;
}
.calendar-container .vuecal {
  height: 700px !important;
}
/*FIM: Estilos gerais do calendário */

/*INÍCIO: Exibição de eventos do calendário */
.calendar-container .calendar-event-content {
  display: flex;
  justify-content: left;
  margin: 5px;
  gap: 5px;
}

.calendar-container .calendar-event-content span {
  margin-top: 3px;
}

.calendar-container .calendar-event-title {
  position: relative;
  text-align: left;
  margin-top: 5px;
}

.calendar-container .calendar-event-title-right-column {
  max-width: calc(100% - 30px);

  margin-left: 30px;
}

.calendar-container .calendar-event-title span {
  white-space: normal;
  word-wrap: normal;
}

/*FIM: Exibição de eventos do calendário */

/*INÍCIO: Exibição de detalhes do evento */

.show-event-dialog {
  width: 700px;
}
.calendar-show-event-dialog-title-box {
  background-color: #4f3f82;
  display: flex !important;
  justify-content: space-between;
  word-wrap: break-word;
}

.calendar-show-event-dialog-title-box img {
  cursor: pointer;
}

.calendar-show-event-dialog-title-box-left-column {
  display: flex;
  align-items: center;
}

.calendar-show-event-dialog-bottom {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.calendar-show-event-dialog-title {
  color: white;
  margin-left: 20px;
  white-space: normal;
}

.calendar-show-event-dialog-patient {
  color: black;
  margin-right: 20px;
}

.calendar-show-event-title-input {
  margin-left: 20px;
  font-size: 16px;
  color: white;
  background: transparent;
  border: none;
  border-bottom: 1px solid white;
}

.calendar-show-event-description-input {
  margin-left: 20px;
  font-size: 16px;
  color: black;
  background: transparent;
  border: none;
  border-bottom: 1px solid black;
}

.calendar-show-event-edit-icon {
  cursor: pointer;
}

.calendar-container .calendar-event-icon {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
}

.calendar-show-event-dialog-title-box-left-column img {
  width: 30px;
  height: 30px;
  margin-left: 4px;
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%)
    hue-rotate(238deg) brightness(110%) contrast(108%);
}

.calendar-show-event-dialog-description input {
  width: 100%;
  height: 30px;
}

.calendar-show-event-dialog-description {
  width: 100%;
  height: 30px;
  display: flex;
  align-content: space-between;
  cursor: pointer;
}

.calendar-show-event-dialog-description p {
  padding-right: 5px;
}

.calendar-edit-event-dialog-patient {
  max-width: 400px;
  margin-bottom: 10px;
  width: 100%;
  padding: 12px;
  border: 1px solid #767676;
  border-radius: 5px;
}

.calendar-edit-event-dialog-patient-suggestions {
  max-width: 400px;
  margin-bottom: 50px;
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
  list-style-type: none;
  margin-left: 2px !important;
  margin-right: 20px;
  padding: 10px;
}

.calendar-edit-event-dialog-patient-suggestions li {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.required-title-error-message {
  color: white;
  font-size: 12px;
  margin-top: 5px;
}
/*FIM: Exibição de detalhes do evento */
</style>