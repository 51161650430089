<template>
  <div class="radio-component field">
    <label
      v-if="label"
      class="label"
    >{{ label }}</label>
    <div class="control">
      <label
        v-for="(item, index) in options"
        :key="item._id"
        class="radio"
      >
        <span class="radio-title">{{ item.name }}</span>
        <input
          type="radio"
          :name="name"
          :value="item._id"
          :disabled="readonly"
          :checked="(index == 0 || item._id == value) ? true : false"
          @input="$emit('update:modelValue', $event.target.value)"
          @change="changeEvent()"
        >
        <span class="checkmark" />
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    label: {
      required: false,
      type: String,
      default: ""
    },
    name: {
      required: true,
      type: String
    },
    options: {
      required: true,
      type: Array
    },
    readonly: {
      required: false,
      default: false,
      type: Boolean
    }
  },
  emits: ['customChangeEvent', 'update:modelValue'],
  methods: {
    changeEvent(){
      this.$emit("customChangeEvent");
    }
  }
};
</script>

<style>
    @import '~@assets/css/components/radio.css';
</style>
