<template>
  <lila-login-template>
    <template #mainContent>
      <div class="login-form full-width">
        <h1 class="title">
          Cadastro Realizado!
        </h1>
                
        <p class="sign-up-performed">
          Verifique seu <strong>e-mail</strong> e clique no link.
        </p>

        <lila-button 
          type="route" 
          path="login"
          title="OK!" 
          class="submit-buttons"
        />
      </div>
    </template>
  </lila-login-template>
</template>

<script>
import LoginTemplate from "@components/template/LoginTemplate.vue";

import Button from "@components/shared/button/Button.vue";

export default {
  components: {
    "lila-login-template": LoginTemplate,
    "lila-button": Button
  }
};
</script>

<style></style>