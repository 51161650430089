<template>
  <div>
    <form
      class="container basic-info-form medical-card-form"
      :class="loadingContent ? 'loading large' : ''"
      @submit.prevent="submitForm()"
    >
      <lila-message :alert-data="alert" />

      <div v-show="!loadingContent">
        <div
          v-if="!$AccessControl.cannot(
            $Session.get($userSessionName).profile,
            'export_patient_data'
          )"
          class="box-export-medical-record"
        >
          <lila-export-button
            :api-url="`/api/patient/export/${data.Id}`"
            :alert="alert"
          />
        </div>

        <!-- Botão "Iniciar navegação administrativa" -->
        <div class="field-group">
          <div
            v-if="utilities.hasAccessToFeature(FeatureEnum.Automacoes)"
            class="field-group"
          >
            <div class="field-group-body">
              <div class="field-body columns is-align-items-center">
                <div class="column is-narrow action-icon-container">
                  <lila-button
                    type="button"
                    :title="data.HasNavAdmStarted ? `Navegação administrativa iniciada ${formattedNavigationStartDate}` : 'Iniciar navegação administrativa'"
                    class="button-automated-actions"
                    :loading="endpointLoading"
                    :is-disabled="endpointLoading || data.HasNavAdmStarted"
                    @custom-click-event="callEndpoint"
                  />

                  <div class="tooltip-icon">
                    <lila-svg-icon
                      :src="require('@assets/icons/info.svg')"
                      class="is-cursor-pointer"
                    />
                    <span class="tooltip-text">
                      Este botão cria ações automatizadas para o paciente com base nas configurações atuais.
                    </span>
                  </div>
                </div>
              </div>
              <div
                v-if="!data.HasNavAdmStarted" 
                class="field-group-header align-title"
              >
                <p class="field-group-label-type">
                  Data de Início da Navegação
                </p>
                <lila-datepicker
                  v-model="data.NavigationStartDate"
                  :placeholder="data.HasNavAdmStarted == true ? data.NavigationStartDate : 'Selecione a data de início da navegação'"
                  :disabled="data.HasNavAdmStarted"
                  class="datepicker-small"
                />
              </div>
            </div>
          </div>

          <div class="field-group-header align-title">
            <p class="field-group-label-type">
              Estado de acesso ao sistema
            </p>
          </div>

          <div class="field-group-body">
            <div class="field-body columns">
              <lila-input
                v-if="data.Status === 'Pending'"
                class="column is-narrow is-6"
                model-value="Não fez primeiro acesso"
                :disabled="true"
              />

              <lila-select
                v-else
                v-model="data.Status"
                class="column is-narrow is-6"
                :options="statusList"
                :readonly="!checkInstalledApp || viewOnly"
              />
            </div>
          </div>

          <div class="division-line" />

          <div class="field-group-header align-title">
            <p class="field-group-label-type">
              Fase da jornada
            </p>
          </div>

          <div class="field-group-body">
            <div class="field-body columns">
              <div class="column is-narrow is-6">
                <lila-select
                  v-model="data.selectedJourneyStage"
                  :options="journeyStageList"
                  @custom-change-event="clearLifeStatus"
                />
              </div>
            </div>

            <!-- Grouped v-if to conditionally render both the datepicker and textarea -->
            <div
              v-if="Number(data.selectedJourneyStage) ===
                PatientJourneyOptions.Falecido
              "
            >
              <div class="field-body columns is-multiline">
                <div class="column is-narrow is-6">
                  <lila-datepicker
                    v-model="v.data.DeathDate.$model"
                    :disable-future-dates="$moment().format('MM/DD/YYYY')"
                    placeholder="Data de falecimento"
                    :validation="v.data.DeathDate"
                  />
                </div>
              </div>

              <lila-textarea
                v-model="v.data.DeathNotes.$model"
                placeholder="Notas"
                :validation="v.data.DeathNotes"
              />
            </div>
          </div>

          <div class="division-line" />

          <!-- Informações sobre o Paciente -->
          <div class="field-group">
            <!-- Paciente -->
            <div class="field-group-header align-title">
              <p class="field-group-label-type">
                Paciente
              </p>
            </div>

            <div class="field-group-body">
              <lila-input
                v-model="v.data.Name.$model"
                placeholder="Nome"
                :validation="v.data.Name"
              />

              <lila-input
                v-model="data.SocialName"
                placeholder="Nome Social"
                :validation="v.data.SocialName"
              />

              <lila-input
                v-model="v.data.RegisterNumber.$model"
                placeholder="Número de Matrícula"
                :validation="v.data.RegisterNumber"
              />

              <lila-select
                v-model="v.data.Gender.$model"
                placeholder="Sexo"
                :options="genderList"
                :validation="v.data.Gender"
              />

              <lila-input
                v-model="v.data.Email.$model"
                placeholder="E-mail"
                :validation="v.data.Email"
              />
            </div>

            <!-- Dados do Paciente -->
            <div class="field-group-header align-title">
              <p class="field-group-label-type">
                Dados do Paciente
              </p>
            </div>

            <div class="field-group-body">
              <lila-mask
                v-model="v.data.CPF.$model"
                placeholder="CPF"
                mask="###.###.###-##"
                :validation="v.data.CPF"
                :readonly="viewOnly"
              />

              <lila-input
                v-model="v.data.CNS.$model"
                placeholder="CNS"
                :validation="v.data.CNS"
              />

              <lila-mask
                v-model="v.data.Phone.$model"
                placeholder="Telefone"
                :mask="['(##) ####-####', '(##) #####-####']"
                :validation="v.data.Phone"
                :readonly="viewOnly"
              />

              <lila-datepicker
                v-model="v.data.BirthDate.$model"
                placeholder="Data de Nascimento"
                :validation="v.data.BirthDate"
                :readonly="viewOnly"
              />
            </div>

            <!-- Atributos do Paciente -->
            <div class="field-group-header align-title">
              <p class="field-group-label-type">
                Atributos do Paciente
              </p>
            </div>

            <div class="field-group-body">
              <lila-select
                v-model="data.selectedRace"
                placeholder="Raça"
                :options="raceList"
              />

              <lila-select
                v-model="data.selectedEducation"
                placeholder="Educação"
                :options="educationList"
              />

              <lila-select
                v-model="data.selectedEmployment"
                placeholder="Profissão"
                :options="employmentList"
              />

              <lila-select
                v-model="data.selectedMaritalStatus"
                placeholder="Estado Civil"
                :options="maritalStatusList"
              />
            </div>

            <!-- Acompanhante -->
            <div class="field-group-header align-title">
              <p class="field-group-label-type">
                Acompanhante
              </p>
            </div>

            <div class="field-group-body">
              <lila-select
                v-model="data.selectedCompanionType"
                placeholder="Tipo de Acompanhante"
                :options="companionTypeList"
              />

              <lila-input
                v-model="data.CompanionName"
                placeholder="Nome do Acompanhante"
                :readonly="viewOnly"
              />

              <lila-mask
                v-model="data.CompanionPhone"
                placeholder="Telefone do Acompanhante"
                :mask="['(##) ####-####', '(##) #####-####']"
                :readonly="viewOnly"
              />
            </div>

            <!-- Contato Parente, Amigo(a)...? -->
            <div class="field-group-header align-title">
              <p class="field-group-label-type">
                Contato Parente, Amigo(a)...?
              </p>
            </div>

            <div class="field-group-body">
              <lila-input
                v-model="data.RelativeName"
                placeholder="Nome do Parente"
                :readonly="viewOnly"
              />

              <lila-mask
                v-model="data.RelativePhone"
                placeholder="Telefone"
                :mask="['(##) ####-####', '(##) #####-####']"
                :readonly="viewOnly"
              />
            </div>

            <!-- Endereço -->
            <div class="division-line" />

            <div class="field-group-header align-title">
              <p class="field-group-label-type">
                Endereço
              </p>
            </div>

            <div class="field-group-body">
              <lila-mask
                v-model="data.CEP"
                placeholder="CEP"
                mask="#####-###"
                :loading="loadingCep"
                :readonly="viewOnly"
                @keyup="changeCep()"
              />

              <lila-input
                v-model="data.Street"
                placeholder="Endereço"
                :readonly="viewOnly"
              />

              <lila-input
                v-model="data.Number"
                placeholder="Número"
                :readonly="viewOnly"
              />

              <lila-input
                v-model="data.Complement"
                placeholder="Complemento"
                :readonly="viewOnly"
              />

              <lila-input
                v-model="data.Neighborhood"
                placeholder="Bairro"
                :readonly="viewOnly"
              />

              <lila-input
                v-model="data.City"
                placeholder="Cidade"
                :readonly="viewOnly"
              />

              <lila-select
                v-model="data.UF"
                placeholder="Estado"
                :options="statesList"
                :readonly="viewOnly"
              />
            </div>

            <!-- Diagnóstico -->
            <div class="division-line" />

            <div class="field-group-header align-title">
              <p class="field-group-label-type">
                Diagnóstico
              </p>
            </div>

            <!-- Início dos sintomas -->
            <div class="field-group-body symptoms-start-date-group is-fullwidth">
              <div class="symptoms-start-date-row columns is-mobile">
                <label class="symptoms-start-date-label column is-narrow">Início dos sintomas:</label>

                <div class="symptoms-start-date-input column">
                  <lila-select
                    v-model="data.selectedSymptomStartPeriod"
                    class="input-data-inicio-sintomas"
                    placeholder="Período de início dos sintomas"
                    :options="symptomStartPeriodList"
                  />
                </div>
              </div>
            </div>

            <!-- Adicionar CID -->
            <div
              v-if="!viewOnly"
              class="field-group-body field-group-add-cid"
            >
              <lila-input
                v-model="v.newCid.CID.CIDCode.$model"
                placeholder="Selecionar CID"
                class="is-cursor-pointer input-cid"
                :validation="v.newCid.CID.CIDCode"
                @custom-click-event="openCidModal"
              />

              <lila-input
                v-if="isCancerRelated"
                v-model="v.newCid.Morphology.$model"
                placeholder="Morfologia"
                class="input-morphology"
                :validation="v.newCid.Morphology"
              />

              <lila-select
                v-if="isCancerRelated"
                v-model="v.newCid.Estadio.$model"
                placeholder="Estadio"
                class="input-estadio"
                :options="estadioList"
                :validation="v.newCid.Estadio"
              />

              <lila-datepicker
                v-if="isCancerRelated"
                v-model="v.newCid.BiopsyDate.$model"
                placeholder="Data da biópsia"
                class="input-data-biopsia"
                :validation="v.newCid.BiopsyDate"
              />

              <lila-button
                type="button"
                title="Adicionar"
                class="button-add-cid"
                :loading="cidLoading"
                :disabled="cidLoading"
                @custom-click-event="addCid2"
              />
            </div>

            <div
              v-if="data.CIDs && data.CIDs.length > 0"
              class="field-group-body"
            >
              <table class="table is-fullwidth table-cid">
                <thead>
                  <tr>
                    <th>CID</th>
                    <th v-if="data.CIDs.some((cidItem) => cidItem.Morphology)">
                      Morfologia
                    </th>
                    <th v-else />
                    <th v-if="data.CIDs.some((cidItem) => cidItem.Estadio)">
                      Estadio
                    </th>
                    <th v-else />
                    <th v-if="data.CIDs.some((cidItem) => cidItem.BiopsyDate)">
                      Data da Biópsia
                    </th>
                    <th v-else />
                    <th
                      v-if="!viewOnly"
                      class="has-text-centered"
                    >
                      Excluir
                    </th>
                  </tr>
                </thead>

                <transition-group
                  tag="tbody"
                  name="fade"
                  enter-active-class="animated fadeIn"
                  leave-active-class="animated fadeOut"
                >
                  <tr
                    v-for="(cidItem, index) in data.CIDs"
                    :key="cidItem.Id"
                    :class="cidItem.Id ? 'no-animation' : ''"
                  >
                    <td>
                      <span>{{ cidItem.CID.CIDCode }}</span> -
                      <span>{{ cidItem.CID.Description }}</span>
                    </td>

                    <td>
                      <span v-if="cidItem.Morphology">{{
                        cidItem.Morphology
                      }}</span>
                    </td>

                    <td>
                      <span v-if="cidItem.Estadio">Estadio {{ cidItem.Estadio }}</span>
                    </td>

                    <td>
                      <span v-if="cidItem.BiopsyDate">{{
                        $moment(cidItem.BiopsyDate).format("DD/MM/YYYY")
                      }}</span>
                    </td>

                    <td
                      v-if="!viewOnly"
                      class="has-text-centered table-cid-delete"
                    >
                      <lila-svg-icon
                        :src="require('@assets/icons/delete.svg')"
                        class="is-cursor-pointer"
                        @custom-click-event="openDeleteModal(cidItem.Id, index)"
                      />
                    </td>
                  </tr>
                </transition-group>
              </table>
            </div>

            <!-- Performance Status -->
            <div class="division-line" />

            <div class="field-group-body">
              <lila-select
                v-model="data.PS"
                :label="`Performance Status${data.HasWeeklyReport ? ' informado pelo paciente' : ''}`"
                :options="performanceStatusList"
                :readonly="true"
              />
            </div>

            <!-- Alergia Medicamentosa, Comorbidades, Diagnóstico Oncológico -->
            <div class="division-line" />

            <div class="field-group-body">
              <lila-textarea
                v-model="data.Allergy"
                label="Alergia Medicamentosa"
                placeholder="Digite aqui..."
                :readonly="viewOnly"
              />

              <lila-textarea
                v-model="data.Comorbidity"
                label="Comorbidades"
                placeholder="Digite aqui..."
                :readonly="viewOnly"
              />

              <lila-textarea
                v-show="utilities.hasAccessToFeature(FeatureEnum.CampoDiagnostico)"
                v-model="data.Diagnostic"
                label="Diagnóstico Oncológico"
                placeholder="Digite aqui..."
                :readonly="viewOnly"
              />
            </div>
          </div>

          <!-- Observações -->
          <div class="division-line" />

          <lila-textarea
            v-model="v.data.Observations.$model"
            label="Observações"
            placeholder="Digite aqui..."
            :readonly="viewOnly"
            :validation="v.data.Observations"
          />

          <lila-button
            v-if="!viewOnly"
            type="submit"
            title="Salvar"
            class="submit-buttons"
            :loading="loading"
          />
        </div>
      </div>
    </form>

    <lila-cid-modal
      :modal-active="cidModalActive"
      @custom-click-event="selectCid"
    />

    <div
      class="modal default-modal"
      :class="deleteModal.active ? 'is-active' : ''"
    >
      <div class="modal-background" />
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">
            Excluir
          </p>
        </header>

        <section class="modal-card-body">
          <p>Deseja excluir esse registro?</p>
        </section>

        <footer class="modal-card-foot">
          <lila-button
            type="submit"
            title="Excluir"
            class="submit-buttons"
            :second-button="{
              type: 'button',
              title: 'Cancelar',
              class: 'dark-lilas',
            }"
            :loading="deleteLoading"
            @custom-click-event="controllDeleteCid()"
            @custom-click-cancel-event="closeDeleteModal()"
          />
        </footer>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Select from "@components/shared/fields/Select.vue";
import Textarea from "@components/shared/fields/Textarea.vue";
import Button from "@components/shared/button/Button.vue";
import SvgIcon from "@components/shared/images/SvgIcon.vue";
import CidModal from "@components/shared/lila/CidModal.vue";
import ExportButton from "@components/shared/lila/ExportButton.vue";
import Message from "@components/shared/message/Message.vue";
import LilaMask from "@components/shared/fields/LilaMask.vue";
import LilaInput from "@components/shared/fields/Input.vue";
import LilaDatepicker from "@components/shared/fields/LilaDatepicker.vue";
import GenderOptions from "@assets/options/gender.json";
import StatesOptions from "@assets/options/states.json";

import useVuelidate from "@vuelidate/core";
import {
  required,
  requiredIf,
  email,
  helpers,
  maxLength,
} from "@vuelidate/validators";

// Import the utilities instance
import UtilitiesV2, { utilities } from "@/plugins/UtilitiesV2";
import {
  Patient,
  CIDType,
  PatientCID,
  Alert,
  Address,
  FrontendPatient,
  PatientAttributeOption,
} from "@/models";
import { performanceStatusList } from "@/enums/PerformanceStatus";

import { AlertMessage, AlertType, PatientJourneyOptions, Feature } from "@/enums/Index";
import axios, { AxiosError } from "axios"; // Import axios and AxiosError
import { descriptors } from "chart.js/dist/core/core.defaults";
import { MessageType } from "@aspnet/signalr";

const atLeastOne = (value: any, context: any) => {
  return !!context.CPF || !!context.CNS; // Verifica se pelo menos um dos dois está preenchido
};

export default defineComponent({
  components: {
    LilaDatepicker,
    "lila-select": Select,
    "lila-textarea": Textarea,
    "lila-button": Button,
    "lila-svg-icon": SvgIcon,
    "lila-cid-modal": CidModal,
    "lila-export-button": ExportButton,
    "lila-message": Message,
    LilaMask,
    LilaInput
  },
  emits: [
    "updatePatient",
    "updatePatientCardSummary",
    "updatePatientTicketCard",
    "updatePatientSymptomAlertCard"
  ],
  data() {
    return {
      endpointLoading: false,
      raceList: [],
      educationList: [],
      employmentList: [],
      maritalStatusList: [],
      journeyStageList: [],
      symptomStartPeriodList: [],
      companionTypeList: [],
      isCancerRelated: false,
      PatientJourneyOptions,
      cidLoading: false, // Add this to manage the loading state of the "Adicionar" button
      addNewCid: false, // Moved outside of newCid
      v: useVuelidate(),
      viewOnly: false,
      data: {
        Id: Number(this.$route.params.id), // Convert to number
        Name: "",
        Email: "",
        AttendancePlaceId: 0,
        PS: null,
        HasWeeklyReport: false,
        BirthDate: "",
        Gender: "",
        CPF: "",
        Phone: "",
        CEP: "",
        Street: "",
        Number: "",
        Complement: "",
        Neighborhood: "",
        City: "",
        UF: "",
        Comorbidity: "",
        Diagnostic: "",
        Allergy: "",
        RelativeName: "",
        RelativePhone: "",
        Status: "",
        LifeStatus: PatientJourneyOptions.Desconhecido,
        DeathDate: "",
        DeathNotes: "",
        RegisterNumber: "",
        CIDs: [] as PatientCID[],
        Education: null,
        Employment: null,
        MaritalStatus: null,
        Race: null,
        SymptomStartPeriod: null,
        CompanionType: null,
        CompanionName: "",
        CompanionPhone: "",
        selectedCompanionType: "",
        selectedRace: "",
        selectedMaritalStatus: "",
        selectedEmployment: "",
        selectedEducation: "",
        selectedJourneyStage: "",
        SocialName: "",
        CNS: "",
        Observations: "",
        HasNavAdmStarted: false,
        NavigationStartDate: ""
      } as FrontendPatient, // data typed as Patient
      newCid: {
        Id: 0,
        CID: {
          Id: 0,
          CIDCode: "",
          Description: "",
        },
        Morphology: "",
        Estadio: "",
        IsEnable: true,
      } as PatientCID,
      genderList: GenderOptions.options,
      statusList: [
        { _id: "Active", name: "Login habilitado" },
        { _id: "Inactive", name: "Login desabilitado" },
      ],
      lifeStatusList: [
        { _id: "InTreatment", name: "Em tratamento" },
        { _id: "OutTreatment", name: "Fora de tratamento" },
        { _id: "Deceased", name: "Falecido" },
      ],
      estadioList: [
        { _id: "I", name: "I" },
        { _id: "II", name: "II" },
        { _id: "III", name: "III" },
        { _id: "IV", name: "IV" },
        { _id: "Localizado", name: "Localizado" },
        { _id: "Extenso", name: "Extenso" },
        { _id: "Não se aplica", name: "Não se aplica" },
      ],
      performanceStatusList,
      medicalCareLocationList: (
        this.$Session.get(this.$userSessionName).doctor.Addresses as Address[]
      ).map((item: Address) => ({
        _id: item.Id,
        name: item.Street,
      })),
      statesList: StatesOptions.options,
      cidModalActive: false,
      deleteModal: {
        id: null as number | null,
        cidIndex: null as number | null,
        active: false,
      },
      deleteLoading: false,
      sendingCode: false,
      loading: false,
      loadingCep: false,
      loadingContent: false,
      alert: {
        message: "",
        type: AlertType.SUCCESS, // valor default usado
        show: false,
      } as Alert, // alert typed as Alert

      cidStateMap: new Map<number, { inserted: boolean }>(), // Map to track the state of each CID
    };
  },
  validations() {
    const validCpf = (value: string) => {
      return value ? utilities.validateCPF(value) : true;
    };

    const validCns = (value: string) => {
      // Valida se o CNS tem 15 dígitos numéricos (teste)
      const regex = /^\d{15}$/;
      return value ? regex.test(value) : true;
    };

    const dateLessOrEqualToCurrent = (value: string) => {
      // Check for empty or null value
      if (!value) {
        return true; // Allow empty or null dates
      }

      // Parse and compare dates if a valid value is provided
      const formattedDate = utilities.formatDate(value, "DD/MM/YYYY");
      const currentFormattedDate = utilities.formatDate(
        new Date().toISOString(),
        "MM/DD/YYYY"
      );

      return formattedDate <= currentFormattedDate;
    };

    const validObservationsLength = helpers.withParams(
      { maxLength: 2000 },
      maxLength(2000) // Aqui usamos o maxLength embutido com 2000 como o limite
    );

    if (this.addNewCid) {
      return {
        data: {
          Name: { required },
          SocialName: {},
          Gender: { required },
          Email: { email },
          AttendancePlaceId: { required },
          CPF: {
            validCpf,
          },
          CNS: {
            validCns: helpers.withMessage(
              "O CNS deve conter exatamente 15 dígitos numéricos",
              validCns
            ),
          },
          Phone: { required },
          BirthDate: { dateLessOrEqualToCurrent },
          DeathDate: {},
          DeathNotes: {},
          RegisterNumber: {},
          NavigationStartDate: {},
          Observations: {
            validObservationsLength,
          },
        },
        newCid: {
          CID: {
            CIDCode: {},
          },
          Morphology: {},
          Estadio: {},
          BiopsyDate: {},
        },
      };
    }

    return {
      data: {
        Name: { required },
        SocialName: {},
        Gender: { required },
        Email: { email },
        AttendancePlaceId: { required },
        CPF: {
          validCpf,
        },
        CNS: {
          validCns: helpers.withMessage(
            "O CNS deve conter exatamente 15 dígitos numéricos",
            validCns
          ),
        },
        cpfOrCns: { atLeastOne }, // Validação para garantir que um dos dois seja preenchido
        Phone: { required },
        BirthDate: { dateLessOrEqualToCurrent },
        DeathDate: {},
        DeathNotes: {},
        RegisterNumber: {},
        NavigationStartDate: {},
        Observations: {
          validObservationsLength,
        },
      },
      newCid: {
        CID: {
          CIDCode: {},
        },
        Morphology: {},
        Estadio: {},
        BiopsyDate: {},
      },
    };
  },

  computed: {
    checkInstalledApp() {
      return this.data.Status != "Pending";
    },
    utilities() {
      return utilities;
    },
    FeatureEnum() {
      return Feature;
    },
    formattedNavigationStartDate() {
      const date = this.data.NavigationStartDate;

      if (!date) {
        return ""; 
      }

      if (/^\d{4}-\d{2}-\d{2}$/.test(date)) {
        const formattedDate = utilities.formatDate(date, "YYYY-MM-DD", "DD/MM/YYYY");
        return `em ${formattedDate}`;
      }
      return `em ${date}`;
    }
  },
  watch: {
    $route(to) {
      this.data.Id = to.params.id;
      this.data.PS = null;
      this.data.HasWeeklyReport = false;
      this.getPatient();
    },
  },
  created: async function () {
    this.viewOnly = this.$AccessControl.cannot(
      this.$Session.get(this.$userSessionName).profile,
      "edit_medical_record"
    );

    // Wait for getPatientAttributesOptions to complete before calling getPatient
    await this.getPatientAttributesOptions();
    this.getPatient();
  },
  methods: {
    async callEndpoint() {
      // Verifica se o PatientId está disponível
      if (!this.data.Id) {
        this.alert.type = AlertType.WARNING;
        this.alert.message = "Paciente não encontrado.";
        utilities.controlAlert(this.alert.message, this.alert);
        return;
      }

      this.endpointLoading = true;

      try {
        const navigationStartDate = this.data.NavigationStartDate ?? null;
        const formattedDate = utilities.formatDate(navigationStartDate, "DD/MM/YYYY", "MM/DD/YYYY");
        // Faz a chamada ao endpoint
        const response = await this.$axios.post(
          `/api/automation/CreateAutoActions/patient/${this.data.Id}?startDate=${formattedDate}`,
          {}, // Se o endpoint espera um corpo vazio
          {
            headers: {
              Authorization: `bearer ${this.$Session.get(this.$userSessionName).token.Value}`,
            },
          }
        );

        // Extrai o array de ações criadas
        const actions = response.data; // Ajuste conforme a estrutura da resposta

        // Obtém o número de ações criadas
        const numberOfActions = Array.isArray(actions) ? actions.length : 0;

        // Manipula a resposta conforme necessário
        this.alert.type = AlertType.SUCCESS;
        this.alert.message = `${numberOfActions} ações automatizadas criadas com sucesso e navegação iniciada.`;
        utilities.controlAlert(null, this.alert);

        // Atualiza a flag no estado do componente
        this.data.HasNavAdmStarted = true;
      } catch (error: any) {
        // Manipula o erro
        console.error("Erro ao chamar o endpoint:", error);

        this.alert.type = AlertType.DANGER;

        if (error.response && error.response.data) {
          let apiMessage = error.response.data.Message || "Erro ao chamar o endpoint.";

          if (error.response.data.Errors && Array.isArray(error.response.data.Errors)) {
            const errorMessages = error.response.data.Errors.join(" ");
            this.alert.message = `${apiMessage} Detalhes: ${errorMessages}`;
          } else {
            this.alert.message = apiMessage;
          }

          utilities.controlAlert(this.alert.message, this.alert);
        } else if (error.message) {
          this.alert.message = error.message;
          utilities.controlAlert(this.alert.message, this.alert);
        } else {
          this.alert.message = "Ocorreu um erro desconhecido ao chamar o endpoint.";
          utilities.controlAlert(this.alert.message, this.alert);
        }
      } finally {
        this.endpointLoading = false;
      }
    },

    async getPatientAttributesOptions() {
      try {
        const response = await this.$axios.get(
          `/api/patient/attributesOptions`,
          {
            headers: {
              Authorization: `bearer ${this.$Session.get(this.$userSessionName).token.Value
                }`,
            },
          }
        );

        const data = response.data;

        // Assign the fetched options to the lists with explicit typing
        this.raceList = data.Races.map((option: PatientAttributeOption) => ({
          _id: option.Id,
          name: option.Description,
        }));

        this.educationList = data.Educations.map(
          (option: PatientAttributeOption) => ({
            _id: option.Id,
            name: option.Description,
          })
        );

        this.symptomStartPeriodList = data.SymptomStartPeriods.map(
          (option: PatientAttributeOption) => ({
            _id: option.Id,
            name: option.Description,
          })
        );

        this.employmentList = data.Employments.map(
          (option: PatientAttributeOption) => ({
            _id: option.Id,
            name: option.Description,
          })
        );

        this.maritalStatusList = data.MaritalStatuses.map(
          (option: PatientAttributeOption) => ({
            _id: option.Id,
            name: option.Description,
          })
        );

        this.journeyStageList = data.JourneyStages.map(
          (option: PatientAttributeOption) => ({
            _id: option.Id,
            name: option.Description,
          })
        );

        this.companionTypeList = data.CompanionTypes.map(
          (option: PatientAttributeOption) => ({
            _id: option.Id,
            name: option.Description,
          }) 
        );
      } catch (error) {
        console.error("Error fetching patient attribute options:", error);
        this.alert.type = AlertType.DANGER;
        this.alert.message = AlertMessage.ERROR;
        utilities.controlAlert(
          "Erro ao buscar as opções de atributos.",
          this.alert
        );
      }
    },
    isCancerRelatedCID(cidCode: string | null): boolean {
      if (!cidCode) {
        return false;
      }

      // Check if the CID starts with 'C' and falls in the range C00 to C97, allowing optional decimal point
      const regex = /^C([0-9]{2})(\.[0-9]+)?$/;
      const match = regex.exec(cidCode);

      if (match) {
        const cidNumber = parseInt(match[1], 10);
        return cidNumber >= 0 && cidNumber <= 97;
      }

      return false;
    }
    ,

    submitForm() {
      this.addNewCid = false;
      this.alert.show = false;

      // Trigger validation
      this.v.$touch();

      // Check if form is invalid
      if (this.v.$invalid) {
        utilities.scrollToInvalidField();
        return;
      }

      // Set loading state
      this.loading = true;

      // Save patient
      this.savePatient()
        .then(() => {
          // Update UI components after successful patient save
          this.updatePatientCard();
          this.updatePatientSummaryCard();
          this.updatePatientTicketCard();
          this.updatePatientSymptomAlertCard();

          // Show success alert
          this.alert.type = AlertType.SUCCESS;
          this.alert.message = AlertMessage.EDIT;
          utilities.controlAlert(null, this.alert);
        })
        .catch((error) => {
          // Handle errors
          console.error("API Error:", error);

          this.alert.type = AlertType.DANGER;

          if (error.response && error.response.data) {
            // Extrair a mensagem de erro padrão
            let apiMessage = error.response.data.Message || "Erro ao salvar paciente.";

            // Verifica se o array de Errors existe e não está vazio
            if (error.response.data.Errors && Array.isArray(error.response.data.Errors)) {
              // Concatena todas as mensagens de erro do array Errors
              const errorMessages = error.response.data.Errors.join(" ");
              this.alert.message = `${apiMessage} Detalhes: ${errorMessages}`;
            } else {
              // Se não houver array de Errors, usa a mensagem padrão
              this.alert.message = apiMessage;
            }

            utilities.controlAlert(this.alert.message, this.alert);
          } else if (error.message) {
            // Use the error message if it exists (e.g., network errors)
            this.alert.message = error.message;

            utilities.controlAlert(this.alert.message, this.alert);
          } else {
            // Default unknown error message
            this.alert.message = "Ocorreu um erro desconhecido.";

            utilities.controlAlert(this.alert.message, this.alert);
          }
        })
        .finally(() => {
          // Reset loading state
          this.loading = false;
        });
    },

    savePatient(): Promise<any> {
      const patient = { ...this.data };
      if (patient.BirthDate)
        patient.BirthDate = utilities.formatDate(
          patient.BirthDate,
          "DD/MM/YYYY",
          "MM/DD/YYYY"
        );
      if (patient.DeathDate)
        patient.DeathDate = utilities.formatDate(
          patient.DeathDate,
          "DD/MM/YYYY",
          "MM/DD/YYYY"
        );

      // Race
      if (!this.data.selectedRace || this.data.selectedRace === "") {
        patient.Race = null;
      } else {
        patient.Race = {
          Id: Number(this.data.selectedRace),
          Description: "", // Initialize with empty string
        };
      }

      // Marital Status
      if (
        !this.data.selectedMaritalStatus ||
        this.data.selectedMaritalStatus === ""
      ) {
        patient.MaritalStatus = null;
      } else {
        patient.MaritalStatus = {
          Id: Number(this.data.selectedMaritalStatus),
          Description: "", // Initialize with empty string
        };
      }

      // Employment
      if (
        !this.data.selectedEmployment ||
        this.data.selectedEmployment === ""
      ) {
        patient.Employment = null;
      } else {
        patient.Employment = {
          Id: Number(this.data.selectedEmployment),
          Description: "", // Initialize with empty string
        };
      }

      // Education
      if (!this.data.selectedEducation || this.data.selectedEducation === "") {
        patient.Education = null;
      } else {
        patient.Education = {
          Id: Number(this.data.selectedEducation),
          Description: "", // Initialize with empty string
        };
      }

      // Education
      if (!this.data.selectedSymptomStartPeriod || this.data.selectedSymptomStartPeriod === "") {
        patient.SymptomStartPeriod = null;
      } else {
        patient.SymptomStartPeriod = {
          Id: Number(this.data.selectedSymptomStartPeriod),
          Description: "", // Initialize with empty string
        };
      }

      // Journey Stage
      if (
        !this.data.selectedJourneyStage ||
        this.data.selectedJourneyStage === ""
      ) {
        patient.JourneyStage = null;
      } else {
        patient.JourneyStage = {
          Id: Number(this.data.selectedJourneyStage),
          Description: "", // Initialize with empty string
        };
      }

      // Companion Type
      if (
        !this.data.selectedCompanionType ||
        this.data.selectedCompanionType === ""
      ) {
        patient.CompanionType = null;
      } else {
        patient.CompanionType = {
          Id: Number(this.data.selectedCompanionType),
          Description: "", // Initialize with empty string
        };
      }

      return this.$axios({
        method: "PUT",
        url: `api/patient/v2/${patient.Id}`,
        data: patient,
        headers: {
          Authorization: `bearer ${this.$Session.get(this.$userSessionName).token.Value
            }`,
        },
      });
    },
    async saveCid(newCid: PatientCID): Promise<any> {
      const url = `api/cid/patient/v2/${this.data.Id}`;
      const method = "POST";

      // Convert the newCid object to match the API format
      const formattedCid = {
        CIDId: newCid.CID.Id,
        Morphology: newCid.Morphology || "",
        Estadio: newCid.Estadio || "",
        IsEnable: newCid.IsEnable || true,
        BiopsyDate: newCid.BiopsyDate
          ? utilities.formatDate(newCid.BiopsyDate, "DD/MM/YYYY", "MM/DD/YYYY")
          : null, // Set to null if empty
      };

      // Send the request to the server
      return await this.$axios({
        method,
        url,
        data: formattedCid,
        headers: {
          Authorization: `bearer ${this.$Session.get(this.$userSessionName).token.Value}`,
        },
      });
    },


    resetNewCidForm() {
      this.newCid = {
        Id: 0, // Set Id to 0 (or null) before adding a new CID
        CID: {
          Id: 0,
          CIDCode: "",
          Description: "",
        },
        Morphology: "",
        Estadio: "",
        IsEnable: true, // Default to true
        BiopsyDate: null,
      };

      // Reset form validation states
      this.v.$reset();

      // Hide the Morfologia and Estadio fields after adding the CID
      this.isCancerRelated = false;
    },

    // Utility function to update newly inserted CIDs and their states
    updateInsertedCids(insertedCids: PatientCID[]) {
      // Ensure cidList is initialized
      if (!this.data.CIDs) {
        this.data.CIDs = []; // Initialize if it's null or undefined
      }

      // Filter out CIDs that are already in the state map (prevent duplicates)
      insertedCids.forEach((newCid) => {
        const exists = this.data.CIDs?.some(
          (item) => item.CID.Id === newCid.CID.Id
        );

        if (!exists) {
          const cidItem: PatientCID = {
            Id: newCid.Id,
            CID: {
              Id: newCid.CID?.Id || 0,
              CIDCode: newCid.CID?.CIDCode || "",
              Description: newCid.CID?.Description || "",
            },
            Morphology: newCid.Morphology || "",
            Estadio: newCid.Estadio || "",
            IsEnable: newCid.IsEnable || true,
            BiopsyDate: newCid.BiopsyDate,
          };

          // Add the newly inserted CID to the list if it doesn't exist
          this.data.CIDs?.push(cidItem);

          // Mark it as inserted in the cidStateMap
          this.cidStateMap.set(cidItem.Id, { inserted: true });
        }
      });
    },
    deleteCid(cidId: number, patientId: number, cidIndex: number) {
      this.deleteLoading = true;

      this.$axios({
        method: "DELETE",
        url: `/api/cid/${cidId}/patient/${patientId}`,
        headers: {
          Authorization: `bearer ${this.$Session.get(this.$userSessionName).token.Value
            }`,
        },
      })
        .then(() => {
          this.data.CIDs?.splice(cidIndex, 1);
        })
        .catch((error) => {
          console.error(error);
          this.alert.type = AlertType.DANGER;
          this.alert.message = AlertMessage.ERROR;
          if (error && error.response) {
            utilities.verifyToken(error.response.status);
            if (error.response.data) {
              utilities.controlAlert(error.response.data, this.alert);
            } else {
              utilities.controlAlert(
                "Ocorreu um erro desconhecido.",
                this.alert
              );
            }
          } else {
            utilities.controlAlert("Ocorreu um erro desconhecido.", this.alert);
          }
        })
        .finally(() => {
          this.deleteLoading = false;

          this.closeDeleteModal();
        });
    },
    async getPatient() {
      try {
        this.loadingContent = true;

        const res = await this.$axios.get(
          `api/patient/record/v2/${this.data.Id}`,
          {
            headers: {
              Authorization: `bearer ${this.$Session.get(this.$userSessionName).token.Value
                }`,
            },
          }
        );

        this.processPatientData(res.data);


      } catch (error: unknown) {
        // Use `unknown`
        this.alert.type = AlertType.DANGER;
        this.alert.message = AlertMessage.ERROR;
        if (utilities.isAxiosError(error)) {
          // Type check for AxiosError
          utilities.verifyToken(error.response?.status ?? 500);
          utilities.controlAlert(error.response?.data, this.alert);
        } else {
          utilities.controlAlert("Ocorreu um erro desconhecido.", this.alert);
        }
      } finally {
        this.loadingContent = false;
      }
    },

    processPatientData(
      data: Partial<FrontendPatient & { CIDs: PatientCID[] }>
    ) {
      Object.keys(data).forEach((key) => {
        const lowerKey = key.toLowerCase();

        switch (key) {
          case "AttendancePlace": // Match case in API response
            if (data.AttendancePlaceId) {
              this.data.AttendancePlaceId = data.AttendancePlaceId;
            }
            break;
          case "HasNavAdmStarted":
            this.data.HasNavAdmStarted = data.HasNavAdmStarted;
            break;
          case "PS": // Match case in API response
            if (data.CurrentPS !== undefined) {
              this.data.PS = data.CurrentPS;
              this.data.HasWeeklyReport = !!data.CurrentPS;
            }
            break;
          case "BirthDate": // Match case in API response
            if (data.BirthDate) {
              this.data.BirthDate = utilities.formatDate(
                data.BirthDate,
                "MM/DD/YYYY",
                "DD/MM/YYYY"
              );
            }
            break;

          case "LifeStatus": // Match case in API response
            if (data.LifeStatus !== PatientJourneyOptions.Desconhecido) {
              this.data.LifeStatus = data.LifeStatus;
              this.data.selectedJourneyStage =
                data.JourneyStage?.Id != null
                  ? String(data.JourneyStage.Id)
                  : null;
            }
            break;
          case "CIDs": // Match case in API response
            if (data.CIDs) {
              this.data.CIDs = data.CIDs.map((item) => {
                // Populate the cidList
                const cidItem = {
                  Id: item.Id,
                  CID: {
                    Id: item.CID?.Id || 0, // Handle potential undefined values
                    CIDCode: item.CID?.CIDCode || "", // Handle potential undefined values
                    Description: item.CID?.Description || "", // Handle potential undefined values
                  },
                  Morphology: item.Morphology,
                  Estadio: item.Estadio,
                  IsEnable: item.IsEnable,
                  BiopsyDate: item.BiopsyDate,
                };

                // Track CID state in the cidStateMap (mark as inserted)
                this.cidStateMap.set(item.Id, { inserted: true });

                return cidItem;
              });
            }
            break;
          case "Race":
            this.data.selectedRace =
              data.Race?.Id != null ? String(data.Race.Id) : null;
            break;

          case "MaritalStatus":
            this.data.selectedMaritalStatus =
              data.MaritalStatus?.Id != null
                ? String(data.MaritalStatus.Id)
                : null;
            break;

          case "Employment":
            this.data.selectedEmployment =
              data.Employment?.Id != null ? String(data.Employment.Id) : null;
            break;

          case "Education":
            this.data.selectedEducation =
              data.Education?.Id != null ? String(data.Education.Id) : null;
            break;

          case "SymptomStartPeriod":
            this.data.selectedSymptomStartPeriod =
              data.SymptomStartPeriod?.Id != null ? String(data.SymptomStartPeriod.Id) : null;
            break;
          
          case "CompanionType":
            this.data.selectedCompanionType = 
              data.CompanionType?.Id != null ? String(data.CompanionType.Id) : null;
            break;

          case "DeathDate": // Match case in API response
            if (data.DeathDate) {
              this.data.DeathDate = utilities.formatDate(
                data.DeathDate,
                "YYYY-MM-DD",
                "DD/MM/YYYY"
              );
            }
            break;

          default:
            // Assuming this.data has the same fields as the Patient model
            if (key in this.data) {
              (this.data as any)[key] = data[key as keyof Patient]; // Safely assign dynamic keys
            }
            break;
        }
      });
    },

    clearLifeStatus() {
      this.data.DeathDate = "";
      this.data.DeathNotes = "";
    },
    selectCid(cidType: {
      CIDTypeId: number;
      CIDCode?: string;
      Description?: string;
    }) {
      this.newCid.CID = {
        Id: cidType.CIDTypeId,
        CIDCode: cidType.CIDCode || "", // Safely handle optional code
        Description: cidType.Description || "", // Handle possible undefined description
      };

      // Perform the cancer-related CID check
      this.isCancerRelated = this.isCancerRelatedCID(
        this.newCid.CID.CIDCode
      );
    },
    async addCid2(): Promise<void> {
      // Validate the form before proceeding
      this.v.$touch();

      // Check if the CIDCode is empty
      if (!this.newCid.CID.CIDCode) {
        this.alert.type = AlertType.WARNING;
        this.alert.message = "Um CID deve ser selecionado.";
        utilities.controlAlert(null, this.alert);
        return;
      }

      if (this.v.$invalid) {
        utilities.scrollToInvalidField();
        return;
      }

      // Set the loading state to true
      this.cidLoading = true;

      // Ensure cidList is initialized
      if (!this.data.CIDs) {
        this.data.CIDs = []; // Initialize if it's null or undefined
      }

      // Create a new CID object based on the user input
      const newCid: PatientCID = {
        Id: 0,
        CID: {
          Id: this.newCid.CID?.Id || 0, // Fallback to 0 if undefined
          CIDCode: this.newCid.CID?.CIDCode || "", // Empty string if undefined
          Description: this.newCid.CID?.Description || "", // Optional description
        },
        Morphology: this.newCid.Morphology || "", // Default to empty string if not provided
        Estadio: this.newCid.Estadio || "", // Default to empty string if not provided
        IsEnable: true, // Assuming the new CID is enabled by default
        BiopsyDate: this.newCid.BiopsyDate,
      };

      // Persist the new CID to the API first
      try {
        const response = await this.saveCid(newCid); // Save the new CID to the database and get the response
        const createdCid = response.data; // Get the created CID from the response (with its database Id)

        // Push the newly created CID to the data.CIDs list
        this.data.CIDs.push(createdCid);

        // Reset the form fields after adding the CID
        this.resetNewCidForm();
      } catch (error: any) {
        console.error("Error saving CID:", error);

        this.alert.type = AlertType.DANGER;

        if (error.response && error.response.data) {
          let apiMessage = error.response.data.Message || "Erro ao salvar CID.";

          if (error.response.data.Errors && Array.isArray(error.response.data.Errors)) {
            const errorMessages = error.response.data.Errors.join(" ");
            this.alert.message = `${apiMessage} Detalhes: ${errorMessages}`;
          } else {
            this.alert.message = apiMessage;
          }

          utilities.controlAlert(this.alert.message, this.alert);
        } else if (error.message) {
          this.alert.message = error.message;
          utilities.controlAlert(this.alert.message, this.alert);
        } else {
          this.alert.message = "Ocorreu um erro desconhecido ao salvar o CID.";
          utilities.controlAlert(this.alert.message, this.alert);
        }
      } finally {
        // Set the loading state back to false when done
        this.cidLoading = false;
      }
    },


    updatePatientCard() {
      this.$emit("updatePatient", this.data as FrontendPatient);
    },
    updatePatientSummaryCard() {
      this.$emit("updatePatientCardSummary", this.data as FrontendPatient);
    },
    updatePatientTicketCard() {
      this.$emit("updatePatientTicketCard", this.data as FrontendPatient);
    },
    updatePatientSymptomAlertCard() {
      this.$emit("updatePatientSymptomAlertCard", this.data as FrontendPatient);
    },
    controllDeleteCid() {
      const cidIndex = this.deleteModal.cidIndex;

      // Check if a valid ID exists, then call deleteCid
      if (this.deleteModal.id) {
        if (cidIndex !== null && cidIndex !== undefined) {
          this.deleteCid(this.deleteModal.id, this.data.Id, cidIndex); // Confirm cidIndex is a number before passing
        }
      }
      // Handle the deletion when no ID is provided but cidIndex is valid
      else if (cidIndex !== null && cidIndex !== undefined) {
        if (
          this.data.CIDs &&
          cidIndex >= 0 &&
          cidIndex < this.data.CIDs.length
        ) {
          this.data.CIDs.splice(cidIndex, 1);
        }

        this.closeDeleteModal();
      }
    },
    openCidModal() {
      this.cidModalActive = !this.cidModalActive;
    },
    openDeleteModal(id: number | null, cidIndex: number | null) {
      // Assign id if valid, else keep it null
      this.deleteModal.id = id ?? null;

      // Assign cidIndex if valid and >= 0, else keep it null
      this.deleteModal.cidIndex =
        cidIndex !== null && cidIndex >= 0 ? cidIndex : null;

      // Activate the modal
      this.deleteModal.active = true;
    },
    closeDeleteModal() {
      this.deleteModal.id = null;
      this.deleteModal.cidIndex = null;
      this.deleteModal.active = false;
    },
    changeCep() {
      utilities.buscarEndereco(this.data, this);
    },
  },
});
</script>

<style scoped>
@import "~@assets/css/views/medicalRecord.css";
</style>